import React, { useState } from 'react';
import * as Menubar from "@radix-ui/react-menubar";
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { ParagraphPlugin } from '@udecode/plate/react';

import FixedToolbarButton from './FixedToolbarControls/FixedToolbarButton';
import FixedToolbarTrigger from './FixedToolbarControls/FixedToolbarTrigger';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { TestimonialGroupPlugin } from '../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin';
import { CardGroupPlugin } from '../../plate-config/Plugins/CardGroup/CardGroup.plugin';
import { ImagePlugin } from '../../plate-config/Plugins/Image/Image.plugin';
import { VideoPlugin } from '../../plate-config/Plugins/Video/Video.plugin';
import { EmbedPlugin } from '../../plate-config/Plugins/Embed/Embed.plugin';
import { SubscribeToNewsletterPlugin } from '../../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin';
import { ColumnGroupPlugin } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { HorizontalRulePlugin } from '../../plate-config/Plugins/HrLine/HrLine.plugin';
import { ButtonGroupPlugin } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';


const FixedToolbar: React.FC = () => {
  const [value, setValue] = useState<string>("");
  const { pluginsStatus } = useAppSelector(state => state.page_plugins_status)

  const preventDefault = (e: Event) => e.preventDefault()
  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()
  const isPluginEnabled = (pluginType: string) => {
    const status = pluginsStatus.find(pluginStatus => pluginStatus.type === pluginType)
    return status ? status.enabled : false
  }

  // NOTE: button id's used in fixed draggables.
  return (
    <Menubar.Root value={value} onValueChange={setValue} className="menubar_fixed" id='menubar_fixed' >
      <Menubar.Menu value="fixed-text-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="textAa" tooltip={'Text'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton
                id='paragraph'
                iconName='paragraph'
                label='Paragraph'
                enabled={isPluginEnabled(ParagraphPlugin.key)}
              />
              <FixedToolbarButton
                id='heading1'
                iconName='textHOne'
                label='Heading 1'
                enabled={isPluginEnabled(HEADING_KEYS.h1)}
              />
              <FixedToolbarButton
                id='heading2'
                iconName='textHTwo'
                label='Heading 2'
                enabled={isPluginEnabled(HEADING_KEYS.h2)}
              />
              <FixedToolbarButton
                id='blockquote'
                iconName='quotes'
                label='Blockquote'
                enabled={isPluginEnabled(BlockquotePlugin.key)}
              />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="card-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="stack" tooltip={'Cards'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton
                id='testimonials'
                iconName='testimonials'
                label='Testimonials'
                enabled={isPluginEnabled(TestimonialGroupPlugin.key)}
              />
              <FixedToolbarButton
                id='cards'
                iconName='cards'
                label='Cards'
                enabled={isPluginEnabled(CardGroupPlugin.key)}
              />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-media-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="image" tooltip={'Media'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton
                id='image'
                iconName='image'
                label='Image'
                enabled={isPluginEnabled(ImagePlugin.key)}
              />
              <FixedToolbarButton
                id='video'
                iconName='videoCamera'
                label='Video'
                enabled={isPluginEnabled(VideoPlugin.key)}
              />
              <FixedToolbarButton
                id='embed'
                iconName='code'
                label='Embed'
                enabled={isPluginEnabled(EmbedPlugin.key)}
              />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-list-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="list" tooltip={'Lists'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton
                id='bulletList'
                iconName='listBullets'
                label='Bullet List'
                enabled={isPluginEnabled(BulletedListPlugin.key)}
              />
              <FixedToolbarButton
                id='numberedList'
                iconName='listNumbers'
                label='Numbered List'
                enabled={isPluginEnabled(NumberedListPlugin.key)}
              />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      {isPluginEnabled(ColumnGroupPlugin.key) && <Menubar.Menu value="fixed-col-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="columns" tooltip={'Columns'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='2columns' iconName='numberSquareTwo' label='2 Column' />
              <FixedToolbarButton id='3columns' iconName='numberSquareThree' label='3 Column' />
              <FixedToolbarButton id='4columns' iconName='numberSquareFour' label='4 Column' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>}
      {isPluginEnabled(HorizontalRulePlugin.key) && <Menubar.Menu value="fixed-dash-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="minus" tooltip={'Lines'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='hr' iconName='minus' label='Straight' />
              <FixedToolbarButton id='hr-dashed' iconName='dashedLine' label='Dashed' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>}
      <Menubar.Menu value="fixed-btn-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="arrowSquareOut" tooltip={'Buttons'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton
                id='subscribe_to_newsletter'
                iconName='newspaper'
                label='Subscribe Form'
                enabled={isPluginEnabled(SubscribeToNewsletterPlugin.key)}
              />
              {isPluginEnabled(ButtonGroupPlugin.key) &&
                <>
                  <FixedToolbarButton id='button' iconName='numberSquareOne' label='1 Button' />
                  <FixedToolbarButton id='2buttons' iconName='numberSquareTwo' label='2 Button' />
                  <FixedToolbarButton id='3buttons' iconName='numberSquareThree' label='3 Button' />
                </>
              }
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
}

export default FixedToolbar;