import React, { MouseEventHandler, useMemo } from 'react';
import { cn, withRef } from '@udecode/cn';
import { isType } from '@udecode/plate';
import {
  type RenderNodeWrapper, type PlateRenderElementProps, MemoizedChildren,
  ParagraphPlugin, useEditorPlugin, useElement,
} from '@udecode/plate/react';
import { useReadOnly, useSelected } from '@udecode/plate/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { useDraggable, useDropLine } from '@udecode/plate-dnd';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BlockSelectionPlugin } from '@udecode/plate-selection/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { MentionInputPlugin, MentionPlugin } from '@udecode/plate-mention/react';
import { BulletedListPlugin, NumberedListPlugin, ListItemPlugin, ListItemContentPlugin } from '@udecode/plate-list/react';
import { SlashInputPlugin, SlashPlugin } from '@udecode/plate-slash-command/react';

import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { ButtonPlugin } from '../../plate-config/Plugins/Button/Button.plugin';
import { ColumnGroupPlugin } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import PhIcon from '../../shared/Icon/PhIcon';
import { SectionPlugin } from '../../plate-config/Plugins/Section/Section.plugin';
import { ImagePlugin } from '../../plate-config/Plugins/Image/Image.plugin';
import { AiTextBlockPlugin } from '../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin';
import { AssessmentPlugin } from '../../plate-config/Plugins/Assessment/Assessment.plugin';
import { EmbedPlugin } from '../../plate-config/Plugins/Embed/Embed.plugin';
import { HorizontalRulePlugin } from '../../plate-config/Plugins/HrLine/HrLine.plugin';
import { PricingPlugin } from '../../plate-config/Plugins/Pricing/Pricing.plugin';
import { VideoPlugin } from '../../plate-config/Plugins/Video/Video.plugin';
import { ButtonGroupPlugin } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';
import { TestimonialGroupPlugin } from '../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin';
import { CardGroupPlugin } from '../../plate-config/Plugins/CardGroup/CardGroup.plugin';
import { EmojiInputPlugin, EmojiPlugin } from '../../plate-config/Plugins/EmojiCommand/EmojiCommand.plugin';
import { TemplateVariableInputPlugin, TemplateVariablePlugin } from '../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin';
import { CardPlugin } from '../../plate-config/Plugins/CardGroup/Card.plugin';
import { TestimonialPlugin } from '../../plate-config/Plugins/Testimonial/Testimonial.plugin';

// Keys of elements that can't be dragged
export const UNDRAGGABLE_KEYS = [
  SectionPlugin.key, ColumnPlugin.key, ButtonPlugin.key, SlashInputPlugin.key, SlashPlugin.key,
  EmojiPlugin.key, EmojiInputPlugin.key, TemplateVariableInputPlugin.key, TemplateVariablePlugin.key,
  LinkPlugin.key, MentionInputPlugin.key, MentionPlugin.key, CardPlugin.key, TestimonialPlugin.key,
  ListItemPlugin.key, ListItemContentPlugin.key
];

export const DraggableAboveNodes: RenderNodeWrapper = (props) => {
  const { editor, element, path } = props;
  const readOnly = useReadOnly();
  const enabled = useMemo(() => {
    if (readOnly) return false;
    // First level nodes are sections, so it will be always false
    if (path.length === 1) {
      return false;
    }
    // All second level nodes are draggable 
    if (path.length === 2 && !isType(editor, element, UNDRAGGABLE_KEYS)) {
      return true;
    }
    // Third level nodes are group elements
    if (path.length === 3 && !isType(editor, element, UNDRAGGABLE_KEYS)) {
      return true;
    }
    // only column childs, so they should be draggable
    if (path.length === 4 && !isType(editor, element, UNDRAGGABLE_KEYS)) {
      return true
    }
    return false;
  }, [editor, element, path, readOnly]);
  if (!enabled) return;
  return (props) => <Draggable {...props} />;
};

export const Draggable = withRef<'div', PlateRenderElementProps>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;
    const { isDragging, previewRef, handleRef } = useDraggable({ element });
    const { dropLine } = useDropLine();

    const node = editor.api.node({ at: [], match: { id: element.id } })!;
    const selectableElements = [
      ParagraphPlugin.key, HEADING_KEYS.h1, HEADING_KEYS.h2, BlockquotePlugin.key, BulletedListPlugin.key,
      NumberedListPlugin.key, ImagePlugin.key, AiTextBlockPlugin.key, AssessmentPlugin.key, EmbedPlugin.key,
      HorizontalRulePlugin.key, PricingPlugin.key, VideoPlugin.key
    ] as string[]

    const onDragIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (selectableElements.includes(element.type)) {
        editor.tf.focus()
        editor.tf.select(node[1])
      }
      if (element.type === ButtonGroupPlugin.key || element.type === TestimonialGroupPlugin.key || element.type === CardGroupPlugin.key) {
        editor.tf.focus()
        editor.tf.select([...node[1], 0])
      }
      if (element.type === ColumnGroupPlugin.key) {
        editor.tf.focus()
        editor.tf.select({ anchor: { path: [...node[1], 0, 0, 0], offset: 0 }, focus: { path: [...node[1], 0, 0, 0], offset: 0 } })
      }
    }

    const onDragIconMouseDown: MouseEventHandler<HTMLDivElement> = () =>
      editor.getApi(BlockSelectionPlugin).blockSelection?.clear();

    return (
      <div ref={ref} className={cn('relative', isDragging && 'opacity-50',)} >
        <Gutter>
          <div className={cn('flex h-[1.5em]', isType(editor, element, [HEADING_KEYS.h1, HEADING_KEYS.h2]) && 'h-[1.3em]',)} >
            <div className='pointer-events-auto mr-1 flex items-center' >
              <div ref={handleRef}>
                <div
                  contentEditable={false}
                  suppressContentEditableWarning={true}
                  className='bg-white rounded p-1 w-6 h-6 flex items-center justify-center shadow-md cursor-pointer'
                  onClick={onDragIconClick}
                  onMouseDown={onDragIconMouseDown}
                >
                  <PhIcon name="dotsSixVertical" size={16} className='brand-text' weight='bold' />
                </div>
              </div>
            </div>
          </div>
        </Gutter>
        <div ref={previewRef}>
          {!!dropLine &&
            <div
              className="relative bg-transparent h-[2px] overflow-hidden my-2"
              contentEditable={false} suppressContentEditableWarning={true}
            >
              <div
                className={`
                    absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                    ${dropLine === 'top' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                    ${dropLine === 'bottom' ? "h-[4px] scale-x-0 opacity-0" : " "}
                  `}
              />
            </div>}
          <MemoizedChildren>{children}</MemoizedChildren>
          {!!dropLine &&
            <div
              className="relative bg-transparent h-[2px] overflow-hidden my-2"
              contentEditable={false} suppressContentEditableWarning={true}
            >
              <div
                className={`
                    absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                    ${dropLine === 'bottom' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                    ${dropLine === 'top' ? "h-[4px] scale-x-0 opacity-0" : " "}
                    `}
              />
            </div>}
        </div>
      </div>
    );
  }
);

const Gutter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ children, className, ...props }, ref) => {
  const { editor, useOption } = useEditorPlugin(BlockSelectionPlugin);
  const element = useElement();
  const isSelectionAreaVisible = useOption('isSelectionAreaVisible');
  const selected = useSelected();

  const isNodeType = (keys: string[] | string) => isType(editor, element, keys);

  return (
    <div
      ref={ref}
      className={cn(
        'absolute -top-px z-50 flex h-full -translate-x-full cursor-text hover:opacity-100',
        'main-hover:group-hover:opacity-100',
        isSelectionAreaVisible && 'hidden',
        !selected && 'opacity-0',
        isNodeType(HorizontalRulePlugin.key) && '-top-1',
        isNodeType([ButtonGroupPlugin.key, ColumnGroupPlugin.key]) && '-left-8',
        className
      )}
      contentEditable={false}
      {...props}
    >
      {children}
    </div>
  );
});