import React, { useState } from "react";
import * as Menubar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";
import { NodeApi, TNode } from "@udecode/plate";

import { PlateSectionElement } from "../../../plate-config/Plugins/Section/Section.plugin";
import Settings from "./Settings/Settings";
import Animation from "./Animation/Animation";
import Font from "./Font/Font";
import ImagePicker from "../../../shared/ImagePicker/ImagePicker";
import BlockSidebarTrigger from "../../../shared/BlockMenubarControls/BlockSidebarTrigger";


type Props = {
  showTrigger: boolean;
  sectionElement: PlateSectionElement
}

const SectionToolbar: React.FC<Props> = ({ showTrigger, sectionElement }) => {
  const editor = useEditorRef()
  const [value, setValue] = useState<string>("");
  const [showImagePicker, setShowImagePicker] = useState(false)

  const currentSectionPath = editor.api.findPath(sectionElement as TNode)!;
  const fixedSection = sectionElement.fixed_kind === "recording"
  const sectionsLength = editor.children.filter((node: any) => node.type === 'section').length
  const currentIndex = editor.children.findIndex((node: any) => node.type === 'section' && node.id === sectionElement.id)
  const isLastChild = sectionsLength === 1;
  const isFirst = currentIndex === 0;
  const isLast = currentIndex === sectionsLength - 1;
  const firstSectionTextChild = NodeApi.firstText(editor, { from: currentSectionPath });
  const selectPath = firstSectionTextChild ? firstSectionTextChild[1] : currentSectionPath

  const onSectionDeleteHandle = () => {
    if (window.confirm('Are you sure you would like to delete this section?')) {
      editor.tf.setNodes(
        { delete: true } as Partial<Node>,
        { at: [], match: (n: any) => n.id === sectionElement.id }
      )
      editor.tf.removeNodes({ at: [], match: (n: any) => n.id === sectionElement.id })
    }
  }
  const onSectionDuplicateHandle = () => editor.tf.insertNodes(sectionElement, { at: [currentIndex + 1] })
  const onSectionMoveUpHandle = () => editor.tf.moveNodes({ at: currentSectionPath, to: [currentIndex - 1] })
  const onSectionMoveDownHandle = () => editor.tf.moveNodes({ at: currentSectionPath, to: [currentIndex + 1] })

  const preventDefault = (e: Event) => e.preventDefault()
  const onMenuClose = () => setValue("")
  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()

  const onBackgroundImageChange = (background_image_url: string, image_source: string, image_external_id: string) => {
    editor.tf.setNodes(
      { background_image_url, image_source, image_external_id } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    editor.tf.setSelection({ anchor: { path: selectPath, offset: 0 }, focus: { path: selectPath, offset: 0 } })
  }
  const onImageUnset = () => {
    editor.tf.setNodes(
      { background_image_url: null, image_source: null, image_external_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    editor.tf.setSelection({ anchor: { path: selectPath, offset: 0 }, focus: { path: selectPath, offset: 0 } })
  }
  const onImagePickerClose = () => {
    setShowImagePicker(false)
    editor.tf.setSelection({ anchor: { path: selectPath, offset: 0 }, focus: { path: selectPath, offset: 0 } })
    editor.tf.focus()
  }
  const onImagePopoverCall = () => setShowImagePicker(true)

  return (
    <>
      <Menubar.Root
        value={value}
        onValueChange={setValue}
        className={`${showTrigger ? "top-[0px] left-[-48px] menubar_wrapper" : "hidden"}`}
        id="section_tooltip_anchor"
      >
        <Menubar.Menu value="section_settings">
          <Menubar.Trigger onPointerEnter={preventPointerMove} >
            <BlockSidebarTrigger icon="gear" tooltip="Settings" container="section_tooltip_anchor" />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content sticky="always" hideWhenDetached className="block_sidebar_wrapper-content" side="right" align="start" sideOffset={8} alignOffset={-8}>
              <Menubar.Item
                onSelect={preventDefault}
                onPointerEnter={preventPointerMove}
                onPointerMove={preventPointerMove}
                onPointerLeave={preventPointerMove}
                className="menubar_item"
              >
                <Settings sectionElement={sectionElement} onMenuClose={onMenuClose} />
              </Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
        <Menubar.Menu value="section_font_menu">
          <Menubar.Trigger onPointerEnter={preventPointerMove}>
            <BlockSidebarTrigger icon="pencil" tooltip="Font" container="section_tooltip_anchor" />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content sticky="always" hideWhenDetached className="block_sidebar_wrapper-content" side="right" align="start" sideOffset={8} alignOffset={-44}  >
              <Menubar.Item onSelect={preventDefault} className="menubar_item" >
                <Font sectionElement={sectionElement} onMenuClose={onMenuClose} />
              </Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
        <Menubar.Menu value="section_animation_menu">
          <Menubar.Trigger onPointerEnter={preventPointerMove}>
            <BlockSidebarTrigger icon="flowArrow" tooltip="Animation" container="section_tooltip_anchor" />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content sticky="always" hideWhenDetached className="block_sidebar_wrapper-content" side="right" align="start" sideOffset={8} alignOffset={-80} >
              <Menubar.Item onSelect={preventDefault} className="menubar_item" >
                <Animation sectionElement={sectionElement} onMenuClose={onMenuClose} />
              </Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
        <Menubar.Menu value="section_image_menu">
          <Menubar.Trigger onPointerEnter={preventPointerMove}>
            <BlockSidebarTrigger icon="image" onClick={onImagePopoverCall} tooltip="Background" container="section_tooltip_anchor" />
          </Menubar.Trigger>
        </Menubar.Menu>
        <Menubar.Menu value="section_copy">
          <Menubar.Trigger onPointerEnter={preventPointerMove} disabled={fixedSection}>
            <BlockSidebarTrigger icon="copy" onClick={onSectionDuplicateHandle} disabled={fixedSection} tooltip="Copy" container="section_tooltip_anchor" />
          </Menubar.Trigger>
        </Menubar.Menu>
        <Menubar.Menu value="section_move_up">
          <Menubar.Trigger onPointerEnter={preventPointerMove} disabled={isFirst}>
            <BlockSidebarTrigger icon="arrowUp" onClick={onSectionMoveUpHandle} disabled={isFirst} tooltip="Move Up" container="section_tooltip_anchor" />
          </Menubar.Trigger>
        </Menubar.Menu>
        <Menubar.Menu value="section_move_down">
          <Menubar.Trigger onPointerEnter={preventPointerMove} disabled={isLast}>
            <BlockSidebarTrigger icon="arrowDown" onClick={onSectionMoveDownHandle} disabled={isLast} tooltip="Move Down" container="section_tooltip_anchor" />
          </Menubar.Trigger>
        </Menubar.Menu>
        <Menubar.Menu value="section_delete">
          <Menubar.Trigger onPointerEnter={preventPointerMove} disabled={fixedSection ? true : isLastChild}>
            <BlockSidebarTrigger
              icon="trash" variant="delete"
              onClick={onSectionDeleteHandle}
              disabled={fixedSection ? true : isLastChild}
              tooltip="Delete" container="section_tooltip_anchor"
            />
          </Menubar.Trigger>
        </Menubar.Menu>
      </Menubar.Root>
      <ImagePicker
        currentImageUrl={sectionElement.background_image_url}
        backgroundImageSource={sectionElement.image_source}
        onImageChange={onBackgroundImageChange}
        onModalClose={onImagePickerClose}
        modalOpen={showImagePicker}
        onImageUnset={onImageUnset}
      />
    </>
  );
}


export default SectionToolbar;