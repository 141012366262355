import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"

export const AiTextBlockPlugin = createPlatePlugin({
  key: 'ai_text',
  node: {
    type: 'ai_text',
    isElement: true,
    isVoid: true,
  },
})

export const createDefaultAiTextBlockElement = (): TElement => ({
  type: AiTextBlockPlugin.node.type,
  children: [{ text: '' }],
  primary_color: null,
  details: {
    kind: 'header',
    prompt: null,
    length_min: null,
    length_max: null,
    length_type: 'words',
    alignment: 'center',
    list_items_min: null,
    list_items_max: null,
    list_style: 'unordered',
    list_kind: 'standard',
    list_extra_spacing: false,
    source_buyer_summary: false,
    source_seller_summary: false,
    source_seller_benefits: false,
    source_seller_testimonials: false,
    source_buyer_contact: false,
    source_persona: false,
    template_resource_slot_ids: [],
  },
})

export interface PlateElementAiTextBlock extends TElement {
  id: string
  type: typeof AiTextBlockPlugin.node.type
  primary_color: string | null
  details: {
    kind: string
    prompt: string | null
    length_min: number | null
    length_max: number | null
    length_type: string
    alignment: string
    list_items_min: number | null
    list_items_max: number | null
    list_style: string
    list_kind: string
    list_extra_spacing: boolean
    source_buyer_summary: boolean
    source_seller_summary: boolean
    source_seller_benefits: boolean
    source_seller_testimonials: boolean
    source_buyer_contact: boolean
    source_persona: boolean
    template_resource_slot_ids: string[],
  }
}

export type UnionSourceTypes = 'source_buyer_summary' | 'source_seller_summary'
  | 'source_seller_benefits' | 'source_seller_testimonials' | 'source_buyer_contact'
