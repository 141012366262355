import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import { PlateHrElement } from "../../../plate-config/Plugins/HrLine/HrLine.plugin";
import BlockMenubarColors from "../../../shared/BlockMenubarControls/BlockMenubarColors";


type Props = {
  showTrigger: boolean;
  block: PlateHrElement;
}

const Menubar: React.FC<Props> = ({ showTrigger, block }) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onElementDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onColorChange = (color: string | null) => editor.tf.setNodes({ color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onStyleChange = (style: string) => editor.tf.setNodes({ style } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

  const line = block.style || 'straight'
  const lineOptions: any = [
    { key: "straight", label: "Straight", iconName: "solidLine", value: "Straight" },
    { key: "dash", label: "Dash", iconName: "dashedLine", value: "Dash" },
  ]

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      <MenuBar.Menu value="hr_line">
        <BlockMenubarTrigger
          isDropdown
          iconBeforeLabel={lineOptions.find(item => item.key === line)?.iconName}
          label={'Line Style'}
          isOpened={value === 'hr_line'}
        />
        <MenuBar.Portal>
          <BlockMenubarContent contentClassName="min-w-[120px]">
            <BlockMenubarOptionList
              data={lineOptions}
              onOptionClick={onStyleChange}
              activeKey={line}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="hr_colors">
        <BlockMenubarTrigger isDropdown isColor color={block.color} tooltip="Colors" containerId={block.id} />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarColors
              color={block.color}
              onColorChange={onColorChange}
              onColorReset={onColorChange.bind(onColorChange, null)}
              label="Color"
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="hr_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onElementDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;