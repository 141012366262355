import { revvedupApi } from "../api";
import { getPageDataAttributes } from "../data-attributes.util";

export interface SubscribeToNewsletterInput {
  name: string;
  email: string;
  account_id: string;
  page_id: string;
}

export const subscribeToNewsletterEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    subscribeToNewsletter: builder.mutation<any, SubscribeToNewsletterInput>({
      query: (subscribeData) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/subscribers`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { subscriber: subscribeData }
        }
      },
      async onQueryStarted({ }, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (err) {
          console.log('Update variable error:', err);
        }
      }
    }),
  }),
})

export const { useSubscribeToNewsletterMutation } = subscribeToNewsletterEndpoints