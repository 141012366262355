import React, { useRef, useState } from "react";

import NavigationSettings from "./NavigationSettings/NavigationSettings";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import Layout from "./Layout/Layout";
import { useElementWidth } from "../../../hooks/useElementWidth";

type Props = {
  pageWrapperHeight: number | null
}

const PageNavigation: React.FC<Props> = ({ pageWrapperHeight }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { nav_enabled, nav_background_color } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage, } = useAppSelector(state => state.page_addendums)
  const navRef = useRef<HTMLDivElement>(null);
  const navWidth = useElementWidth(navRef) || 0

  const readOnlyMode = readOnly || isLandingPage
  const bgColor = nav_background_color ? `background-${nav_background_color}` : 'bg-black';

  if (readOnlyMode && !nav_enabled) return null;

  return (
    <nav
      className={`h-[68px] relative w-full flex px-[34px] ${bgColor} ${isLandingPage ? "" : "rounded-t-lg"}`}
      onMouseEnter={setIsHovered.bind(setIsHovered, true)}
      onMouseLeave={setIsHovered.bind(setIsHovered, false)}
      ref={navRef}
    >
      {nav_enabled
        ?
        <Layout pageWrapperHeight={pageWrapperHeight} bgColor={bgColor} navWidth={navWidth} />
        :
        <section className="flex w-full p-4 items-center justify-center">
          <span className="text-gray-300 text-xs bg-white/10 px-[5px] py-[3px] rounded-lg brand-font-family cursor-default">
            Bar Hidden
          </span>
        </section>
      }
      {!readOnlyMode && <NavigationSettings showTrigger={isHovered} />}
    </nav>
  );
}

export default PageNavigation;