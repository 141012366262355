import React, { useEffect } from 'react';
import { withRef } from '@udecode/cn';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { PlateElement, useReadOnly, useSelected } from '@udecode/plate/react';

import { Popover, PopoverAnchor } from "../../shared/Popover/Popover";
import { PlateColumnGroupElement } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { PlateSectionElement } from '../../plate-config/Plugins/Section/Section.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import ColumnGroupMenubar from './ColumnGroupMenubar/ColumnGroupMenubar';

export const ColumnGroupElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateColumnGroupElement
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const editorContainerWidth = useAppSelector(state => state.page_addendums.editorContainerWidth)
    const selected = useSelected();
    const isReadOnly = useReadOnly()

    useEffect(() => {
      editor.tf.setNodes({ selected } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    }, [selected])

    const isMobilePreviewActive = editorContainerWidth === 'mobile'
    const isChildMenuOpen = element.children.map(child => child.menuOpen).includes(true);
    const node = editor.api.node({ at: [], match: (n: any) => n.id === element.id })!
    const nodePath = node[1]
    const section = editor.children[nodePath[0]] as PlateSectionElement
    const sectionChildren = section.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    const preventDefault = (e: Event) => e.preventDefault();

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        data-id={block.id}
        data-plate-selectable
        className={`
        ${className} 
        page-block group-column-block toolbar-z-index-control py-2 px-[22px] mx-[-31px] rounded-lg group/col_group_wrapper
        transition-all duration-300 ease-in-out
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          }     
        `}
        {...props}
      >
        <Popover open={isReadOnly ? false : isChildMenuOpen ? false : selected} >
          <PopoverAnchor className={`flex w-full gap-[8px] ${isMobilePreviewActive ? "flex-col" : ""}`}>
            {children}
          </PopoverAnchor>
          <PopoverPrimitive.Content
            onOpenAutoFocus={preventDefault}
            onCloseAutoFocus={preventDefault}
            side='top'
            sideOffset={12}
            align='center'
            style={{ zIndex: 1 }}
          >
            <ColumnGroupMenubar block={block} />
          </PopoverPrimitive.Content>
        </Popover>
      </PlateElement>
    );
  }
);


