import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"


export const SubscribeToNewsletterPlugin = createPlatePlugin({
  key: 'subscribe_to_newsletter',
  node: {
    type: 'subscribe_to_newsletter',
    isElement: true,
    isVoid: true,
  },
})

export interface PlateSubscribeToNewsletterElement extends TElement {
  id: string
  type: typeof SubscribeToNewsletterPlugin.key
  primary_color: string | null,
  secondary_color: string | null,
}


export const createDefaultSubscribeToNewsletterElement = (): TElement => ({
  type: SubscribeToNewsletterPlugin.key,
  children: [{ text: '' }],
  primary_color: null,
  secondary_color: null
})