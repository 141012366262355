import React, { useEffect, useRef } from "react";

import PageHeaderNav from "./PageHeaderNav/PageHeaderNav";
import { useAppDispatch, useAppSelector } from "../store/hooks/redux-hooks";
import { useGetPageDataQuery } from "../store/reducers/api/api";
import { useGetCalcVariablesQuery } from "../store/reducers/api/calc_variables/calc_variables.api";
import { useGetPageContactsQuery } from "../store/reducers/api/contacts/contacts.api";
import { useLazyGetTemplateVariablesQuery } from "../store/reducers/api/template_variables/template_variables.api";
import { setAnimationStatus } from "../store/reducers/page_side_effects/page_side_effects";
import EditorLayout from "./EditorLayout/EditorLayout";
import { useSaveSectionDataBeforeUnmountMutation } from "../store/reducers/api/sections/sections";
import FullScreenSpinner from "../shared/LoadingComponents/FullScreenSpinner";
import useViewMode from "../hooks/useViewMode";
import { setEditorContainerWidth } from "../store/reducers/page_addendums/page_addendums";
import { RESET_STORE_ACTION_TYPE } from "../store/store";


const PageLayout: React.FC = () => {
  const viewMode = useViewMode()
  const dispatch = useAppDispatch()
  const editorData = useAppSelector(state => state.page_sections.sections)
  const requestAttributes = useAppSelector(state => state.page_addendums.requestAttributes)
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)
  const editorDataRef = useRef(editorData);
  const requestAttributesRef = useRef(requestAttributes);
  const editorModeRef = useRef(readOnly);

  const { isFetching: isPageDataLoading, data, refetch: refetchPageData, isSuccess } = useGetPageDataQuery()
  const { isFetching: isPageCalcVarsLoading, refetch: refetchPageCalcVars } = useGetCalcVariablesQuery()
  const { isFetching: isPageContactsLoading, refetch: refetchPageContacts } = useGetPageContactsQuery()
  const [getTemplateData, { isLoading: isTemplateDataLoading }] = useLazyGetTemplateVariablesQuery()
  const [saveDataBeforeUnmount] = useSaveSectionDataBeforeUnmountMutation()

  useEffect(() => {
    refetchPageData()
    refetchPageCalcVars()
    refetchPageContacts()
  }, [])

  useEffect(() => {
    if (data?.template) {
      getTemplateData()
    }
  }, [data])

  useEffect(() => {
    if (isLandingPage) {
      dispatch(setEditorContainerWidth(viewMode))
    }
  }, [viewMode, isLandingPage])

  // ---------------------------- SCREENSHOOT OBSERVER ----------------------------
  useEffect(() => {
    let timeoutId: number | null = null;
    if (isSuccess) {
      const targetNode = document.getElementById('react');
      if (!targetNode) {
        dispatch(setAnimationStatus(false))
        return;
      }
      const screenshootStatus = targetNode.getAttribute('data-screenshot');
      if (!!screenshootStatus && screenshootStatus === 'true') {
        timeoutId = window.setTimeout(() => {
          targetNode.setAttribute('data-screenshot', 'ready');
        }, 3000);
      }
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [isSuccess])

  useEffect(() => {
    const targetNode = document.getElementById('react');
    if (!targetNode) {
      dispatch(setAnimationStatus(false))
      return;
    }
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-screenshot') {
          const target = mutation.target as HTMLElement;
          const newValue = target.getAttribute('data-screenshot');
          if (newValue === 'ready') {
            dispatch(setAnimationStatus(true))
          }
          if (!newValue) {
            return dispatch(setAnimationStatus(false))
          }
        }
      });
    });

    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['data-screenshot'],
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  // ---------------------------- SCREENSHOOT OBSERVER ----------------------------

  // ---------------------------- SAVE EDITOR DATA TO API BEFORE APP UNMOUNT ----------------------------
  //  CRITICAL: This is not only one place where we save data to API.
  //  Second place to check saving process is in PlateEditor.tsx
  useEffect(() => {
    editorModeRef.current = readOnly;
  })
  useEffect(() => {
    editorDataRef.current = editorData;
  })
  useEffect(() => {
    requestAttributesRef.current = requestAttributes;
  })
  useEffect(() => {
    return () => {
      if (!editorModeRef.current) {
        saveDataBeforeUnmount({ editor_data: editorDataRef.current, requestData: requestAttributesRef.current }).then(() => {
          dispatch({ type: RESET_STORE_ACTION_TYPE })
        })
        return
      }
      dispatch({ type: RESET_STORE_ACTION_TYPE })
    }
  }, [])
  // ---------------------------- SAVE EDITOR DATA TO API BEFORE APP UNMOUNT ----------------------------
  const isLoading = isPageDataLoading || isTemplateDataLoading || isPageCalcVarsLoading || isPageContactsLoading

  return (
    <>
      {isLoading
        ?
        <FullScreenSpinner />
        :
        <>
          {!isLandingPage && <PageHeaderNav />}
          <EditorLayout />
        </>
      }
    </>
  );
}

export default PageLayout;