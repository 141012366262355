import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"

import { PlateButtonElement, ButtonPlugin, createDefaultButtonElement } from "./Button.plugin";

export const ButtonGroupPlugin = createPlatePlugin({
  key: 'button_group',
  node: {
    type: 'button_group',
    isElement: true,
  },
}).overrideEditor(({ editor, tf: { normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // Group should be deleted if it has no children
        if (node.type === ButtonGroupPlugin.node.type) {
          const block = node as PlateButtonGroupElement;
          if (block.children.length === 0) {
            editor.tf.removeNodes({ at: path });
            return;
          }
        }
        // Children control - only buttons allowed as a child
        if (node.type === ButtonGroupPlugin.node.type) {
          const block = node as PlateButtonGroupElement;
          block.children.map((child) => {
            if (child.type !== ButtonPlugin.node.type) {
              editor.tf.removeNodes({ at: [], match: (n: any) => n.id === child.id });
            }
          });
        }
        normalizeNode([node, path])
      },
    }
  }
})


export interface PlateButtonGroupElement extends TElement {
  id: string
  type: typeof ButtonGroupPlugin.key
  children: PlateButtonElement[]
  align: string
  button_size: string
}

export const createDefaultButtonGroupElement = (): TElement => ({
  type: ButtonGroupPlugin.key,
  children: [createDefaultButtonElement()],
  align: 'left',
  button_size: 'medium',
})

export const createDefaultButtonGroupWith2BtnElement = (): TElement => ({
  type: ButtonGroupPlugin.key,
  children: [createDefaultButtonElement(), createDefaultButtonElement()],
  align: 'left',
  button_size: 'medium',
})

export const createDefaultButtonGroupWith3BtnElement = (): TElement => ({
  type: ButtonGroupPlugin.key,
  children: [createDefaultButtonElement(), createDefaultButtonElement(), createDefaultButtonElement()],
  align: 'left',
  button_size: 'medium',
})