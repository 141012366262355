import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import { PlateImageElement } from "../../../plate-config/Plugins/Image/Image.plugin";
import BlockMenubarLink from "../../../shared/BlockMenubarControls/BlockMenubarLink";


type Props = {
  showTrigger: boolean;
  block: PlateImageElement;
  onPickerOpen: () => void;
  goToLink: any;
}

const Menubar: React.FC<Props> = ({ showTrigger, block, onPickerOpen, goToLink }) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const shape = block.shape || 'square'
  const shapeOptions: any = [
    { key: "square", iconName: "square" },
    { key: "circle", iconName: "circle" },
    { key: "ellipse", iconName: "ellipse" },
    { key: "parallelogram", iconName: "parallelogram" },
    { key: "triangle", iconName: "triangle" },
    { key: "star", iconName: "star" },
  ]
  const align = block.align || 'center'
  const alignOptions: any = [
    { key: "left", iconName: "alignLeft" },
    { key: "center", iconName: "alignCenterVertical" },
    { key: "right", iconName: "alignRight" }
  ]

  const onElementDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onShapeChange = (shape: string) => editor.tf.setNodes({ shape } as Partial<Node>, { at: [], match: (n: any) => { return n.id === block.id } })
  const onAlignChange = (align: string) => editor.tf.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => { return n.id === block.id } })
  const onExternalUrlChange = (url: string) =>
    editor.tf.setNodes(
      { url, ink_type: 'external', internal_page_id: null, internal_resource_id: null, internal_template_resource_slot_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onInternalPageIdChange = (internal_page_id: string) =>
    editor.tf.setNodes(
      { url: null, link_type: 'internal', internal_page_id, internal_resource_id: null, internal_template_resource_slot_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onInternalTemplateResourceSlotIdChange = (internal_template_resource_slot_id: string) =>
    editor.tf.setNodes(
      { url: null, link_type: 'resource', internal_template_resource_slot_id, internal_resource_id: null, internal_page_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onInternalResourceIdChange = (internal_resource_id: string) =>
    editor.tf.setNodes(
      { url: null, link_type: 'resource', internal_resource_id, internal_template_resource_slot_id: null, internal_page_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onDynamicUrlChange = (url: string) =>
    editor.tf.setNodes(
      { url, link_type: 'dynamic', internal_resource_id: null, internal_template_resource_slot_id: null, internal_page_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      {block.image_url &&
        <>
          <MenuBar.Menu value="image_link">
            <BlockMenubarTrigger isDropdown labelIcon="link" isOpened={value === 'image_link'} tooltip="Link" containerId={block.id} />
            <MenuBar.Portal>
              <BlockMenubarContent>
                <BlockMenubarLink
                  linkType={block.link_type}
                  url={block.url}
                  onExternalUrlChange={onExternalUrlChange}
                  internal_page_id={block.internal_page_id}
                  onInternalPageIdChange={onInternalPageIdChange}
                  internal_template_resource_slot_id={block.internal_template_resource_slot_id}
                  onInternalTemplateResourceSlotIdChange={onInternalTemplateResourceSlotIdChange}
                  internal_resource_id={block.internal_resource_id}
                  onInternalResourceIdChange={onInternalResourceIdChange}
                  onDynamicUrlChange={onDynamicUrlChange}
                />
              </BlockMenubarContent>
            </MenuBar.Portal>
          </MenuBar.Menu>
          {goToLink &&
            <MenuBar.Menu value="image_goToLink">
              <BlockMenubarTrigger
                isLink path={goToLink} openInNewTab={(block.link_type === 'external' || block.link_type === 'resource')}
                tooltip="Go to Link" containerId={block.id}
              />
            </MenuBar.Menu>
          }
          <MenuBar.Separator className="element_topbar_separator" />
          <MenuBar.Menu value="image_align">
            <BlockMenubarTrigger
              isDropdown
              iconBeforeLabel={alignOptions.find(item => item.key === align)?.iconName}
              isOpened={value === 'image_align'}
              tooltip="Align" containerId={block.id}
            />
            <MenuBar.Portal>
              <BlockMenubarContent>
                <BlockMenubarOptionList
                  data={alignOptions}
                  onOptionClick={onAlignChange}
                  activeKey={align}
                />
              </BlockMenubarContent>
            </MenuBar.Portal>
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
          <MenuBar.Menu value="image_shape">
            <BlockMenubarTrigger
              isDropdown
              iconBeforeLabel={shapeOptions.find(item => item.key === shape)?.iconName}
              isOpened={value === 'image_shape'}
              tooltip="Shape" containerId={block.id}
            />
            <MenuBar.Portal>
              <BlockMenubarContent>
                <BlockMenubarOptionList
                  data={shapeOptions}
                  onOptionClick={onShapeChange}
                  activeKey={shape}
                />
              </BlockMenubarContent>
            </MenuBar.Portal>
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
          <MenuBar.Menu value="image_picker">
            <BlockMenubarTrigger labelIcon="image" onClick={onPickerOpen} tooltip="Picker" containerId={block.id} />
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
        </>
      }
      <MenuBar.Menu value="image_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onElementDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;