import React, { useRef } from "react"
import { ParagraphPlugin, useEditorRef, useReadOnly } from "@udecode/plate/react"

import { useAppSelector } from "../../../store/hooks/redux-hooks"
import AnimateWrapper from "../AnimateWrapper/AnimateWrapper"
import { PlateSectionElement } from "../../../plate-config/Plugins/Section/Section.plugin"
import { colorClassToRGB } from "../../../utils/color.util"
import { nonSlashCommandsBlocks } from "../../../utils/plate.util"
import { createDefaultParagraphElement } from "../../../plate-config/Plugins/DefaultMockups/DefaultMockups"
import Templates from "./Templates/Templates"
import AnimateHeightWrapper from "../../../shared/AnimateHeightWrapper/AnimateHeightWrapper"

export const CARD_PADDING_CONFIG: { [key: string]: string } = {
  laptop: 'p-5',
  tablet: 'p-5',
  mobile: 'p-[10px]'
}

type Props = {
  section: PlateSectionElement
  children: React.ReactNode
}

const SectionChildWrapper = ({ section, children }: Props) => {
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

  const editor = useEditorRef()
  const isReadOnly = useReadOnly();
  const { activePalette } = useAppSelector(state => state.page_colors)
  const editorContainerWidth = useAppSelector((state) => state.page_addendums.editorContainerWidth);

  const containerRef = useRef<HTMLDivElement>(null);

  const node = editor.api.node({ at: [], match: { id: section.id } })!
  const sectionFirstChild = section.children.length === 1 ? section.children[0] as any : false
  const isFirstChildParagraph = sectionFirstChild ? section.children[0].type === ParagraphPlugin.key : false
  const isSectionFirstChildEmptyParagraph = isFirstChildParagraph
    ? sectionFirstChild.children[0].text === "" && sectionFirstChild.children.length === 1
    : false
  const cardHasBgColor = !!section.card_color

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isReadOnly) return
    if (!containerRef.current) return;
    if (!cardHasBgColor) return
    const style = window.getComputedStyle(containerRef.current);
    const paddingBottom = parseInt(style.paddingBottom, 10);
    const rect = containerRef.current.getBoundingClientRect();
    const bottomEdge = rect.bottom;
    const clickYPosition = e.clientY;
    if (clickYPosition > (bottomEdge - paddingBottom)) {
      const lastSectionChild = section.children[section.children.length - 1];
      const lastSectionChildType = lastSectionChild.type as string
      if (nonSlashCommandsBlocks.includes(lastSectionChildType)) {
        editor.tf.insertNodes(createDefaultParagraphElement(), { at: [...node[1], section.children.length], select: true });
      }
    }
    return
  };


  return (
    <>
      {section.animate_type === 'animate' ? (
        <AnimateWrapper sectionElement={section}>
          <AnimateHeightWrapper>
            <div
              onClick={handleClick}
              ref={containerRef}
              style={{ backgroundColor: colorClassToRGB(section.card_color as string, section.card_opacity as number, activePalette.colors)! }}
              className={`section_plugin_container ${cardHasBgColor ? CARD_PADDING_CONFIG[editorContainerWidth] : ""}`}
            >
              {children}
              {!isReadOnly && isSectionFirstChildEmptyParagraph && <Templates sectionElement={section} />}
            </div>
          </AnimateHeightWrapper>
        </AnimateWrapper>
      ) : (
        <AnimateHeightWrapper>
          <div
            onClick={handleClick}
            ref={containerRef}
            style={{ backgroundColor: colorClassToRGB(section.card_color as string, section.card_opacity as number, activePalette.colors)! }}
            className={`section_plugin_container ${cardHasBgColor ? CARD_PADDING_CONFIG[editorContainerWidth] : ""}`}
          >
            {children}
            {!isReadOnly && isSectionFirstChildEmptyParagraph && <Templates sectionElement={section} />}
          </div>
        </AnimateHeightWrapper >
      )}
    </>
  );
}

export default SectionChildWrapper
