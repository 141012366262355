import React, { useState } from "react";
import { useEditorRef, useReadOnly } from "@udecode/plate/react";
import { NodeApi } from "@udecode/plate";

import { PlateCardElement } from "../../../plate-config/Plugins/CardGroup/Card.plugin";
import { PlateCardGroupElement } from "../../../plate-config/Plugins/CardGroup/CardGroup.plugin";
import ImagePicker from "../../../shared/ImagePicker/ImagePicker";
import PhIcon from "../../../shared/Icon/PhIcon";
import { useAppSelector } from "../../../store/hooks/redux-hooks";


type Props = {
  block: PlateCardElement
}

const ImageVisualType: React.FC<Props> = ({ block }) => {
  const editor = useEditorRef()
  const [showImagePicker, setShowImagePicker] = useState(false)
  const template = useAppSelector(state => state.page_navigation.template)
  const isReadOnly = useReadOnly()

  const elementNode = editor.api.node({ at: [], match: { id: block.id } })!
  const parentNode = NodeApi.parent(editor, elementNode[1]) as PlateCardGroupElement;
  const parentAlign = parentNode.align || 'left'
  const image_align = parentAlign === 'center' ? 'justify-center' : parentAlign === 'right' ? 'justify-end' : 'justify-start'

  const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
    editor.tf.setNodes(
      { image_external_id, image_source, image_url } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onImageUnset = () =>
    editor.tf.setNodes(
      { image_external_id: null, image_source: null, image_url: null, } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )

  return (
    <div className={`${image_align} mb-5 w-full flex`}>
      <div
        onClick={isReadOnly ? undefined : setShowImagePicker.bind(setShowImagePicker, true)}
        className={`w-[64px] h-[64px] flex items-center gap-4 flex-col ${block.image_url ? "bg-transparent" : "bg-gray-100"} rounded-full cursor-pointer justify-center`}
      >
        {
          isReadOnly
            ?
            block.image_url
              ? <img src={block.image_url} alt="img" className="w-[64px] h-[64px] rounded-full cursor-pointer" draggable={false} />
              : null
            :
            block.image_url
              ? <img src={block.image_url} alt="img" className="w-[64px] h-[64px] rounded-full cursor-pointer" draggable={false} />
              : <PhIcon name='fileImage' size={30} className="brand-subtext" />
        }
      </div>
      <ImagePicker
        currentImageUrl={block.image_url as string}
        backgroundImageSource={block.image_source as string}
        modalOpen={isReadOnly ? false : showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={!template}
      />
    </div>
  )
}

export default ImageVisualType;