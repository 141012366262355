import React, { useEffect, useState } from "react";
import { PlateElement, useReadOnly, withRef, useSelected } from "@udecode/plate/react";

import embedUrlPatterns, { EmbedPlatform, EmbedConfig } from "./config/config";
import { PlateElementEmbed } from "../../../components/plate-config/Plugins/Embed/Embed.plugin";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import EmbedIframe from "./EmbedIframe/EmbedIframe";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";
import PreviewSelector from "./PreviewSelector/PreviewSelector";
import Menubar from "./Menubar/Menubar";


const EmbedVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const block = element as PlateElementEmbed

    const isReadOnly = useReadOnly();
    const [platform, setPlatform] = useState<EmbedConfig | null>(null)
    const selected = useSelected();
    const [error, setError] = useState<boolean>(false);
    const [errorInstruction, setErrorInstruction] = useState<string>();

    useEffect(() => {
      if (block.url) {
        onUrlSubmit(block.url)
      }
    }, [])

    const nodePath = editor.api.node({ at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const openState = isReadOnly ? false : selected

    const validatePlatformUrl = (url: string) => {
      for (let config of embedUrlPatterns) {
        const match = url.match(config.pattern);
        if (match) {
          const isGoogleSheets = config.name === EmbedPlatform.GoogleSheets;
          if (isGoogleSheets) {
            const parsedUrl = new URL(url);
            if (parsedUrl.searchParams.has('output')) {
              setError(true);
              return;
            }
          }
          setPlatform({ ...config, link: url })
          editor.tf.setNodes({ url } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
          setError(false);
          return;
        }
      }
      setError(true);
      editor.tf.setNodes({ url: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
      setPlatform(null)
      if (url.includes("www.linkedin.com/")) {
        setErrorInstruction("https://revvedup.freshdesk.com/en/support/solutions/articles/201000047344-create-an-embedded-linkedin-post")
      }
    }

    const onUrlSubmit = (url: string) => {
      if (url) {
        validatePlatformUrl(url)
      } else {
        editor.tf.setNodes({ url: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
        setPlatform(null)
        setError(true)
        if (url.includes("www.linkedin.com/")) {
          setErrorInstruction("https://revvedup.freshdesk.com/en/support/solutions/articles/201000047344-create-an-embedded-linkedin-post")
        }
      }
    }
    const onUrlChange = () => {
      setError(false)
      setErrorInstruction(undefined)
    }

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={block}
        className={` ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
        ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block embed-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          } 
      `}
        data-plate-selectable
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        {children}
        {
          isReadOnly
            ? platform ? <EmbedIframe controlsVisible={openState} element={block} platform={platform} /> : null
            : platform
              ? <EmbedIframe controlsVisible={openState} element={block} platform={platform} />
              : <PreviewSelector isElColumnChild={isElColumnChild} />
        }
        <Menubar
          showTrigger={openState}
          block={block}
          platform={platform}
          onUrlChange={onUrlChange}
          onUrlSubmit={onUrlSubmit}
          error={error}
          errorInstruction={errorInstruction}
        />
      </PlateElement>
    )
  })

export default EmbedVoidElement
