import React from "react"
import { useReadOnly } from "@udecode/plate/react";

import PhIcon from "../../Icon/PhIcon";
import { ProgramaticTypes } from "../../../plate-config/Plugins/Image/Image.plugin";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import DecoratedInput from "../../Inputs/DecoratedInput";


type Props = {
  tabs: {
    name: string;
    value: string;
  }[]
  activeTab: string;
  setActiveTabHandler: (tab: string) => void;
  onModalClose: () => void;
  currentImageUrl?: string;
  onImageUnset: () => void;
  query: string;
  setQuery: (query: string) => void;
}

const ImagePickerHeader = ({
  tabs, activeTab, setActiveTabHandler, onModalClose, currentImageUrl, onImageUnset, query, setQuery
}: Props) => {
  const isReadOnly = useReadOnly()
  const { profile_image, image_placeholders } = useAppSelector(state => state.page_images)
  const isTemplate = useAppSelector(state => state.page_navigation.template)

  const isProgrammatic = currentImageUrl === ProgramaticTypes.BRAND_LOGO || currentImageUrl === ProgramaticTypes.PROFILE_IMAGE
  const profileImageUrl = (isTemplate && isReadOnly || !isTemplate)
    ? profile_image ? profile_image : image_placeholders.profile_url
    : image_placeholders.profile_url
  const image_url = isProgrammatic
    ? currentImageUrl === ProgramaticTypes.BRAND_LOGO ? image_placeholders.brand_url : profileImageUrl
    : currentImageUrl

  return (
    <div className="flex flex-col px-6 pt-6 gap-2 pb-4">
      <div className="flex items-center justify-between">
        <span className="brand-text text-2xl font-semibold">Select Image</span>
        <div onClick={onModalClose} className="cursor-pointer">
          <PhIcon name="x" className="text-[#3C3C3C]" size={18} />
        </div>
      </div>
      <div className="flex items-center gap-5">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`
              text-sm font-medium py-3 border-b-[3px]
              ${activeTab === tab.value ? "brand-text  border-primary" : "brand-subtext border-transparent"}
               `}
            onClick={setActiveTabHandler.bind(setActiveTabHandler, tab.value)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {image_url &&
        <div className="bg-white border border-gray-300 w-full rounded-xl flex items-center justify-between px-2 py-[10px]">
          <div className="flex gap-4 items-center">
            <img src={image_url} alt="Current image" className="w-[66px] h-[48px] rounded-lg object-cover" />
            <span className="brand-text text-sm cursor-default">
              Current Image
            </span>
          </div>
          <div onClick={onImageUnset} className="brand_btn-secondary-warning" >
            Remove Current Image
          </div>
        </div>
      }
      {activeTab === "unsplash" &&
        <DecoratedInput
          placeholder="Search..."
          onChange={setQuery}
          value={query}
          contentBefore={<PhIcon name="magnifyingGlass" size={16} className="text-[#5E5E5E] mr-2" />}
          inputClassName="text-sm"
        />
      }
    </div >
  )
}

export default ImagePickerHeader