import { TElement } from "@udecode/plate"
import { createPlatePlugin } from '@udecode/plate/react';

import { CardPlugin, createDefaultCardElement, PlateCardElement } from "./Card.plugin";

export const CardGroupPlugin = createPlatePlugin({
  key: 'card_group',
  node: {
    type: 'card_group',
    isElement: true,
  },
}).overrideEditor(({ editor, tf: { normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // Rule: group should be deleted if it has no children
        if (node.type === CardGroupPlugin.node.type) {
          const block = node as PlateCardGroupElement;
          if (block.children.length === 0) {
            editor.tf.removeNodes({ at: path });
            return;
          }
        }
        // Children control - only cards allowed
        if (node.type === CardGroupPlugin.node.type) {
          const block = node as PlateCardGroupElement;
          block.children.map((child) => {
            if (child.type !== CardPlugin.node.type) {
              editor.tf.removeNodes({ at: [], match: (n: any) => n.id === child.id });
            }
          });
        }
        normalizeNode([node, path])
      },
    }
  }
})


export interface PlateCardGroupElement extends TElement {
  id: string
  type: typeof CardGroupPlugin.key
  children: PlateCardElement[]
  template: string
  align: string | null
  card_bg_color: string | null
  card_bg_opacity: number
  text_color: string | null
}

export const createDefaultCardGroupElement = (): TElement => ({
  type: CardGroupPlugin.key,
  children: [
    createDefaultCardElement(), createDefaultCardElement(), createDefaultCardElement(),
    createDefaultCardElement(), createDefaultCardElement(), createDefaultCardElement(),
  ],
  template: 'none',
  align: 'left',
  card_bg_color: 'color-white',
  card_bg_opacity: 100,
  text_color: 'black',
})
