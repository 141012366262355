import React, { useRef, useState } from "react";
import { useEditorRef, useReadOnly } from "@udecode/plate/react";
import { NodeApi } from "@udecode/plate";

import { PlateTestimonialElement } from "../../../plate-config/Plugins/Testimonial/Testimonial.plugin";
import PhIcon from "../../../shared/Icon/PhIcon";
import ImagePicker from "../../../shared/ImagePicker/ImagePicker";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import { PlateTestimonialGroupElement } from "../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import AutoHeightTextarea from "../../../shared/AutoHeightTextarea/AutoHeightTextarea";
import { useElementWidth } from "../../../hooks/useElementWidth";


type Props = {
  block: PlateTestimonialElement
}

const SliderLayout: React.FC<Props> = ({ block }) => {
  const editor = useEditorRef()
  const [showImagePicker, setShowImagePicker] = useState(false)
  const template = useAppSelector(state => state.page_navigation.template)
  const mobileView = useAppSelector(state => state.page_addendums.editorContainerWidth === 'mobile')
  const [testimonial, setTestimonial] = useState(block.content as string)
  const [userName, setUserName] = useState(block.user_name as string)
  const [userPosition, setUserPosition] = useState(block.user_position as string)
  const isReadOnly = useReadOnly()
  const parentRef = useRef<HTMLImageElement>(null)
  const parentWidth = useElementWidth(parentRef)

  const node = editor.api.node({ at: [], match: { id: block.id } })!
  const parentNode = NodeApi.parent(editor, node[1]) as PlateTestimonialGroupElement;
  const textColor = parentNode.text_color ? parentNode.text_color : 'text-black'
  const isMobile = mobileView || parentWidth! < 390

  const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
    editor.tf.setNodes(
      { image_external_id, image_source, image_url } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onImageUnset = () =>
    editor.tf.setNodes(
      { image_external_id: null, image_source: null, image_url: null, } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onTestimonialChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTestimonial(e.target.value)
    editor.tf.setNodes(
      { content: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  }

  const onUserNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserName(e.target.value)
    editor.tf.setNodes(
      { user_name: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  }

  const onUserPositionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserPosition(e.target.value)
    editor.tf.setNodes(
      { user_position: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  }

  const getInitials = (fullName: string): string => {
    const names = fullName.split(' ');
    let initials = '';
    for (const name of names) {
      if (name.length > 0) {
        initials += name[0];
      }
    }
    return initials.slice(0, 2).toUpperCase();
  }

  return (
    <div className={`flex items-center  min-w-full justify-center ${isMobile ? "flex-col gap-4" : "gap-8"}`} ref={parentRef} >
      <div
        onClick={isReadOnly ? undefined : setShowImagePicker.bind(setShowImagePicker, true)}
        className={`
          w-[200px] h-[200px] min-w-[200px] min-h-[200px] flex items-center flex-col bg-gray-100 
          rounded-full justify-center ${isReadOnly ? "" : "cursor-pointer"}`}
      >
        {
          block.image_url
            ?
            <img
              src={block.image_url}
              alt="img"
              className={`w-[200px] h-[200px] rounded-full ${isReadOnly ? "" : "cursor-pointer"} object-cover`}
              draggable={false}
            />
            :
            <>
              {
                isReadOnly
                  ? <div className="text-6xl brand-subtext capitalize">
                    {block.user_name ? getInitials(block.user_name) : ""}
                  </div>
                  :
                  <>
                    <PhIcon name='image' size={38} className="brand-subtext" />
                    <div className="text-base brand-subtext">Add Image</div>
                  </>
              }
            </>
        }
      </div>
      <div className={`py-2 flex flex-col  flex-grow  ${isMobile ? "w-full gap-2" : "max-w-[500px] gap-4"}`}>
        <div className="p-1">
          <AutoHeightTextarea
            value={testimonial || ''}
            onChange={onTestimonialChange}
            textClasses={`${textColor} text-base unset_input paragraph-font-family`}
            placeholder='Type testimonial here...'
            disabled={isReadOnly}
            paramToUpdate={parentWidth}
          />
        </div>
        <div className="p-1 flex flex-col gap-1">
          <AutoHeightTextarea
            value={userName || ''}
            placeholder={'Name Surname'}
            onChange={onUserNameChange}
            textClasses={`${textColor} text-base unset_input header-font-family`}
            disabled={isReadOnly}
            paramToUpdate={parentWidth}
          />
          <AutoHeightTextarea
            value={userPosition || ''}
            placeholder={'Position'}
            onChange={onUserPositionChange}
            textClasses={`${textColor} text-base  unset_input paragraph-font-family`}
            disabled={isReadOnly}
            paramToUpdate={parentWidth}
          />
        </div>
      </div>
      <ImagePicker
        currentImageUrl={block.image_url as string}
        backgroundImageSource={block.image_source as string}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={!template}
      />
    </div>
  )
}

export default SliderLayout;