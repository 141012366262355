import React from "react";
import { withRef } from '@udecode/cn';
import { PlateElement, useReadOnly } from "@udecode/plate/react";
import { NodeApi } from "@udecode/plate";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { PlateTestimonialElement } from "../../plate-config/Plugins/Testimonial/Testimonial.plugin";
import { PlateTestimonialGroupElement } from "../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import SliderLayout from "./SliderLayout/SliderLayout";
import CardLayout from "./CardLayout/CardLayout";


export const TestimonialVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const isReadOnly = useReadOnly();

    const block = element as PlateTestimonialElement
    const elementNode = editor.api.node({ at: [], match: (n: any) => n.id === block.id })!;
    const parentNode = NodeApi.parent(editor, elementNode[1]) as PlateTestimonialGroupElement;

    const hasUserName = !!block.user_name && block.user_name.trim().length > 0;
    const hasUserPosition = !!block.user_position && block.user_position.trim().length > 0;
    const hasContent = !!block.content && block.content.trim().length > 0;

    if (isReadOnly && (!hasUserName || !hasUserPosition || !hasContent)) {
      return null
    }

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`${className} flex ${parentNode.template === 'slider' ? "min-w-full w-full" : "w-[420px] self-stretch"}`}
        contentEditable={false}
        suppressContentEditableWarning
        data-plate-selectable
        data-id={block.id}
        {...props}
      >
        {children}
        {
          parentNode.template === 'slider'
            ? <SliderLayout block={block} />
            : <CardLayout block={block} />
        }
      </PlateElement>
    );
  }
);

export default TestimonialVoidElement
