import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, horizontalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import MenuListItem from './MenuListItem/MenuListItem';
import { useAppSelector } from '../../store/hooks/redux-hooks';

interface Props {
  itemsToDisplay: any[]
  onOrderUpdate: (items: any) => void;
  text_color: string,
  onLinkDelete: (id: string) => void,
  onLinkUpdate: (id: string, title: string | null, url_type: string, section_id?: string, page_id?: string) => void,
  isMobile?: boolean
  isLoading?: boolean
  closeMenu?: () => void
}

const BuilderNavMenu: React.FC<Props> = ({
  itemsToDisplay, onOrderUpdate, text_color, onLinkDelete, isMobile = false, isLoading, onLinkUpdate, closeMenu
}) => {

  const [draggableItems, setDraggableItems] = useState(itemsToDisplay)
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setDraggableItems(itemsToDisplay)
  }, [itemsToDisplay])

  const items = draggableItems.map((item: any) => ({ id: item.id || '', ...item }));

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id && over.id && active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        setDraggableItems(updatedItems)
        onOrderUpdate(updatedItems);
      }
    }
  };

  const readOnlyMode = readOnly || isLandingPage || isLoading

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={items}
        strategy={horizontalListSortingStrategy}
        disabled={readOnlyMode}
      >
        <div className={`flex  ${isMobile ? "flex-col gap-4" : "gap-8"}`}>
          {items.map((el) =>
            <MenuListItem
              key={el.id}
              item={el}
              text_color={text_color}
              onLinkDelete={onLinkDelete}
              onLinkUpdate={onLinkUpdate}
              isLoading={isLoading}
              closeMenu={closeMenu}
            />
          )}
        </div>
      </SortableContext>
    </DndContext>
  );
};


export default BuilderNavMenu;

