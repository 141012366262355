import React from "react";
import { useEditorRef } from "@udecode/plate/react";

import { PlateSectionElement } from "../../../../plate-config/Plugins/Section/Section.plugin";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import { textColorFromColorClass } from "../../../../utils/color.util";
import { createDefaultH1Element, createDefaultParagraphElement } from "../../../../plate-config/Plugins/DefaultMockups/DefaultMockups";
import { createDefaultColumnGroupElement } from "../../../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin";
import PhIcon from "../../../../shared/Icon/PhIcon";
import { createDefaultButtonGroupElement } from "../../../../plate-config/Plugins/Button/ButtonGroup.plugin";
import { createDefaultButtonElement } from "../../../../plate-config/Plugins/Button/Button.plugin";
import { createColumnWithImageElement, createDefaultColumnElement } from "../../../../plate-config/Plugins/ColumnGroup/Column.plugin";
import { createDefaultCardGroupElement } from "../../../../plate-config/Plugins/CardGroup/CardGroup.plugin";
import { createDefaultCardElement } from "../../../../plate-config/Plugins/CardGroup/Card.plugin";
import { createDefaultTestimonialGroupElement } from "../../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import { createDefaultTestimonialElement } from "../../../../plate-config/Plugins/Testimonial/Testimonial.plugin";

type Props = {
  sectionElement: PlateSectionElement
}

const Templates: React.FC<Props> = ({ sectionElement }) => {
  const editor = useEditorRef()
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)

  const sectionBgColor = sectionElement.tint_color ? sectionElement.tint_color : false
  const sectionTextColor = sectionElement.font_color ? sectionElement.font_color : false
  const sectionActiveColor = sectionBgColor ? sectionBgColor : sectionTextColor ? sectionTextColor : false
  const dynamicTextColor = sectionActiveColor ? `text-${textColorFromColorClass(sectionActiveColor, colors)}` : "text-black"
  const placeholderConfig: any[] = [
    { template: 'sectionTemplate1', icon: 'sectionTemplate1' },
    { template: 'sectionTemplate2', icon: 'sectionTemplate2' },
    { template: 'sectionTemplate3', icon: 'sectionTemplate3' },
    { template: 'sectionTemplate4', icon: 'sectionTemplate4' }

  ]
  const node = editor.api.node({ at: [], match: { id: sectionElement.id } })!
  const paragraphLong = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam"

  const onTemplateAdd = (template: string) => {
    switch (template) {
      case 'sectionTemplate1':
        editor.tf.insertNodes([
          { ...createDefaultH1Element(), children: [{ text: 'Headline' }], align: 'center' },
          { ...createDefaultParagraphElement(), children: [{ text: paragraphLong }], align: 'center' },
          {
            ...createDefaultButtonGroupElement(),
            align: 'center',
            children: [{ ...createDefaultButtonElement(), children: [{ text: 'Action Button' }] }]
          }
        ],
          { at: [...node[1], 1], select: true }
        )
        editor.tf.removeNodes({ at: [...node[1], 0] })
        break
      case 'sectionTemplate2':
        editor.tf.insertNodes([
          {
            ...createDefaultColumnGroupElement(),
            children: [
              createColumnWithImageElement(),
              {
                ...createDefaultColumnElement(),
                align: 'center',
                children: [
                  { ...createDefaultH1Element(), children: [{ text: 'Headline' }] },
                  { ...createDefaultParagraphElement(), children: [{ text: paragraphLong }] },
                ]
              }
            ]
          }
        ],
          { at: [...node[1], 1], select: true }
        )
        editor.tf.removeNodes({ at: [...node[1], 0] })
        break
      case 'sectionTemplate3':
        editor.tf.insertNodes([
          { ...createDefaultH1Element(), children: [{ text: 'Headline' }], align: 'center' },
          { ...createDefaultParagraphElement(), children: [{ text: paragraphLong }], align: 'center' },
          {
            ...createDefaultCardGroupElement(),
            visual_type: 'icon',
            align: 'center',
            children: [
              { ...createDefaultCardElement(), title: "Item 1", icon: 'FaPlus', content: 'Pulvinar velit cum consectetur pharetra neque eget mi elementum' },
              { ...createDefaultCardElement(), title: "Item 2", icon: 'FaPlus', content: 'Pulvinar velit cum consectetur pharetra neque eget mi elementum' },
              { ...createDefaultCardElement(), title: "Item 3", icon: 'FaPlus', content: 'Pulvinar velit cum consectetur pharetra neque eget mi elementum' },
            ]
          }
        ],
          { at: [...node[1], 1], select: true }
        )
        editor.tf.removeNodes({ at: [...node[1], 0] })
        break
      case 'sectionTemplate4':
        editor.tf.insertNodes([
          { ...createDefaultH1Element(), children: [{ text: 'Headline' }], align: 'center' },
          { ...createDefaultParagraphElement(), children: [{ text: paragraphLong }], align: 'center' },
          {
            ...createDefaultTestimonialGroupElement(),
            children: [
              { ...createDefaultTestimonialElement(), content: paragraphLong, user_name: 'Pulvinar Velit', user_position: 'Pharetra neque eget mi elementum' },
              { ...createDefaultTestimonialElement(), content: paragraphLong, user_name: 'Pulvinar Velit', user_position: 'Pharetra neque eget mi elementum' },
            ]
          }
        ],
          { at: [...node[1], 1], select: true }
        )
        editor.tf.removeNodes({ at: [...node[1], 0] })
        break
      default:
        break
    }
  }

  return (
    <div
      className="mt-16 w-full flex flex-col items-start justify-center"
      contentEditable={false}
      suppressContentEditableWarning
    >
      <span className={`${dynamicTextColor} text-base brand-text font-semibold cursor-default`}>
        Or continue with a template:
      </span>
      <div className="mt-4 flex items-center gap-3 flex-wrap w-full">
        {placeholderConfig.map((config, index) => (
          <div
            key={index}
            className="flex items-center justify-center cursor-pointer transition-transform duration-200 hover:scale-[105%]"
            onClick={onTemplateAdd.bind(onTemplateAdd, config.template)}
          >
            <PhIcon name={config.icon} />
          </div>
        ))}
        {/* Not really sure that we'll need that in future. Let's keep it for a while. */}
        {/* <button
          className="bg-white border brand-text underline text-center border-black rounded-lg w-[175px] h-[120px]"
          onClick={setShowTemplatesDialog.bind(setShowTemplatesDialog, true)}
        >
          See All Templates
        </button> */}
      </div>
      {/* Not really sure that we'll need that in future. Let's keep it for a while. */}
      {/* <TemplatesDialog
        modalOpen={showTemplatesDialog}
        onModalClose={setShowTemplatesDialog.bind(setShowTemplatesDialog, false)}
        onTemplateAdd={onTemplateAdd}
      /> */}
    </div>
  )
}

export default Templates