import React from "react";
import { useEditorRef } from "@udecode/plate/react";

import { Popover, PopoverTrigger, PopoverContent } from "../../../../../shared/Popover/Popover"
import { PlatePricingElement, PricingTable } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import ToolbarColorSelector from "../../../../../shared/ToolbarControls/ToolbarColorSelector";
import PhIcon from "../../../../../shared/Icon/PhIcon";

type Props = {
  tableIndex: number
  element: PlatePricingElement
}

const Toolbar: React.FC<Props> = ({ tableIndex, element }) => {
  const editor = useEditorRef()

  const onPrimaryColorChange = (primary_color: string) => {
    editor.tf.setNodes(
      { primary_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onSecondaryColorHandle = (secondary_color: string) => {
    editor.tf.setNodes(
      { secondary_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableDeleteHandle = () => {
    if (window.confirm('Are you sure you would like to delete this table section?')) {
      const pricing_tables_attributes = element.pricing_tables_attributes.filter((_table, i) => i !== tableIndex)
      editor.tf.setNodes(
        { pricing_tables_attributes } as Partial<Node>,
        { at: [], match: (n: any) => n.id === element.id }
      )
    }
  }

  const onTableCopyHandle = () => {
    const pricing_tables_attributes = [...element.pricing_tables_attributes]
    pricing_tables_attributes.splice(tableIndex + 1, 0, element.pricing_tables_attributes[tableIndex])
    editor.tf.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableMoveUpHandle = () => {
    const pricing_tables_attributes = [...element.pricing_tables_attributes] as PricingTable[]
    [pricing_tables_attributes[tableIndex], pricing_tables_attributes[tableIndex - 1]] = [pricing_tables_attributes[tableIndex - 1], pricing_tables_attributes[tableIndex]]
    editor.tf.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableMoveDownHandle = () => {
    const pricing_tables_attributes = [...element.pricing_tables_attributes] as PricingTable[]
    [pricing_tables_attributes[tableIndex], pricing_tables_attributes[tableIndex + 1]] = [pricing_tables_attributes[tableIndex + 1], pricing_tables_attributes[tableIndex]]
    editor.tf.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <div className="btn-group">
      <Popover>
        <PopoverTrigger className="btn btn-small btn-white w-10 centered bg-white">
          <div>
            <PhIcon name={"settings"} size={16} />
          </div>
        </PopoverTrigger>
        <PopoverContent sideOffset={0} side="right" align="center">
          <div className="w-72 border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30 p-4">
            <ToolbarColorSelector
              label="Table Color"
              color={element.primary_color as string}
              onColorChange={onPrimaryColorChange}
              labelClassName="uppercase font-semibold text-sm text-left text-gray-700"
              wrapperClassName="max-w-64"
            />
            <ToolbarColorSelector
              label="Summary Color"
              color={element.secondary_color as string}
              onColorChange={onSecondaryColorHandle}
              labelClassName="uppercase font-semibold text-sm text-left text-gray-700"
              wrapperClassName="max-w-64"
            />
          </div>
        </PopoverContent>
      </Popover>
      <button
        className="btn btn-small btn-white w-10 centered"
        onClick={onTableMoveUpHandle}
        disabled={tableIndex === 0}
      >
        <PhIcon name="caretUp" size={16} />
      </button>
      <button
        className="btn btn-small btn-white w-10 centered"
        onClick={onTableMoveDownHandle}
        disabled={!(element.pricing_tables_attributes.length > tableIndex + 1)}
      >
        <PhIcon name="caretDown" size={16} />
      </button>
      <button
        className="btn btn-small btn-white w-10 centered"
        onClick={onTableCopyHandle}
      >
        <PhIcon name="copy" size={16} />
      </button>
      <button
        className="btn btn-small btn-white w-10 centered last-child"
        onClick={onTableDeleteHandle}
      >
        <PhIcon name="trash" size={16} />
      </button>
    </div>
  )
}

export default Toolbar