import React from "react";
import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { getListRoot, toggleList, unwrapList } from "@udecode/plate-list";
import { ParagraphPlugin, useEditorRef, useEditorSelector } from "@udecode/plate/react";
import { TElement } from "@udecode/plate";

import { turnIntoItems } from "./TurnIntoDefaultNodes";
import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";


const TurnIntoUl: React.FC = () => {
  const editor = useEditorRef();

  const defaultItem = turnIntoItems.find((item) => item.value === ParagraphPlugin.key)!;
  const selectedItem = useEditorSelector((editor) => {
    const entry = editor.api.parent(editor.selection as any)?.[0] as TElement
    if (entry) {
      return turnIntoItems.find((item) => {
        if (entry && entry.type === 'lic') {
          const listRootElement = getListRoot(editor);
          if (listRootElement) {
            return listRootElement[0].type === item.value;
          }
        }
        if (entry) {
          return item.value === entry.type;
        }
        return item.value === ParagraphPlugin.key;
      }) || defaultItem
    } else {
      return defaultItem;
    }
  }, []);

  const isSelectedItemUl = selectedItem.value === BulletedListPlugin.key;

  const onValueHandle = () => {
    if (isSelectedItemUl) {
      unwrapList(editor)
      editor.tf.toggleBlock(ParagraphPlugin.key)
      editor.tf.select(editor.selection!);
      editor.tf.focus();
      return
    }
    if (selectedItem.value === NumberedListPlugin.key) {
      toggleList(editor, { type: BulletedListPlugin.key });
      editor.tf.select(editor.selection!);
      editor.tf.focus();
      return
    }
    editor.tf.toggleBlock(BulletedListPlugin.key);
    editor.tf.select(editor.selection!);
    editor.tf.focus();
  }

  return <BlockMenubarTrigger
    labelIcon="listBullets"
    onClick={onValueHandle}
    isOpened={isSelectedItemUl}
    tooltip="Unordered List"
    containerId="floating-toolbar"
  />
}

export default TurnIntoUl;