import React, { useEffect, useRef, useState } from "react";
import { withRef } from '@udecode/cn';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PlateElement, useReadOnly } from "@udecode/plate/react";
import { NodeApi } from "@udecode/plate";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { colorClassToRGB } from "../../utils/color.util";
import { PlateButtonElement } from "../../plate-config/Plugins/Button/Button.plugin";
import { Popover, PopoverContent, PopoverPortal, PopoverAnchor } from "../../shared/Popover/Popover";
import { PlateButtonGroupElement } from "../../plate-config/Plugins/Button/ButtonGroup.plugin";
import PreviewButton from "./PreviewButton/PreviewButton";
import ReadOnlyButton from "./ReadOnlyButton/ReadOnlyButton";
import ButtonMenubar from "./ButtonMenubar/ButtonMenubar";


export const ButtonVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const block = element as PlateButtonElement
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: block.id });
    const [content, setContent] = useState(block.content || '')
    const isReadOnly = useReadOnly();
    const { resources } = useAppSelector(state => state.page_resources)
    const { activePalette } = useAppSelector(state => state.page_colors)
    const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)
    const all_pages = useAppSelector(state => state.page_navigation.all_pages)
    const [open, setOpen] = useState(false)
    const measureRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const elementNode = editor.api.node({ at: [], match: (n: any) => n.id === block.id })!;
    const parentNode = elementNode && NodeApi.parent(editor, elementNode[1]) as PlateButtonGroupElement;
    const activeSize = parentNode?.button_size || 'medium'

    useEffect(() => {
      if (measureRef.current && inputRef.current) {
        const widthToAdd = activeSize === 'small' ? 14 : activeSize === 'medium' ? 46 : 58;
        const minWidth = 125;
        const measuredWidth = measureRef.current.offsetWidth + widthToAdd;
        inputRef.current.style.width =
          `${Math.max(minWidth, measuredWidth)}px`;
      }
    }, [content, activeSize]);

    useEffect(() => {
      editor.tf.setNodes({ menuOpen: open } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    }, [open])

    const matchingPage = all_pages?.find(page => page.id === block.internal_page_id)
    const matchingResource = resources?.find(resource => resource.id === block.internal_resource_id)
    const externalLinkPath = block.url && block.url.length > 0 ? block.url : null
    const internalLinkPath = matchingPage ? isLandingPage ? matchingPage.landing_link : matchingPage.builder_link : null
    const resourceLinkPath = matchingResource ? matchingResource.goto_url : null
    const goToLink = block.link_type === "external" ? externalLinkPath :
      block.link_type === "internal" ? internalLinkPath :
        block.link_type === "resource" ? resourceLinkPath : null
    const missingUrl =
      (block.link_type === "external" && !block.url) ||
      (block.link_type === "internal" && !block.internal_page_id) ||
      (block.link_type === "resource" && !block.internal_resource_id && !block.internal_template_resource_slot_id)
    const bgColor = block.primary_color ? colorClassToRGB(block.primary_color, 100, activePalette.colors) : null
    const textColor = block.secondary_color ? block.secondary_color : 'text-white'
    const style = { transform: CSS.Transform.toString(transform), transition };
    const childNotifications = parentNode.children.map(child => {
      const missingUrl =
        (child.link_type === "external" && !child.url) ||
        (child.link_type === "internal" && !child.internal_page_id) ||
        (child.link_type === "resource" && !child.internal_resource_id && !child.internal_template_resource_slot_id)
      const titleLimit = !!child.content ? child.content?.length >= 30 : false
      return missingUrl || titleLimit
    })
    const hasNotifications = childNotifications.includes(true)

    const onContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setContent(event.target.value)
      editor.tf.setNodes({ content: event.target.value } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    }
    const preventDefault = (e: Event) => e.preventDefault();
    const onDndClick = () => open && setOpen(false)
    const onWrapperClick = () => !open && setOpen(true)

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`${className} button-block flex`}
        contentEditable={false}
        suppressContentEditableWarning
        data-id={block.id}
        {...props}
        // The only way to fix drag preview size issue, without it the preview takes all available height
        style={{ transform: 'translate3d(0, 0, 0)' }}
      >
        {children}
        <Popover open={isReadOnly ? false : open} onOpenChange={setOpen}  >
          <PopoverAnchor>
            {isReadOnly
              ?
              <ReadOnlyButton
                block={block}
                bgColor={bgColor}
                textColor={textColor}
                goToLink={goToLink}
                activeSize={activeSize}
              />
              :
              <PreviewButton
                setNodeRef={setNodeRef}
                containerStyles={style}
                isReadOnly={isReadOnly}
                attributes={attributes}
                listeners={listeners}
                setActivatorNodeRef={setActivatorNodeRef}
                onDnDClick={onDndClick}
                onWrapperClick={onWrapperClick}
                measureRef={measureRef}
                content={content}
                inputRef={inputRef}
                onContentChange={onContentChange}
                bgColor={bgColor}
                textColor={textColor}
                activeSize={activeSize}
                missingUrl={missingUrl}
                hasNotifications={hasNotifications}
              />
            }
          </PopoverAnchor>
          <PopoverPortal>
            <PopoverContent
              onOpenAutoFocus={preventDefault}
              onCloseAutoFocus={preventDefault}
              side='top'
              sideOffset={10}
              align='center'
            >
              <ButtonMenubar block={block} goToLink={goToLink} />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PlateElement>
    );
  }
);

export default ButtonVoidElement
