import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import MediaInput from "../../../shared/Inputs/MediaInput";
import { PlateVideoElement } from "../../../plate-config/Plugins/Video/Video.plugin";


type Props = {
  showTrigger: boolean;
  block: PlateVideoElement;
  onUrlChange: () => void;
  onUrlSubmit: (url: string) => void;
  error: boolean;
}

const Menubar: React.FC<Props> = ({ showTrigger, block, onUrlChange, onUrlSubmit, error }) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onElementDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onAlignChange = (align: string) => editor.tf.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => { return n.id === block.id } })

  const align = block.align || 'center'
  const alignOptions: any = [
    { key: "left", iconName: "alignLeft" },
    { key: "center", iconName: "alignCenterVertical" },
    { key: "right", iconName: "alignRight" }
  ]

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      <MenuBar.Menu value="video_url">
        <MediaInput
          value={block.url}
          onValueChange={onUrlChange}
          onValueSubmit={onUrlSubmit}
          placeholder="Insert a link to the video..."
          error={error ? { message: 'Link address is not correct.' } : undefined}
        />
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      {block.url &&
        <>
          <MenuBar.Menu value="video_align">
            <BlockMenubarTrigger
              isDropdown
              iconBeforeLabel={alignOptions.find(item => item.key === align)?.iconName}
              isOpened={value === 'video_align'}
              tooltip="Align"
              containerId={block.id}
            />
            <MenuBar.Portal>
              <BlockMenubarContent>
                <BlockMenubarOptionList
                  data={alignOptions}
                  onOptionClick={onAlignChange}
                  activeKey={align}
                />
              </BlockMenubarContent>
            </MenuBar.Portal>
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
        </>
      }
      <MenuBar.Menu value="embed_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onElementDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;