import React, { useState } from "react";
import { withRef } from '@udecode/cn';
import { PlateElement, useReadOnly } from "@udecode/plate/react";
import { NodeApi } from "@udecode/plate";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { PlateCardElement } from "../../plate-config/Plugins/CardGroup/Card.plugin";
import { PlateCardGroupElement } from "../../plate-config/Plugins/CardGroup/CardGroup.plugin";
import { colorClassToRGB } from "../../utils/color.util";
import ImageVisualType from "./ImageVisualType/ImageVisualType";
import EmojiVisualType from "./EmojiVisualType/EmojiVisualType";
import IconVisualType from "./IconVisualType/IconVisualType";
import AutoHeightTextarea from "../../shared/AutoHeightTextarea/AutoHeightTextarea";


const CardVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateCardElement
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isReadOnly = useReadOnly()
    const [title, setTitle] = useState(block.title)
    const [content, setContent] = useState(block.content)
    const activePalette = useAppSelector(state => state.page_colors.activePalette)

    const elementNode = editor.api.node({ at: [], match: (n: any) => n.id === block.id })!;
    const parentNode = NodeApi.parent(editor, elementNode[1]) as PlateCardGroupElement;
    const textColor = parentNode.text_color ? parentNode.text_color : 'text-black'
    const bg_color = parentNode.card_bg_color as string || 'color-white'
    const bg_opacity = typeof parentNode.card_bg_opacity === 'number' ? parentNode.card_bg_opacity : 100
    const parentAlign = parentNode.align || 'left'
    const text_align = parentAlign === 'center' ? 'text-center' : parentAlign === 'right' ? 'text-right' : 'text-left'
    const visual_type = parentNode.template || 'none'

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value)
      editor.tf.setNodes(
        { title: e.target.value } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )
    }
    const onContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setContent(e.target.value)
      editor.tf.setNodes(
        { content: e.target.value } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )
    }

    const hasTitle = !!block.title && block.title.trim().length > 0;
    const hasContent = !!block.content && block.content.trim().length > 0;

    if (isReadOnly && (!hasTitle || !hasContent)) {
      return null
    }

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`${className} p-5 flex flex-col rounded-2xl border border-black/20 shadow-md self-stretch`}
        contentEditable={false}
        suppressContentEditableWarning
        data-plate-selectable
        data-id={block.id}
        {...props}
        style={{ backgroundColor: colorClassToRGB(bg_color, bg_opacity, activePalette.colors)! }}
      >
        {children}
        {visual_type === 'image' && <ImageVisualType block={block} />}
        {visual_type === 'emoji' && <EmojiVisualType block={block} />}
        {visual_type === 'icon' && <IconVisualType block={block} />}
        <input
          value={title || ''}
          type="text"
          className={`
              w-full text-base unset_input header-font-family p-0 outline-none border-none focus:border-none
              focus:outline-none focus:shadow-none focus:drop-shadow-none focus:ring-0 mb-1
              ${textColor} bg-transparent paragraph-font ${text_align}
            `}
          placeholder={'Enter Title'}
          onChange={onTitleChange}
          maxLength={20}
        />
        <AutoHeightTextarea
          value={content || ''}
          onChange={onContentChange}
          textClasses={`${textColor} ${text_align} unset_input text-base paragraph-font-family font-normal`}
          placeholder='Enter Description'
          disabled={isReadOnly}
        />
      </PlateElement>
    );
  }
);

export default CardVoidElement
