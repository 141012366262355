import React, { useEffect } from 'react';
import { withRef } from '@udecode/cn';
import { PlateElement, useReadOnly, useSelected } from '@udecode/plate/react';

import { nonSlashCommandsBlocks } from '../../../utils/plate.util';
import { createDefaultParagraphElement } from '../../../plate-config/Plugins/DefaultMockups/DefaultMockups';
import { useAppSelector } from '../../../store/hooks/redux-hooks';
import { PlateColumnGroupElement } from '../../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import ColumnToolbar from './ColumnToolbar/ColumnToolbar';
import { PlateColumnElement } from '../../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { colorClassToRGB } from '../../../utils/color.util';
import ColumnDivider from './ColumnDivider/ColumnDivider';

export interface ColumnStyleTypes extends React.CSSProperties {
  '--column_plugin-background-image'?: string;
  '--column_plugin-background-filter'?: string;
  '--column_plugin-background-transform'?: string;
  '--column_plugin-background-blend-mode'?: string;
  '--column_plugin-background-color': string;
}

export const ColumnElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateColumnElement

    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const editorContainerWidth = useAppSelector(state => state.page_addendums.editorContainerWidth)
    const { activePalette } = useAppSelector(state => state.page_colors)
    const selected = useSelected();
    const isReadOnly = useReadOnly()

    useEffect(() => {
      if (!selected) {
        editor.tf.setNodes({ menuOpen: false } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
      }
    }, [selected])

    const isMobilePreviewActive = editorContainerWidth === 'mobile'
    const node = editor.api.node({ at: [], match: (n: any) => n.id === block.id })!
    const parentNode = editor.api.parent(node[1])!
    const col_group = parentNode[0] as PlateColumnGroupElement
    const isParentSelected = col_group.selected
    const isLastCol = col_group.children[col_group.children.length - 1].id === block.id
    const colGroupStyle = col_group.style as string
    const styleOptions = colGroupStyle.split('/');
    const colIndex = col_group.children.findIndex(child => child.id === block.id)

    const handleClick = (e: any) => {
      if (isReadOnly) return
      if (e.target === e.currentTarget) {
        const lastColumnChild = block.children[block.children.length - 1];
        const lastColumnChildType = lastColumnChild.type as string
        if (nonSlashCommandsBlocks.includes(lastColumnChildType)) {
          editor.tf.insertNodes(createDefaultParagraphElement(), { at: [...node[1], block.children.length], select: true });
        }
      } else return
    };

    const onColumnSwap = () => {
      const path = [...node[1]]
      path[path.length - 1] += 1
      editor.tf.moveNodes({ at: node[1], to: path })
    }

    const onDeleteColumn = () => {
      const childLength = col_group.children.length
      editor.tf.removeNodes({ at: [], match: (n: any) => n.id === element.id })
      editor.tf.setNodes({ style: childLength === 3 ? "50/50" : '33/33/33' } as Partial<Node>, { at: [], match: (n: any) => n.id === col_group.id })
    };
    const onAlignChange = (align: string) =>
      editor.tf.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
      editor.tf.setNodes({ image_url, image_source, image_external_id } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    const onImageUnset = () =>
      editor.tf.setNodes({ image_url: null, image_source: null, image_external_id: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    const onBgColorChange = (bg_color: string) =>
      editor.tf.setNodes({ bg_color, bg_opacity: typeof element.bg_opacity === "number" ? element.bg_opacity : 50 } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    const onBgColorReset = () =>
      editor.tf.setNodes({ bg_color: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    const onBgOpacityChange = (value: number[]) =>
      editor.tf.setNodes({ bg_opacity: value[0] } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

    const columnStyles: ColumnStyleTypes = {
      '--column_plugin-background-color': colorClassToRGB(block.bg_color as string, block.bg_opacity as number, activePalette.colors)!,
      ...(block.image_url
        ? {
          '--column_plugin-background-image': `url(${block.image_url})`,
          '--column_plugin-background-blend-mode': 'normal',
        }
        : {}),
    };

    const align = element.align ? element.align : 'top'

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={block}
        className={`
          ${className} column_plugin_wrapper column relative transition-all duration-300 ease-in-out
          after:rounded-[7px] before:rounded-[7px] flex
          ${!isReadOnly
            ? `border rounded-lg ${!selected ? "group-hover/col_group_wrapper:border-sky-50" : ""} 
            ${selected
              ? 'border-sky-50 hover:border-sky-50'
              : `${isParentSelected ? "border-sky-50 hover:border-sky-50" : "border-transparent"}`}
              `
            : ""}
        `}
        style={{ width: isMobilePreviewActive ? '100%' : `${styleOptions[colIndex]}%`, ...columnStyles }}
        {...props}
      >
        <div
          className={`relative z-[2] w-full p-2 flex flex-col
          ${align === 'top' ? 'justify-start' : align === 'center' ? 'justify-center' : 'justify-end'}
          `}
          onClick={handleClick}
        >
          {!isReadOnly && selected &&
            <ColumnToolbar
              colIndex={colIndex}
              element={block}
              onDeleteColumn={onDeleteColumn}
              onAlignChange={onAlignChange}
              onBgColorChange={onBgColorChange}
              onBgOpacityChange={onBgOpacityChange}
              setImageFromPicker={setImageFromPicker}
              onImageUnset={onImageUnset}
              onBgColorReset={onBgColorReset}
            />
          }
          {children}
        </div>
        {!isLastCol && !isReadOnly && isParentSelected && <ColumnDivider onColumnSwap={onColumnSwap} />}
      </PlateElement>
    );
  }
);
