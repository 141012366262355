import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { FontData, PageData } from '../api/api.types'
import { getWeights } from './page_fonts.config'

export interface PageFonts {
  header_font: string,
  header_weight: number,
  paragraph_font: string,
  paragraph_weight: number,
  paragraphAvailableWeights: number[],
  headerAvailableWeights: number[],
  customFonts: FontData[]
}

const initialState: PageFonts = {
  header_font: 'DM Sans',
  header_weight: 700,
  paragraph_font: 'DM Sans',
  paragraph_weight: 400,
  paragraphAvailableWeights: [],
  headerAvailableWeights: [],
  customFonts: []
}

export const pageFontsSlice = createSlice({
  name: 'page_fonts',
  initialState,
  reducers: {
    setPageFonts: (state, action: PayloadAction<PageData>) => {
      const paragraph_font = action.payload.styles.paragraph_font || state.paragraph_font
      const header_font = action.payload.styles.header_font || state.header_font
      let header_weight = action.payload.styles.header_weight ? parseInt(action.payload.styles.header_weight) : state.header_weight
      let paragraph_weight = action.payload.styles.paragraph_weight ? parseInt(action.payload.styles.paragraph_weight) : state.paragraph_weight
      const paragraphAvailableWeights: number[] = getWeights(paragraph_font, action.payload.styles.fonts) || []
      const headerAvailableWeights: number[] = getWeights(header_font, action.payload.styles.fonts) || []

      if (paragraphAvailableWeights.length > 0) {
        if (!paragraphAvailableWeights.includes(paragraph_weight)) {
          const closest = paragraphAvailableWeights.reduce(function (prev, curr) {
            return (Math.abs(curr - paragraph_weight) < Math.abs(prev - paragraph_weight) ? curr : prev);
          });
          paragraph_weight = closest
        }
      }

      if (headerAvailableWeights.length > 0) {
        if (!headerAvailableWeights.includes(header_weight)) {
          const closest = headerAvailableWeights.reduce(function (prev, curr) {
            return (Math.abs(curr - header_weight) < Math.abs(prev - header_weight) ? curr : prev);
          });
          header_weight = closest
        }
      }

      return {
        ...state,
        paragraph_font,
        header_font,
        paragraph_weight,
        header_weight,
        paragraphAvailableWeights,
        headerAvailableWeights,
        customFonts: action.payload.styles.fonts
      }
    },
    setHeaderFont: (state, action: PayloadAction<string>) => {
      const header_font = action.payload
      const headerAvailableWeights = getWeights(header_font, state.customFonts) || []
      let header_weight = state.header_weight
      if (headerAvailableWeights.length > 0) {
        if (!headerAvailableWeights.includes(header_weight)) {
          const closest = headerAvailableWeights.reduce(function (prev, curr) {
            return (Math.abs(curr - header_weight) < Math.abs(prev - header_weight) ? curr : prev);
          });
          header_weight = closest
        }
      }
      return {
        ...state,
        header_font,
        header_weight,
        headerAvailableWeights,
      }
    },
    setParagraphFont: (state, action: PayloadAction<string>) => {
      const paragraph_font = action.payload
      const paragraphAvailableWeights = getWeights(paragraph_font, state.customFonts) || []
      let paragraph_weight = state.paragraph_weight
      if (paragraphAvailableWeights.length > 0) {
        if (!paragraphAvailableWeights.includes(paragraph_weight)) {
          const closest = paragraphAvailableWeights.reduce(function (prev, curr) {
            return (Math.abs(curr - paragraph_weight) < Math.abs(prev - paragraph_weight) ? curr : prev);
          });
          paragraph_weight = closest
        }
      }
      return {
        ...state,
        paragraph_font,
        paragraph_weight,
        paragraphAvailableWeights,
      }
    },
    setHeaderWeight: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        header_weight: action.payload
      }
    },
    setParagraphWeight: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        paragraph_weight: action.payload
      }
    },
  },
})

export const { setPageFonts, setHeaderFont, setHeaderWeight, setParagraphFont, setParagraphWeight } = pageFontsSlice.actions

export default pageFontsSlice.reducer