import { createSlice } from '@reduxjs/toolkit'
import { HEADING_KEYS } from '@udecode/plate-heading'
import { BlockquotePlugin } from '@udecode/plate-block-quote/react'
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ParagraphPlugin } from '@udecode/plate/react'

import { PageData, PluginStatus } from '../api/api.types'
import { EmojiInputPlugin } from '../../../plate-config/Plugins/EmojiCommand/EmojiCommand.plugin'
import { HorizontalRulePlugin } from '../../../plate-config/Plugins/HrLine/HrLine.plugin'
import { CardGroupPlugin } from '../../../plate-config/Plugins/CardGroup/CardGroup.plugin'
import { TestimonialGroupPlugin } from '../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin'
import { ColumnGroupPlugin } from '../../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin'
import { EmbedPlugin } from '../../../plate-config/Plugins/Embed/Embed.plugin'
import { VideoPlugin } from '../../../plate-config/Plugins/Video/Video.plugin'
import { ButtonGroupPlugin } from '../../../plate-config/Plugins/Button/ButtonGroup.plugin'
import { SubscribeToNewsletterPlugin } from '../../../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin'
import { ImagePlugin } from '../../../plate-config/Plugins/Image/Image.plugin'
import { TemplateVariableInputPlugin } from '../../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin'
import { PricingPlugin } from '../../../plate-config/Plugins/Pricing/Pricing.plugin'
import { AssessmentPlugin } from '../../../plate-config/Plugins/Assessment/Assessment.plugin'
import { AiTextBlockPlugin } from '../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin'

export interface PagePlugins {
  pluginsStatus: PluginStatus[]
}

// Note: add only first level nodes that can be added with slash commands or fixed toolbar.
// Should be updated each time when you add new plugin.
const initialState: PagePlugins = {
  pluginsStatus: [
    { type: ParagraphPlugin.key, enabled: true },
    { type: HEADING_KEYS.h1, enabled: true },
    { type: HEADING_KEYS.h2, enabled: true },
    { type: BlockquotePlugin.key, enabled: true },
    { type: NumberedListPlugin.key, enabled: true },
    { type: BulletedListPlugin.key, enabled: true },
    { type: EmojiInputPlugin.key, enabled: true },
    { type: HorizontalRulePlugin.key, enabled: true },
    { type: CardGroupPlugin.key, enabled: true },
    { type: TestimonialGroupPlugin.key, enabled: true },
    { type: ColumnGroupPlugin.key, enabled: true },
    { type: EmbedPlugin.key, enabled: true },
    { type: VideoPlugin.key, enabled: true },
    { type: ButtonGroupPlugin.key, enabled: true },
    // This plugin is disabled by default because we don't want to show it in the toolbar
    // It was added for test purpose
    { type: SubscribeToNewsletterPlugin.key, enabled: false },
    { type: ImagePlugin.key, enabled: true },
    { type: TemplateVariableInputPlugin.key, enabled: true },
    { type: AiTextBlockPlugin.key, enabled: true },
    // PricingPlugin and AssessmentPlugin should be disabled by default.
    // It's old plugins, we support read/update/delete operations and prevent creation.
    { type: PricingPlugin.key, enabled: false },
    { type: AssessmentPlugin.key, enabled: false },
  ]
}

export const pagePlugins = createSlice({
  name: 'page_plugins_status',
  initialState,
  reducers: {
    setPluginStatus: (state, action: PayloadAction<PageData>) => {
      const { plugin_status } = action.payload
      const updatedPlugins = state.pluginsStatus.map(plugin => {
        const status = !!plugin_status ? plugin_status.find(pluginStatus => pluginStatus.type === plugin.type) : null
        return status ? { ...plugin, enabled: status.enabled } : plugin
      })
      return { ...state, pluginsStatus: updatedPlugins }
    },
  },
})

export const { setPluginStatus } = pagePlugins.actions

export default pagePlugins.reducer
