import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import { PlateElementAiTextBlock } from "../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";


type Props = {
  showTrigger: boolean;
  block: PlateElementAiTextBlock;
  activeView: string;
  onEditModalOpen: () => void;
}

const Menubar: React.FC<Props> = ({ showTrigger, block, activeView, onEditModalOpen }) => {
  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onBlockDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      {activeView === 'preview' &&
        <>
          <MenuBar.Menu value="ai_edit">
            <BlockMenubarTrigger labelIcon="pencil" onClick={onEditModalOpen} tooltip="Edit" containerId={block.id} />
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
        </>
      }
      <MenuBar.Menu value="ai_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onBlockDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;