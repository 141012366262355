import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileInput", "output"];

  readCSV(event) {
    const [file] = event.target.files; // Destructure files
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => this.processCSV(e.target.result);
    reader.readAsText(file, "UTF-8");
  }

  processCSV(csvText) {
    const csvData = this.parseCSV(csvText);

    if (csvData.length < 2) return;

    const csvHash = this.mapCSVToHash(csvData[0], csvData[1]);
    this.outputTarget.value = JSON.stringify(csvHash);
  }

  mapCSVToHash(headers, row) {
    return headers.reduce((acc, header, index) => {
      acc[header] = row[index] || "";
      return acc;
    }, {});
  }

  // Parses CSV formatted string into an array.
  parseCSV(strData) {
    const pattern = new RegExp(
      "(\\,|\\r?\\n|\\r|^)" + // Delimiters
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^\"\\,\\r\\n]*))",
      "gi"
    );

    const arrData = [[]];
    let matches;

    while ((matches = pattern.exec(strData))) {
      const delimiter = matches[1];

      // Start a new row if the delimiter is not a comma
      if (delimiter.length && delimiter !== ",") {
        arrData.push([]);
      }

      const matchedValue = matches[2]
        ? matches[2].replace(/""/g, "\"") // Replace double quotes
        : matches[3];

      arrData[arrData.length - 1].push(matchedValue);
    }
    return arrData;
  }
}
