import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import validator from 'validator';
import { PlateElement, useEditorReadOnly, useSelected, withRef } from "@udecode/plate/react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";
import Menubar from "./Menubar/Menubar";
import { PlateSubscribeToNewsletterElement } from "../../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin";
import { colorClassToRGB } from "../../utils/color.util";
import Dialog from "./Dialog/Dialog";
import { useSubscribeToNewsletterMutation } from "../../store/reducers/api/subscribe_to_newsletter/subscribe_to_newsletter.api";

interface IFormInput {
  name: string
  email: string
}

export const SubscribeToNewsletterVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isReadOnly = useEditorReadOnly();
    const selected = useSelected();
    const { register, handleSubmit, formState: { errors }, setError } = useForm<IFormInput>({
      defaultValues: {
        name: "",
        email: ""
      }
    });
    const activePalette = useAppSelector(state => state.page_colors.activePalette)
    const { isLandingPage, account_id } = useAppSelector(state => state.page_addendums)
    const page_id = useAppSelector(state => state.page_navigation.current_page_id)
    const [modalOpen, setModalOpen] = useState(false)
    const [subscribeToNewsletter, { isLoading }] = useSubscribeToNewsletterMutation();

    const readOnlyMode = isReadOnly || isLandingPage
    const block = element as PlateSubscribeToNewsletterElement;
    const nodePath = editor.api.node({ at: [], match: { id: block.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const openState = isReadOnly ? false : selected
    const bgColor = block.primary_color ? colorClassToRGB(block.primary_color, 100, activePalette.colors) : null
    const textColor = block.secondary_color ? block.secondary_color : 'text-white';

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
      if (isLandingPage) {
        await subscribeToNewsletter({
          name: data.name,
          email: data.email,
          account_id: account_id || '',
          page_id: page_id || ''
        }).then(({ data, error }) => {
          !!data && setModalOpen(true)
          !!error && setError('email', {
            type: 'manual',
            message: "Oops! Something went wrong. Please try again."
          })
        })
        return
      }
      setModalOpen(true)
    }
    const emptyNameCheck = (value: string, formValues: IFormInput) => {
      if (!value || value.trim().length < 1) return "Please enter a valid name";
      return true
    }
    const emailCheck = (value: string, formValues: IFormInput) => {
      if (!validator.isEmail(value)) return "Please enter a valid email";
      return true
    }

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={block}
        className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
          ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block subscribe-to-newsletter-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
          ${!isReadOnly ? `${selected ? 'border-sky-50 border hover:border-sky-50' : 'hover:border-sky-50 border border-transparent'}` : ''} 
        `}
        data-plate-selectable
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        {children}
        <Menubar block={block} showTrigger={openState} />
        <div className="flex w-full justify-center">
          <form onSubmit={handleSubmit(onSubmit)} className="flex max-w-[400px] w-full flex-col gap-4">
            <div className="flex flex-col gap-1">
              <input
                {...register("name", { required: 'Name is required', minLength: 1, maxLength: 70, validate: emptyNameCheck })}
                type="text"
                placeholder='Enter your name'
                className={`brand_input paragraph-font-family text-base ${errors.name ? 'error' : ''}`}
                autoComplete='off'
                disabled={!readOnlyMode}
              />
              {errors.name && <p className="mt-1 text-[#F46262] text-sm brand-font-family">{errors.name.message}</p>}
            </div>
            <div className="flex flex-col gap-1">
              <input
                {...register("email", { required: 'Email is required', minLength: 1, maxLength: 70, validate: emailCheck })}
                type="text"
                placeholder='Enter your email'
                className={`brand_input paragraph-font-family text-base ${errors.name ? 'error' : ''}`}
                autoComplete='off'
                disabled={!readOnlyMode}
              />
              {errors.email && <p className="mt-1 text-[#F46262] text-sm brand-font-family">{errors.email.message}</p>}
            </div>
            <button
              type='submit'
              onClick={handleSubmit(onSubmit)}
              className={` 
                outline-none border-none focus:border-none focus:outline-none focus:shadow-none 
                focus:drop-shadow-none focus:ring-0 rounded-2xl font-medium text-base text-center
                border w-full unset_input disabled:cursor-not-allowed px-4 py-3 paragraph-font-family
                ${textColor} ${!bgColor ? 'bg-[#333333] border-[#333333]' : ''}`}
              style={{
                ...(bgColor &&
                {
                  backgroundColor: bgColor,
                  borderColor: (bgColor === 'rgba(255, 255, 255, 1)' || bgColor === '#ffffff') ? "#333333" : bgColor,
                  borderStyle: 'solid'
                })
              }}
              disabled={!readOnlyMode || isLoading}
            >
              Subscribe
            </button>
          </form>
        </div>
        <Dialog modalOpen={modalOpen} onModalClose={setModalOpen.bind(setModalOpen, false)} />
      </PlateElement>
    )
  }
);

export default SubscribeToNewsletterVoidElement;
