import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarColors from "../../../shared/BlockMenubarControls/BlockMenubarColors";
import { PlateSubscribeToNewsletterElement } from "../../../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";


type Props = {
  showTrigger: boolean;
  block: PlateSubscribeToNewsletterElement;
}

const Menubar: React.FC<Props> = ({ showTrigger, block }) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onElementDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onButtonColorChange = (primary_color: string | null) =>
    editor.tf.setNodes({ primary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onTextColorChange = (secondary_color: string | null) =>
    editor.tf.setNodes({ secondary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      <MenuBar.Menu value="subscribe_colors">
        <BlockMenubarTrigger
          isDropdown isColor color={block.primary_color} isOpened={value === 'subscribe_colors'}
          tooltip="Colors"
          containerId={block.id}
        />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarColors
              color={block.primary_color}
              onColorChange={onButtonColorChange}
              onColorReset={onButtonColorChange.bind(onButtonColorChange, null)}
              label="Button Color"
            />
            <BlockMenubarColors
              color={block.secondary_color}
              onColorChange={onTextColorChange}
              onColorReset={onTextColorChange.bind(onTextColorChange, null)}
              label="Text Color"
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="subscribe_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onElementDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;