import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import isEqual from 'react-fast-compare';
import { Plate, useEditorMounted } from '@udecode/plate/react';
import { NodeApi, TElement } from '@udecode/plate';

import { Editor, EditorContainer } from '../plate-components/EditorContainer/editor';
import { FloatingToolbar } from '../plate-components/FloatingToolbar/floating-toolbar';
import { useAppSelector, useAppDispatch } from '../store/hooks/redux-hooks';
import { setSectionsFromEditor } from '../store/reducers/page_sections/page_sections';
import { useSaveSectionDataMutation } from '../store/reducers/api/sections/sections';
import { hasMoreThanThreeSecPassed } from '../utils/time.util';
import { updateShadowCalcVariables } from '../store/reducers/page-shadow-store/page_shadow_store';
import useAppEditor from './PlateConfig';
import FixedToolbar from '../plate-components/FixedToolbar/FixedToolbar';

const PlateEditor = () => {
  const dispatch = useAppDispatch()
  const page_sections = useAppSelector(state => state.page_sections)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)
  const isTemplate = useAppSelector(state => state.page_navigation.template)
  const calc_vars = useAppSelector(state => state.page_calc_variables)
  const isReadOnly = useAppSelector(state => state.page_addendums.readOnly)
  const [saveEditorStateToApi, { isLoading }] = useSaveSectionDataMutation()
  const isEditorReady = useEditorMounted()
  // DOCUMENTATION: put here any initial state to debug editor
  const initialSections = page_sections.sections as TElement[]
  const [editorState, setEditorState] = useState(initialSections)

  // ----------- SHADOW STORE CONTROLLER -----------
  useEffect(() => {
    if (isReadOnly || isLandingPage) {
      dispatch(updateShadowCalcVariables(calc_vars))
    }
  }, [isReadOnly, isLandingPage])
  // ----------- SHADOW STORE CONTROLLER -----------

  const lastUpdated = page_sections.lastUpdated
  const lastSavedSections = page_sections.lastSavedSections as TElement[]
  const onEditorValueChange = ({ value }) => setEditorState(value)
  //CRITICAL: useMemo is used to prevent re-creating editor instance on every render 
  const editor = useMemo(() => useAppEditor(isTemplate, editorState, "editor"), []);

  // ----------- EDITOR_DATA SAVING PROCESS -----------
  //  CRITICAL: Save data to API if more than 5 seconds have passed since last update
  const shouldSyncWithAPI = async () => {
    if (!isEqual(editorState, lastSavedSections) && !isLoading) {
      await saveEditorStateToApi(editorState)
    }
  }
  // CRITICAL: Save data to API in case user stoped adding content and shouldSyncWithAPI was not triggered
  // during last 5 seconds
  useEffect(() => {
    const intervalId = setInterval(async () => {
      await shouldSyncWithAPI()
    }, 5 * 1000);
    return () => clearInterval(intervalId);
  }, [editorState, lastSavedSections]);

  //  CRITICAL: Save data to API if more than 3 seconds have passed since last update
  const syncWithAPI = async () => {
    if (hasMoreThanThreeSecPassed(new Date(lastUpdated)) && !isEqual(editorState, page_sections.sections) && !isLoading) {
      await saveEditorStateToApi(editorState)
    }
  }
  // ----------- EDITOR_DATA SAVING PROCESS -----------

  // CRITICAL: This is workaround to track editor changes
  useEffect(() => {
    //  CRITICAL: This is not only one place where we save data to API, but it is the most important one.
    //  second place to check saving process is in App.tsx
    if (isLandingPage || isReadOnly) return
    // sync with API
    syncWithAPI()
    // set editor state to redux store
    dispatch(setSectionsFromEditor(editorState))
  }, [editorState, isLandingPage, isReadOnly])

  // CRITICAL: This is workaround to scroll to section by id
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
          !isLandingPage && window.history.pushState("", document.title, window.location.pathname + window.location.search);
        }
      }, 500);
    }
  }, []);

  // Focus editor on page load
  useEffect(() => {
    if (!isReadOnly && !isLandingPage && isEditorReady) {
      const firstSection = editor.children[0];
      if (firstSection.type !== 'section') return;
      const firstSectionTextChild = NodeApi.firstText(editor, { from: [0] });
      editor.tf.focus()
      editor.tf.setSelection({ anchor: { path: firstSectionTextChild![1], offset: 0 }, focus: { path: firstSectionTextChild![1], offset: 0 } })
    }
  }, [isLandingPage, isReadOnly, isEditorReady]);


  return (
    <DndProvider backend={HTML5Backend}>
      <Plate
        editor={editor}
        onChange={onEditorValueChange}
        readOnly={isLandingPage ? isLandingPage : isReadOnly}
      >
        <EditorContainer>
          <Editor />
        </EditorContainer>
        {!isReadOnly && <FloatingToolbar />}
        {!isReadOnly && <FixedToolbar />}
      </Plate>
    </DndProvider>
  );
}

export default PlateEditor;