import { type TriggerComboboxPluginOptions, withTriggerCombobox } from '@udecode/plate-combobox';
import { type PluginConfig, type TElement, createSlatePlugin, createTSlatePlugin } from '@udecode/plate';

export type VariableConfig = PluginConfig<'template_variable', TriggerComboboxPluginOptions>;

export const TemplateVariableInputPlugin = createSlatePlugin({
  key: 'template_variable_input',
  node: { isElement: true, isInline: true, isVoid: true },
});

export const TemplateVariablePlugin = createTSlatePlugin<VariableConfig>({
  key: 'template_variable',
  node: {
    isElement: true,
    isInline: true,
    isVoid: true,
  },
  options: {
    createComboboxInput: (trigger) => ({
      children: [{ text: '' }],
      trigger,
      type: TemplateVariableInputPlugin.key,
    }),
    trigger: '{',
    triggerPreviousCharPattern: /^\s?$/,
  },
  plugins: [TemplateVariableInputPlugin],
}).overrideEditor(withTriggerCombobox);

export interface PlateTemplateVariableElement extends TElement {
  id: string,
  type: typeof TemplateVariablePlugin.key,
  variableId: string,
}

export const createDefaultTemplateVariableInputElement = (): TElement => ({
  type: TemplateVariableInputPlugin.key,
  children: [{ text: '' }],
})