import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useEditorRef, useReadOnly } from "@udecode/plate/react";

import { PlatePricingElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";
import PhIcon from "../../../../../shared/Icon/PhIcon";


type Props = {
  tableIndex: number
  element: PlatePricingElement
}

const Description: React.FC<Props> = ({ tableIndex, element }) => {
  const editor = useEditorRef()
  const isReadOnly = useReadOnly()
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)
  const editorContainerWidth = useAppSelector(state => state.page_addendums.editorContainerWidth)

  const table = element.pricing_tables_attributes[tableIndex]
  const isMobilePreview = editorContainerWidth === 'mobile'

  const onTablePriceHideToggle = () => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, hide_price: !table.hide_price }
      }
      return table
    })
    editor.tf.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableQuantityHideToggle = () => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, hide_quantity: !table.hide_quantity }
      }
      return table
    })
    editor.tf.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onTableValueHideToggle = () => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, hide_value: !table.hide_value }
      }
      return table
    })
    editor.tf.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <>
      {isMobilePreview &&
        <TableRow className="bg-white">
          <TableCell colSpan={5} align="left" valign="middle" sx={isMobilePreview ? { padding: '5px' } : { padding: '5px', paddingTop: "25px", border: 'unset', minWidth: '100px' }}>
            <div className="header-font-family text-sm">Description</div>
          </TableCell>
        </TableRow >
      }
      <TableRow className="bg-white">
        {
          !isMobilePreview &&
          <TableCell colSpan={2} align="left" valign="middle" sx={isMobilePreview ? { padding: '5px' } : { padding: '5px', paddingTop: "25px", border: 'unset', minWidth: '100px' }}>
            <div className="header-font-family text-sm">Description</div>
          </TableCell>
        }
        {table.hide_price && (isReadOnly || isLandingPage)
          ? null
          :
          <TableCell align="left" colSpan={1} valign="middle" sx={isMobilePreview ? { padding: '5px' } : { padding: '5px', paddingTop: "25px", border: 'unset', minWidth: '100px' }}>
            <div className="flex items-center justify-start">
              <span className={`header-font-family text-sm ${table.hide_price ? "text-gray-500" : ""}`}>Price</span>
              {!isMobilePreview && !isReadOnly &&
                <div
                  className="btn btn-clear text-gray-400 text-sm px-3 py-0"
                  onClick={onTablePriceHideToggle}
                >
                  <PhIcon name={table.hide_price ? "eyeSlash" : "eye"} size={16} />
                </div>
              }
            </div>
          </TableCell >
        }
        {table.hide_quantity && (isReadOnly || isLandingPage)
          ? null
          :
          <TableCell align="left" colSpan={1} valign="middle" sx={isMobilePreview ? { padding: '5px' } : { padding: '5px', paddingTop: "25px", border: 'unset', minWidth: '100px' }}>
            <div className="flex items-center justify-center">
              <span className={`header-font-family text-sm ${table.hide_quantity ? "text-gray-500" : ""}`}>Quantity</span>
              {!isMobilePreview && !isReadOnly &&
                <div
                  className="btn btn-clear text-gray-400 text-sm px-3 py-0"
                  onClick={onTableQuantityHideToggle}
                >
                  <PhIcon name={table.hide_quantity ? "eyeSlash" : "eye"} size={16} />
                </div>
              }
            </div>
          </TableCell>
        }
        <TableCell align="center" colSpan={1} valign="middle" sx={isMobilePreview ? { padding: '5px' } : { padding: '5px', paddingTop: "25px", border: 'unset', minWidth: '100px' }}>
          <p className={`header-font-family text-sm`}>Subtotal</p>
        </TableCell>
        {table.hide_value && (isReadOnly || isLandingPage)
          ? null
          :
          <TableCell align="center" colSpan={1} valign="middle" sx={isMobilePreview ? { padding: '5px' } : { padding: '5px', paddingTop: "25px", border: 'unset', minWidth: '100px' }}>
            <div className="flex items-center justify-center">
              <p className={`header-font-family text-sm ${table.hide_value ? "text-gray-500" : ""}`}>Value</p>
              {!isMobilePreview && !isReadOnly &&
                <div
                  className="btn btn-clear text-gray-400 text-sm px-3 py-0"
                  onClick={onTableValueHideToggle}
                >
                  <PhIcon name={table.hide_value ? "eyeSlash" : "eye"} size={16} />
                </div>
              }
            </div>
          </TableCell>
        }
      </TableRow >
    </>
  )
}

export default Description