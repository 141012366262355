import React from 'react';
import { withRef } from '@udecode/cn';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { toggleList, unwrapList, getListRoot } from '@udecode/plate-list';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { ParagraphPlugin, PlateEditor, PlateElement, useReadOnly } from '@udecode/plate/react';
import { AssessmentPlugin, createDefaultAssessmentElement } from '../../plate-config/Plugins/Assessment/Assessment.plugin';
import { createDefaultPricingElement, PricingPlugin } from '../../plate-config/Plugins/Pricing/Pricing.plugin';
import { TElement } from '@udecode/plate';

import {
  InlineCombobox, InlineComboboxContent, InlineComboboxEmpty,
  InlineComboboxInput, InlineComboboxItem,
} from '../InlineCombobox/InlineCombobox';
import { createDefaultVideoElement, VideoPlugin } from '../../plate-config/Plugins/Video/Video.plugin';
import { createDefaultEmbedElement, EmbedPlugin } from '../../plate-config/Plugins/Embed/Embed.plugin';
import { ButtonGroupPlugin, createDefaultButtonGroupElement } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';
import { createDefaultImageElement, ImagePlugin } from '../../plate-config/Plugins/Image/Image.plugin';
import { AiTextBlockPlugin, createDefaultAiTextBlockElement } from '../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { createDefaultEmojiElement, EmojiInputPlugin } from '../../plate-config/Plugins/EmojiCommand/EmojiCommand.plugin';
import { createDefaultTemplateVariableInputElement, TemplateVariableInputPlugin } from '../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin';
import { ColumnGroupPlugin, createDefaultColumnGroupElement } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { createDefaultHrElement, HorizontalRulePlugin } from '../../plate-config/Plugins/HrLine/HrLine.plugin';
import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { SectionPlugin } from '../../plate-config/Plugins/Section/Section.plugin';
import PhIcon, { PhIconName } from '../../shared/Icon/PhIcon';
import { createDefaultTestimonialGroupElement, TestimonialGroupPlugin } from '../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin';
import { CardGroupPlugin, createDefaultCardGroupElement } from '../../plate-config/Plugins/CardGroup/CardGroup.plugin';
import { createDefaultSubscribeToNewsletterElement, SubscribeToNewsletterPlugin } from '../../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin';

interface SlashCommandRule {
  icon: PhIconName;
  onSelect: (editor: PlateEditor) => void;
  value: string;
  keywords?: string[];
  type: string;
}

export const SlashInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const { children, editor, element } = props;

    const isReadOnly = useReadOnly()
    const isTemplate = useAppSelector(state => state.page_navigation.template)
    const calc_variables = useAppSelector(state => state.page_calc_variables)
    const pluginsStatus = useAppSelector(state => state.page_plugins_status)

    const defaultSliderVar = calc_variables.find(variable => variable.slider_default)!
    if (isReadOnly) return <>{children}</>

    const slashNode = editor.api.node({ at: [], match: (n: any) => n.id === props.element.id })!
    const slashNodePath = slashNode[1]
    const parent = editor.api.parent(slashNode[1])
    const highLevelParent = editor.api.parent(parent![1])
    const isHighLevelParentColumn = highLevelParent ? highLevelParent[0].type === ColumnPlugin.key : false
    const isHighLevelParentSection = highLevelParent ? highLevelParent[0].type === SectionPlugin.key : false
    const isFirstColumnChild = isHighLevelParentColumn ? highLevelParent![0].children[0].id === parent![0].id : false
    const isFirstSectionChild = isHighLevelParentSection ? highLevelParent![0].children[0].id === parent![0].id : false
    const isListType = getListRoot(editor, parent![1])
    const firstLevelSectionChild = editor.children[slashNodePath[0]]?.children[slashNodePath[1]] as TElement
    const secondLevelSectionChild = firstLevelSectionChild?.children ? firstLevelSectionChild.children[slashNodePath[2]] as TElement : null
    const thirdLevelSectionChild = secondLevelSectionChild?.children ? secondLevelSectionChild.children[slashNodePath[3]] as TElement : null
    const isColumnDisabled = secondLevelSectionChild && secondLevelSectionChild.type === ColumnPlugin.key ||
      thirdLevelSectionChild && thirdLevelSectionChild.type === ColumnGroupPlugin.key

    const rules: SlashCommandRule[] = [
      {
        icon: 'paragraph',
        keywords: ['p', 'paragraph'],
        onSelect: (editor: PlateEditor) => {
          if (isListType) unwrapList(editor)
          return editor.tf.toggleBlock(ParagraphPlugin.key)
        },
        value: 'Paragraph',
        type: ParagraphPlugin.key
      },
      {
        icon: 'textHOne',
        keywords: ['h1', 'heading 1'],
        onSelect: (editor: PlateEditor) => {
          if (isListType) unwrapList(editor)
          return editor.tf.toggleBlock(HEADING_KEYS.h1)
        },
        value: 'Heading 1',
        type: HEADING_KEYS.h1
      },
      {
        icon: 'textHTwo',
        keywords: ['h2', 'heading 2'],
        onSelect: (editor: PlateEditor) => {
          if (isListType) unwrapList(editor)
          return editor.tf.toggleBlock(HEADING_KEYS.h2)
        },
        value: 'Heading 2',
        type: HEADING_KEYS.h2
      },
      {
        icon: 'quotes',
        keywords: ['blockquote', 'quote'],
        onSelect: (editor: PlateEditor) => {
          if (isListType) unwrapList(editor)
          return editor.tf.toggleBlock(BlockquotePlugin.key)
        },
        value: 'Blockquote',
        type: BlockquotePlugin.key,
      },
      {
        icon: 'listBullets',
        keywords: ['ul', 'unordered list'],
        onSelect: (editor: PlateEditor) => {
          if (parent) {
            const isListType = getListRoot(editor, parent![1])
            if (isListType && isListType[0].type === NumberedListPlugin.key) {
              return toggleList(editor, { type: BulletedListPlugin.key })
            }
            if (isListType && isListType[0].type === BulletedListPlugin.key) {
              return unwrapList(editor)
            }
            return editor.tf.toggleBlock(BulletedListPlugin.key)
          }
        },
        value: 'Bulleted list',
        type: BulletedListPlugin.key,
      },
      {
        icon: 'listNumbers',
        keywords: ['ol', 'ordered list'],
        onSelect: (editor: PlateEditor) => {
          if (parent) {
            const isListType = getListRoot(editor, parent![1])
            if (isListType && isListType[0].type === BulletedListPlugin.key) {
              return toggleList(editor, { type: NumberedListPlugin.key })
            }
            if (isListType && isListType[0].type === NumberedListPlugin.key) {
              return unwrapList(editor)
            }
            return editor.tf.toggleBlock(NumberedListPlugin.key)
          }
        },
        value: 'Numbered list',
        type: NumberedListPlugin.key,
      },
      {
        icon: 'smiley',
        keywords: ['emoji'],
        onSelect: (editor: PlateEditor) => editor.tf.insertNodes(createDefaultEmojiElement(), { at: slashNode[1] }),
        value: 'Emoji',
        type: EmojiInputPlugin.key
      },
      {
        icon: 'minus',
        keywords: ['hr', 'horizontal line'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultHrElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultHrElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultHrElement()], { select: true })
        },
        value: 'Horizontal line',
        type: HorizontalRulePlugin.key,
      },
      {
        icon: 'cards',
        keywords: ['cards', 'card group', 'group'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultCardGroupElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultCardGroupElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultCardGroupElement()], { select: true })
        },
        value: 'Cards',
        type: CardGroupPlugin.key
      },
      {
        icon: 'testimonials',
        keywords: ['testimonials', 'testimonial group', 'group'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultTestimonialGroupElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultTestimonialGroupElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultTestimonialGroupElement()], { select: true })
        },
        value: 'Testimonials',
        type: TestimonialGroupPlugin.key
      },
      ...(isColumnDisabled ? [] : [{
        icon: 'twoColumns',
        keywords: ['column'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultColumnGroupElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultColumnGroupElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultColumnGroupElement()], { select: true })
        },
        value: 'Column Group',
        type: ColumnGroupPlugin.key
      }] as any),
      {
        icon: 'videoCamera',
        keywords: ['video'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultVideoElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultVideoElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultVideoElement()], { select: true })
        },
        value: 'Video',
        type: VideoPlugin.key,
      },
      {
        icon: 'code',
        keywords: ['embed'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultEmbedElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultEmbedElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultEmbedElement()], { select: true })
        },
        value: 'Embed',
        type: EmbedPlugin.key
      },
      {
        icon: 'arrowSquareOut',
        keywords: ['btn', 'button'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultButtonGroupElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultButtonGroupElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultButtonGroupElement()], { select: true })
        },
        value: 'Button',
        type: ButtonGroupPlugin.key,
      },
      {
        icon: 'newspaper',
        keywords: ['subscribe to newsletter', 'subscribe'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultSubscribeToNewsletterElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultSubscribeToNewsletterElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultSubscribeToNewsletterElement()], { select: true })
        },
        value: 'Subscribe Form',
        type: SubscribeToNewsletterPlugin.key
      },
      {
        icon: 'image',
        keywords: ['img', 'image'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultImageElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultImageElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultImageElement()], { select: true })
        },
        value: 'Image',
        type: ImagePlugin.key,
      },
      ...(isTemplate ? [{
        icon: 'magicWand',
        keywords: ['ai', 'text', 'block', 'aitextblock'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultAiTextBlockElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultAiTextBlockElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultAiTextBlockElement()], { select: true })
        },
        value: 'AI Text Block',
        type: AiTextBlockPlugin.key
      }] : []),
      ...(isTemplate ? [{
        icon: 'pencilRuler',
        keywords: ['variable', 'template variable', 'template'],
        onSelect: (editor: PlateEditor) => editor.tf.insertNodes(createDefaultTemplateVariableInputElement(), { at: slashNode[1] }),
        value: 'Template Variable',
        type: TemplateVariableInputPlugin.key
      }] : []),
      {
        icon: 'pencilRuler',
        keywords: ['calc', 'value', 'assessment'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultAssessmentElement(defaultSliderVar.id)], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultAssessmentElement(defaultSliderVar.id), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultAssessmentElement(defaultSliderVar.id)], { select: true })
        },
        value: 'Value Calculator',
        type: AssessmentPlugin.key,
      },
      {
        icon: 'pencilRuler',
        keywords: ['pricing', 'table'],
        onSelect: (editor: PlateEditor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return editor.tf.insertNodes([createDefaultPricingElement()], { at: path, select: true })
          }
          if (editor.api.isEmpty(editor.selection, { block: true })) {
            parent && !isFirstColumnChild && !isFirstSectionChild && editor.tf.removeNodes({ at: parent[1] })
            return editor.tf.insertNodes(createDefaultPricingElement(), { at: parent![1], select: true })
          }
          editor.tf.insertNodes([createDefaultPricingElement()], { select: true })
        },
        value: 'Pricing table',
        type: PricingPlugin.key
      },
    ].filter(pluginRule => {
      const status = pluginsStatus.pluginsStatus.find(pluginStatus => pluginStatus.type === pluginRule.type)
      return status ? status.enabled : false
    })

    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        ref={ref}
        data-id={element.id}
        {...props}
        className={`rounded text-sm p-1 bg-gray-100 ${className}`}
        style={{ fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit' }}
      >
        <InlineCombobox element={element} trigger="/">
          <InlineComboboxInput placeholder='Filter...' />
          <InlineComboboxContent className="max-h-80 min-w-[200px] border border-white/20 shadow-md rounded-xl p-3">
            <InlineComboboxEmpty>
              No matching commands found
            </InlineComboboxEmpty>
            {rules.map(({ icon, keywords, onSelect, value }) => (
              <InlineComboboxItem
                key={value}
                keywords={keywords}
                onClick={() => onSelect(editor)}
                className='rounded-lg text-sm brand-text p-2'
                value={value}
              >
                <PhIcon name={icon} className="mr-[6]" size={16} />
                {value}
              </InlineComboboxItem>
            ))}
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);
