import { createPlatePlugin } from '@udecode/plate/react';
import { TElement } from '@udecode/plate';

import { ColumnPlugin, createColumnWithImageElement, createDefaultColumnElement } from './Column.plugin';

export const ColumnGroupPlugin = createPlatePlugin({
  key: 'column_group',
  node: {
    type: 'column_group',
    isElement: true,
  },
}).overrideEditor(({ editor, tf: { normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // Children control - only columns allowed
        if (node.type === ColumnGroupPlugin.key) {
          const columnGroup = node as PlateColumnGroupElement;
          columnGroup.children.map((child) => {
            if (child.type !== ColumnPlugin.key) {
              editor.tf.removeNodes({ at: [], match: (n: any) => n.id === child.id });
            }
          });
        }
        // Style normalization - ensure that column group style is correct for the amount of children
        if (node.type === ColumnGroupPlugin.key) {
          const columnGroup = node as PlateColumnGroupElement;
          if (columnGroup.children.length === 2) {
            // Should respect only structure like 'width/width' and not === to layoutConfigStyleTypes
            const isStyleRight = /^\d+\/\d+$/.test(columnGroup.style as string);
            if (!isStyleRight) {
              editor.tf.setNodes({ style: '50/50' } as Partial<Node>, { at: [], match: (n: any) => n.id === columnGroup.id })
            }
          }
          if (columnGroup.children.length === 3) {
            // Should respect only structure like 'width/width/width' and not === to layoutConfigStyleTypes
            const isStyleRight = /^\d+\/\d+\/\d+$/.test(columnGroup.style as string);
            if (!isStyleRight) {
              editor.tf.setNodes({ style: '33/33/33' } as Partial<Node>, { at: [], match: (n: any) => n.id === columnGroup.id })
            }
          }
          if (columnGroup.children.length === 4) {
            // Should respect only structure like 'width/width/width/width' and not === to layoutConfigStyleTypes
            const isStyleRight = /^\d+\/\d+\/\d+\/\d+$/.test(columnGroup.style as string);
            if (!isStyleRight) {
              editor.tf.setNodes({ style: '25/25/25/25' } as Partial<Node>, { at: [], match: (n: any) => n.id === columnGroup.id })
            }
          }
        }
        // Children amount normalization - ensure that column group always has at least 2 children
        if (node.type === ColumnGroupPlugin.key) {
          const columnGroup = node as PlateColumnGroupElement;
          if (columnGroup.children.length < 2) {
            editor.tf.insertNodes(createDefaultColumnElement(), { at: [...path, +columnGroup.children.length] });
          }
        }
        // Children amount normalization - ensure that column group always has at most 4 children
        if (node.type === ColumnGroupPlugin.key) {
          const columnGroup = node as PlateColumnGroupElement;
          if (columnGroup.children.length > 4) {
            editor.tf.removeNodes({ at: [...path, 4] });
          }
        }
        normalizeNode([node, path])
      },
    }
  }
})

export interface PlateColumnGroupElement extends TElement {
  id: string
  type: typeof ColumnGroupPlugin.key,
  style: string | null,
  // Service fields
  selected: boolean,
}

export const createDefaultColumnGroupElement = (): TElement => ({
  type: ColumnGroupPlugin.key,
  children: [createDefaultColumnElement(), createColumnWithImageElement()],
  style: '50/50'
})
export const createTwoColumnGroupElement = (): TElement => ({
  type: ColumnGroupPlugin.key,
  children: [createDefaultColumnElement(), createDefaultColumnElement()],
  style: '50/50'
})

export const createThreeColumnsElement = (): TElement => ({
  type: ColumnGroupPlugin.key,
  children: [createDefaultColumnElement(), createDefaultColumnElement(), createDefaultColumnElement()],
  style: '33/33/33'
})

export const createFourColumnsElement = (): TElement => ({
  type: ColumnGroupPlugin.key,
  children: [createDefaultColumnElement(), createDefaultColumnElement(), createDefaultColumnElement(), createDefaultColumnElement()],
  style: '25/25/25/25'
})