import React from "react";

import { useAppSelector } from "../../../store/hooks/redux-hooks";
import PhIcon from "../../../shared/Icon/PhIcon";
import Tooltip from "../../../shared/ToolTip/Tooltip";


const TemplateDetails: React.FC = () => {
  const details_path = useAppSelector((state) => state.page_navigation.details_path);

  const openUrl = () => details_path && window.location.replace(details_path);

  return (
    <Tooltip content={'Settings'} side={'bottom'} sideOffset={5} container="nav_tooltip_anchor" >
      <button onClick={openUrl} className="brand_btn-secondary w-11 min-w-11 h-11" style={{ padding: 0 }} >
        <PhIcon name="gear" size={18} className="text-gray-900" />
      </button>
    </Tooltip>
  );
}

export default TemplateDetails;
