import React, { useState } from 'react';
import { PortalBody, useComposedRef, withRef } from '@udecode/cn';
import {
  flip,
  FloatingToolbarState,
  offset,
  useFloatingToolbar,
  useFloatingToolbarState,
} from '@udecode/plate-floating';
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import { BoldPlugin, ItalicPlugin, StrikethroughPlugin, UnderlinePlugin } from '@udecode/plate-basic-marks/react';
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef, useReadOnly } from '@udecode/plate/react';

import Align from './Align/Align';
import TurnIntoDefaultNodes from './TurnInto/TurnIntoDefaultNodes';
import FontSize from './FontSize/FontSize';
import TurnIntoUl from './TurnInto/TurnIntoUl';
import TurnIntoOl from './TurnInto/TurnIntoOl';
import BasicMarks from './BasicMarks/BasicMarks';
import Colors from './Colors/Colors';
import Link from './Link/Link';

export const FloatingToolbar = withRef<
  typeof ToolbarPrimitive.Root,
  {
    portalElement?: Element
    state?: FloatingToolbarState;
  }
>(({ state, portalElement, children, ...props }, componentRef) => {
  const editor = useEditorRef();
  const readOnly = useReadOnly()
  const floatingToolbarState = useFloatingToolbarState({
    editorId: editor.id,
    focusedEditorId: editor.id,
    ...state,
    floatingOptions: {
      placement: 'top',
      middleware: [
        offset(12),
        flip({
          padding: 12,
          fallbackPlacements: [
            'top-start',
            'top-end',
            'bottom-start',
            'bottom-end',
          ],
        }),
      ],
      ...state?.floatingOptions,
    },
  });

  const {
    ref: floatingRef,
    props: rootProps,
    hidden,
  } = useFloatingToolbar(floatingToolbarState);

  const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef);
  const [value, setValue] = useState<string>("");

  if (hidden || readOnly) return null;

  return (
    <PortalBody element={portalElement}>
      <div
        ref={ref}
        className="absolute z-50 whitespace-nowrap opacity-100"
        {...rootProps}
        {...props}
        id='floating-toolbar'
      >
        <MenuBar.Root
          value={value}
          onValueChange={setValue}
          className="element_topbar_relative_wrapper"
        >
          <TurnIntoDefaultNodes value={value} />
          <FontSize />
          <MenuBar.Separator className="element_topbar_separator" />
          <Colors value={value} />
          <Align value={value} />
          <MenuBar.Separator className="element_topbar_separator" />
          <MenuBar.Menu value="float_ul">
            <TurnIntoUl />
          </MenuBar.Menu>
          <MenuBar.Menu value="float_ol">
            <TurnIntoOl />
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
          <MenuBar.Menu value="float_bold">
            <BasicMarks mark={BoldPlugin.key} iconName='textBolder' tooltip="Bold" containerId="floating-toolbar" />
          </MenuBar.Menu>
          <MenuBar.Menu value="float_italick">
            <BasicMarks mark={ItalicPlugin.key} iconName='textItalic' tooltip='Italic' containerId='floating-toolbar' />
          </MenuBar.Menu>
          <MenuBar.Menu value="float_underline">
            <BasicMarks mark={UnderlinePlugin.key} iconName='textUnderline' tooltip='Underline' containerId='floating-toolbar' />
          </MenuBar.Menu>
          <MenuBar.Menu value="float_strikethrough">
            <BasicMarks mark={StrikethroughPlugin.key} iconName='textStrikethrough' tooltip='Strikethrough' containerId='floating-toolbar' />
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
          <MenuBar.Menu value="float_link">
            <Link />
          </MenuBar.Menu>
        </MenuBar.Root>
      </div>
    </PortalBody>
  );
});