import React, { useState } from 'react';
import { withRef } from '@udecode/cn';
import { PlateElement, useReadOnly, useSelected } from '@udecode/plate/react';

import { useAppSelector } from '../../store/hooks/redux-hooks';
import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { PlateTestimonialGroupElement } from '../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin';
import SliderWrapper from './SliderWrapper/SliderWrapper';
import Menubar from './Menubar/Menubar';
import LibraryDialog from './LibraryDialog/LibraryDialog';


const TestimonialGroupElement = withRef<typeof PlateElement>(
  ({ children, editor, element, className, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const block = element as PlateTestimonialGroupElement
    const isReadOnly = useReadOnly();
    const selected = useSelected()
    const [libOpen, setLibOpen] = useState(false)

    const nodePath = editor.api.node({ at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const testimonialsToShow = block.children.filter(child => {
      const hasUserName = !!child.user_name && child.user_name.trim().length > 0;
      const hasUserPosition = !!child.user_position && child.user_position.trim().length > 0;
      const hasContent = !!child.content && child.content.trim().length > 0;
      if (!hasUserName || !hasUserPosition || !hasContent) {
        return false
      }
      return true
    })

    if (isReadOnly && testimonialsToShow.length === 0) return null

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={block}
        className={`
        rounded-lg transition-all duration-300 ease-in-out p-2 relative
        ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          } 
        ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        ${className}`
        }
        data-plate-selectable="true"
        data-id={block.id}
        {...props}
      >
        {block.template === 'slider'
          ?
          <SliderWrapper block={block}> {children} </SliderWrapper>
          :
          <div className='flex flex-wrap items-center justify-center gap-5'>
            {children}
          </div>
        }
        <Menubar showTrigger={isReadOnly ? false : selected} block={block} onLibModalOpen={setLibOpen.bind(setLibOpen, true)} />
        <LibraryDialog modalOpen={libOpen} onModalClose={setLibOpen.bind(setLibOpen, false)} block={block} />
      </PlateElement>
    )
  })

export default TestimonialGroupElement
