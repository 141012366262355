import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"

import { PlateSectionElement, SectionPlugin } from "../Section/Section.plugin"

export const RecordingPlugin = createPlatePlugin({
  key: 'recording',
  node: {
    type: 'recording',
    isVoid: true,
    isElement: true,
  },
}).overrideEditor(({ editor, tf: { apply, normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // Fixed sections should not have any children except recordings
        if (node.type === SectionPlugin.key) {
          const section = node as PlateSectionElement;
          const isFixedSection = section.fixed_kind === "recording"
          if (isFixedSection) {
            section.children.forEach((child) => {
              if (child?.type !== RecordingPlugin.key) {
                editor.tf.removeNodes({ at: [...path, section.children.indexOf(child)] });
              }
            })
          }
        }
        normalizeNode([node, path])
      },
      apply(operation) {
        // We need to be sure that fixed section can't be deleted
        if (operation.type === "remove_node" && operation.node.type === SectionPlugin.key) {
          const isFixedSection = operation.node.fixed_kind === "recording"
          if (isFixedSection) {
            return
          }
        }
        // Recording is not deletable
        if (operation.type === 'remove_node' && operation.node.type === RecordingPlugin.key) {
          return
        }
        apply(operation)
      },
    }
  }
})

export interface RecordingConversation {
  speaker: {
    id: string
    number: number
    name: string
  }
  display_start_time: string
  uterrances: {
    id: string
    position: number
    start_time: string
    end_time: string
    text: string
  }[]
}


export interface PlateRecordingElement extends TElement {
  id: string
  type: typeof RecordingPlugin.key
  recording_file: string
  recording_kind: string
  speakers: {
    id: string
    number: number
    name: string
    role: string
  }[]
  conversation: RecordingConversation[]
}