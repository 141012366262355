import React, { useEffect, useState } from "react";
import debounce from 'lodash/debounce';
import * as Icons from 'react-icons/fa6';
import { useEditorRef, useReadOnly } from "@udecode/plate/react";
import { NodeApi } from "@udecode/plate";

import { PlateCardElement } from "../../../plate-config/Plugins/CardGroup/Card.plugin";
import { PlateCardGroupElement } from "../../../plate-config/Plugins/CardGroup/CardGroup.plugin";
import PhIcon from "../../../shared/Icon/PhIcon";
import { Popover, PopoverContent, PopoverPortal, PopoverAnchor } from "../../../shared/Popover/Popover";
import DecoratedInput from "../../../shared/Inputs/DecoratedInput";

export const iconPickerOptions = Object.keys(Icons).map((iconName) => ({
  value: iconName,
  IconComponent: Icons[iconName],
}));

type Props = {
  block: PlateCardElement
}

const IconVisualType: React.FC<Props> = ({ block }) => {
  const editor = useEditorRef()
  const [showIconPicker, setShowIconPicker] = useState(false)
  const [icons, setIcons] = useState(iconPickerOptions)
  const [search, setSearch] = useState('')
  const isReadOnly = useReadOnly()

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const filteredIcons = iconPickerOptions.filter(({ value }) =>
        value.toLowerCase().includes(search.toLowerCase())
      );
      setIcons(filteredIcons);
    }, 200);
    if (search === '') {
      setIcons(iconPickerOptions);
    } else {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [search]);

  const elementNode = editor.api.node({ at: [], match: { id: block.id } })!
  const parentNode = NodeApi.parent(editor, elementNode[1]) as PlateCardGroupElement;
  const parentAlign = parentNode.align || 'left'
  const image_align = parentAlign === 'center' ? 'justify-center' : parentAlign === 'right' ? 'justify-end' : 'justify-start'
  const iconExist = block.icon ? iconPickerOptions.find(icon => icon.value === block.icon) : false
  const SelectedIcon = Icons[block.icon!]
  const textColor = parentNode.text_color ? parentNode.text_color : 'text-black'

  const preventDefault = (e: Event) => e.preventDefault();
  const onSearchChange = (value: string) => setSearch(value)
  const onIconClick = (icon: string) => {
    editor.tf.setNodes(
      { icon } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
    setShowIconPicker(false)
  }


  return (
    <Popover open={isReadOnly ? false : showIconPicker} onOpenChange={setShowIconPicker}  >
      <PopoverAnchor>
        <div className={`${image_align} mb-5 w-full flex relative`}>
          <div
            className={`w-[64px] h-[64px] flex items-center gap-4 flex-col 
              ${iconExist ? "bg-transparent" : "bg-gray-100"} 
              rounded-full cursor-pointer justify-center`}
            onClick={isReadOnly ? undefined : () => setShowIconPicker(true)}
          >
            {
              isReadOnly
                ?
                iconExist
                  ? <SelectedIcon className={`${textColor} w-[64px] h-[64px]`} />
                  : null
                :
                iconExist
                  ? <SelectedIcon className={`${textColor} w-[64px] h-[64px]`} />
                  : <PhIcon name='fileMagnifyingGlass' size={30} className="brand-subtext" />
            }
          </div>
        </div>
      </PopoverAnchor>
      <PopoverPortal>
        <PopoverContent
          onOpenAutoFocus={preventDefault}
          onCloseAutoFocus={preventDefault}
          side='bottom'
          align='center'
        >
          <div className="w-[353px] h-[420px] flex flex-col items-center gap-2  rounded-lg border border-black/20 shadow-md bg-white p-2" >
            <DecoratedInput
              contentBefore={<PhIcon name="magnifyingGlass" size={16} className="text-gray-700 mr-2" />}
              placeholder="Search..."
              value={search}
              onChange={onSearchChange}
              inputClassName="text-base"
            />
            <div className="flex items-center flex-wrap justify-start gap-2 overflow-scroll w-full ">
              {icons.map(({ value, IconComponent }, i) => (
                <div
                  onClick={onIconClick.bind(onIconClick, value)}
                  key={i}
                  className="w-10 h-10 p-2 hover:bg-[#2C272708] rounded-lg flex items-center justify-center"
                >
                  <IconComponent className="w-8 h-8 brand-text" />
                </div>
              ))}
              {icons.length === 0 && <p className="brand-text text-black text-sm" >No results found.</p>}
            </div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}

export default IconVisualType;