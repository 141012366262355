import React, { useState } from "react"
import { useEditorRef } from "@udecode/plate/react"

import HeadCalcValue from "./HeadCalcValue/HeadCalcValue"
import CalcSlider from "./CalcSlider/CalcSlider"
import PriceCalc from "./PriceCalc/PriceCalc"
import ValueCalc from "./ValueCalc/ValueCalc"
import EditableField from "../../../shared/EditableField/EditableField"
import { PlateAssessmentElement } from "../../../plate-config/Plugins/Assessment/Assessment.plugin"
import { useAppSelector } from "../../../store/hooks/redux-hooks"
import { StoreCalcVariable } from "../../../store/reducers/page_calc_variables/page_calc_variables"
import { textColorFromColorClass } from "../../../utils/color.util"


type Props = {
  element: PlateAssessmentElement,
  trackAssessmentExplore: () => void,
  trackAssessmentChanges: (
    field: string, variable_id: string | null, value: string | number,
    previous_value: string | number, sliderValue: number | null, variables: StoreCalcVariable[] | null
  ) => void,
  isEditorReadOnly: boolean,
  calc_variables: StoreCalcVariable[]
}

const EditableScreen = ({ element, trackAssessmentExplore, trackAssessmentChanges, isEditorReadOnly, calc_variables }: Props) => {
  const editor = useEditorRef()
  const editorContainerWidth = useAppSelector(state => state.page_addendums.editorContainerWidth)
  const [headline, setHeadline] = useState(element.assessment_detail_attributes.headline || '')
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)

  const textColor = textColorFromColorClass(element.primary_color, colors)
  const isMobilePreviewActive = editorContainerWidth === 'mobile'

  const onHeadlineChange = (headline: string) => {
    setHeadline(headline)
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          headline,
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onStatPrefixChange = (stat_prefix: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          stat_prefix,
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onStatSuffixChange = (stat_suffix: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          stat_suffix,
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onStatFormulaChange = (calculation: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          stat_formula_attributes: { ...element.assessment_detail_attributes.stat_formula_attributes, calculation }
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onSliderVariableIdChange = (slider_variable_id: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          slider_variable_id
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onSliderColorChange = (slider_color: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          slider_color
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onDisplayPriceChange = (hide_price: boolean) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          hide_price: !hide_price
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onPriceFormulaChange = (calculation: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          price_formula_attributes: { ...element.assessment_detail_attributes.price_formula_attributes, calculation }
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onDisplayValueChange = (hide_value: boolean) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          hide_value: !hide_value
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onValueFormulaChange = (calculation: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          value_formula_attributes: { ...element.assessment_detail_attributes.value_formula_attributes, calculation }
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const onValueFormulaNotesChange = (notes: string) =>
    editor.tf.setNodes(
      {
        assessment_detail_attributes: {
          ...element.assessment_detail_attributes,
          value_formula_attributes: { ...element.assessment_detail_attributes.value_formula_attributes, notes }
        }
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  return (
    <div className="flex flex-col gap-12">
      <EditableField
        placeholder={isEditorReadOnly ? "" : "Enter your headline here"}
        value={headline}
        onChange={onHeadlineChange}
        wrapperClassName="w-[100%]"
        inputClassName={`text-2xl mt-6 inline-content-editable-input   opacity-70 paragraph-font-family text-${textColor}`}
        styleOptions={{ textAlign: 'center' }}
        disabled={isEditorReadOnly}
      />
      <HeadCalcValue
        element={element}
        calc_variables={calc_variables}
        isEditorReadOnly={isEditorReadOnly}
        onStatPrefixChange={onStatPrefixChange}
        onStatSuffixChange={onStatSuffixChange}
        onStatFormulaChange={onStatFormulaChange}
        textColor={textColor}
      />
      <CalcSlider
        element={element}
        onSliderVariableIdChange={onSliderVariableIdChange}
        onSliderColorChange={onSliderColorChange}
        calc_variables={calc_variables}
        trackAssessmentChanges={trackAssessmentChanges}
        isEditorReadOnly={isEditorReadOnly}
      />
      {(!element.assessment_detail_attributes.hide_price || !element.assessment_detail_attributes.hide_value) &&
        <div className={`flex flex-wrap justify-center items-center ${isMobilePreviewActive ? 'gap-8' : 'gap-16'} w-full mx-auto`}>
          {!element.assessment_detail_attributes.hide_price &&
            <PriceCalc
              element={element}
              onDisplayPriceChange={onDisplayPriceChange}
              onPriceFormulaChange={onPriceFormulaChange}
              calc_variables={calc_variables}
              isEditorReadOnly={isEditorReadOnly}
              textColor={textColor}
            />
          }
          {!element.assessment_detail_attributes.hide_value &&
            <ValueCalc
              element={element}
              onDisplayValueChange={onDisplayValueChange}
              onValueFormulaChange={onValueFormulaChange}
              onValueFormulaNotesChange={onValueFormulaNotesChange}
              calc_variables={calc_variables}
              trackAssessmentExplore={trackAssessmentExplore}
              trackAssessmentChanges={trackAssessmentChanges}
              isEditorReadOnly={isEditorReadOnly}
              textColor={textColor}
            />
          }
        </div>
      }
    </div>
  )
}

export default EditableScreen