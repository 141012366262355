import React, { useCallback } from 'react';
import { cn, withRef } from '@udecode/cn';
import { TLinkElement } from '@udecode/plate-link';
import { useLink } from '@udecode/plate-link/react';
import ahoy from "ahoy.js"
import { PlateElement, useEditorRef, useElement, useReadOnly } from '@udecode/plate/react';

import { useAppSelector } from '../../store/hooks/redux-hooks';

export const LinkElement = withRef<typeof PlateElement>(
  ({ className, children, ...props }, ref) => {
    const element = useElement<TLinkElement>();
    const { props: linkProps } = useLink({ element });
    const editor = useEditorRef()
    const isReadOnly = useReadOnly();
    const analyticsEnabled = useAppSelector(state => state.page_side_effects.analyticsEnabled)
    const account_id = useAppSelector(state => state.page_addendums.account_id)
    const pageId = useAppSelector(state => state.page_navigation.current_page_id)


    const node = editor.api.node({ at: [], match: { id: element.id } })!
    const parentNode = editor.api.parent(node[1])
    const section = editor.children[node[1][0]]

    const trackLinkClick = useCallback(() => {
      if (!analyticsEnabled) return
      return ahoy.track("page action", {
        page_id: pageId,
        section_id: section.id,
        block_id: parentNode ? parentNode[0].id : null,
        type: "link_click",
        account_id,
        href: element.url,
        link_text: element.children[0].text,
      });
    }, [pageId, section, account_id, element, analyticsEnabled]);

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isReadOnly) {
        trackLinkClick()
      }
    }

    return (
      <PlateElement
        ref={ref}
        asChild
        data-id={element.id}
        className={cn(
          'font-medium text-primary underline decoration-primary underline-offset-4',
          className
        )}
        {...(linkProps as any)}
        {...props}
        onClick={onClick}
        target="_blank"
      >
        <a>{children}</a>
      </PlateElement>
    );
  }
);
