import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"

import { textNodeTypes } from '../../../utils/plate.util';
import { SectionPlugin } from '../Section/Section.plugin';
import { LinkPlugin } from "@udecode/plate-link/react";

export const TextSizeLeafPlugin = createPlatePlugin({
  key: 'font_size',
  node: {
    isLeaf: true,
  },
}).overrideEditor(({ editor, tf: { normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // If section node has font_size property we need to add font_size marks to all text nodes inside the section
        if (textNodeTypes.includes(node.type as string)) {
          const textNode = node as TElement;
          const section = editor.api.node({ at: [path[0]], match: { type: SectionPlugin.key } });
          if (section) {
            if (section[0].font_size) {
              textNode.children.forEach((child, index) => {
                // Link node can be represented as plugin and as text node, so we need to check both
                // to avoid overriding link font size
                if (!!child.type && child.type === LinkPlugin.key) {
                  const linkElement = child as TElement;
                  const linkChild = linkElement.children[0] || false;
                  const linkFontSize = linkChild ? linkChild?.font_size : false
                  if (linkFontSize) {
                    return;
                  }
                }
                // If font_size is already set we don't need to override it
                if (child.font_size) {
                  return;
                }
                // Override font_size to respect section font_size
                editor.tf.setNodes({ font_size: section[0].font_size }, { at: [...path, index], marks: true });
              });
            }
          }
        }
        normalizeNode([node, path])
      },
    }
  }
})