import React, { memo, useEffect, useState } from 'react';
import { styled, keyframes } from '@stitches/react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';


type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: TooltipPrimitive.TooltipContentProps['side'];
  container?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  contentClassName?: string;
  sideOffset?: number;
  withArrow?: boolean;
  triggerClassName?: string;
};

const Tooltip = memo(({
  children, content, side = 'top', container, withArrow = false,
  open, onOpenChange, contentClassName, sideOffset, triggerClassName
}: Props) => {
  const scaleIn = keyframes({
    '0%': { opacity: 0, transform: 'scale(0)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  });
  const StyledContent = styled(TooltipPrimitive.Content, {
    transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
    animation: `${scaleIn} 100ms ease-out`,
  });
  const TooltipArrow = styled(TooltipPrimitive.Arrow, {
    fill: '#e4e5e3',
  });

  const [containerElement, setContainerElement] = useState<HTMLElement | null | undefined>(document.body);

  useEffect(() => {
    if (container) {
      const element = document.getElementById(container);
      setContainerElement(element || document.body);
    }
  }, [container])

  const rootProps = !!onOpenChange ? { onOpenChange } : {}

  if (!content) return <>{children}</>;

  return (
    <TooltipPrimitive.Root
      open={open}
      {...rootProps}
    >
      <TooltipPrimitive.Trigger asChild contentEditable={false} suppressContentEditableWarning className={triggerClassName} >
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal container={containerElement} >
        <StyledContent
          side={side}
          sideOffset={sideOffset}
          align="center"
          className={contentClassName ? contentClassName : "brand_tooltip"}
          contentEditable={false}
          suppressContentEditableWarning
        >
          {content}
          {withArrow && <TooltipArrow />}
        </StyledContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  )
})
export default Tooltip;