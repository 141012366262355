import { v4 as uuidv4 } from 'uuid';
import { createPlatePlugin, ParagraphPlugin } from '@udecode/plate/react';
import { TElement } from '@udecode/plate';

import { createDefaultParagraphElement } from "../DefaultMockups/DefaultMockups";
import { createDefaultImageElement } from '../Image/Image.plugin';
import { dependentBlocks } from '../../../utils/plate.util';

export const ColumnPlugin = createPlatePlugin({
  key: 'column',
  node: {
    type: 'column',
    isElement: true,
  },
}).overrideEditor(({ editor, tf: { apply, normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // Ecxlude dependent blocks
        if (node.type === ColumnPlugin.key) {
          const column = node as PlateColumnElement;
          column.children.map((child) => {
            if (dependentBlocks.includes(child.type as any)) {
              editor.tf.removeNodes({ at: [], match: (n: any) => n.id === child.id });
            }
          })
        }
        normalizeNode([node, path])
      },
      apply(operation) {
        // Restrict merging columns
        if (operation.type === "merge_node" && operation.properties.type === ColumnPlugin.key) {
          return
        }
        // Convert emty {text: ""} node into paragraph. In case of removing last child in column
        if (operation.type == 'insert_node' && !operation.node.type && operation.path.length === 4) {
          if (operation.path[3] === 0) {
            operation = { ...operation, node: { type: ParagraphPlugin.key, children: [{ text: '' }], id: uuidv4() } } as any
          }
        }
        apply(operation)
      },
    }
  }
})



export interface PlateColumnElement extends TElement {
  id: string
  type: typeof ColumnPlugin.key,
  image_source: string | null
  image_url: string | null
  image_external_id: string | null
  bg_color: string | null
  bg_opacity: number | null
  align: string | null
  // Service fields
  menuOpen: boolean
}

export const createDefaultColumnElement = (): TElement => ({
  type: ColumnPlugin.key,
  children: [createDefaultParagraphElement()],
  image_source: null,
  image_url: null,
  image_external_id: null,
  bg_color: null,
  bg_opacity: 50,
  align: 'top'
})

export const createColumnWithImageElement = (): TElement => ({
  type: ColumnPlugin.key,
  children: [createDefaultImageElement()],
  image_source: null,
  image_url: null,
  image_external_id: null,
  bg_color: null,
  bg_opacity: 50,
  align: 'top'
})