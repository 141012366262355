import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import { PlateButtonGroupElement } from "../../../plate-config/Plugins/Button/ButtonGroup.plugin";


type Props = {
  block: PlateButtonGroupElement
}

const ButtonGroupMenubar: React.FC<Props> = ({ block }) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onGroupDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onBtnSizeChange = (button_size: string) => editor.tf.setNodes({ button_size } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onAlignmentChange = (align: string) => editor.tf.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

  const size = block.button_size || 'medium'
  const align = block.align || 'left'
  const alignOptions: any = [
    { key: "left", iconName: "alignLeft" },
    { key: "center", iconName: "alignCenterVertical" },
    { key: "right", iconName: "alignRight" }
  ]
  const sizeOptions: any = [
    { key: "small", iconName: "sizeSmall", value: "Small" },
    { key: "medium", iconName: "sizeMedium", value: "Medium" },
    { key: "large", iconName: "sizeLarge", value: "Large" },
  ]

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className="element_topbar_relative_wrapper"
    >
      <MenuBar.Menu value="btn_align">
        <BlockMenubarTrigger
          isDropdown
          iconBeforeLabel={alignOptions.find(item => item.key === align)?.iconName}
          isOpened={value === 'btn_align'}
          tooltip="Align"
          containerId={block.id}
        />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarOptionList
              data={alignOptions}
              onOptionClick={onAlignmentChange}
              activeKey={align}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Menu value="btn_size">
        <BlockMenubarTrigger
          isDropdown
          label={sizeOptions.find(item => item.key === size)?.value}
          iconBeforeLabel={sizeOptions.find(item => item.key === size)?.iconName}
          isOpened={value === 'btn_size'}
          tooltip="Size"
          containerId={block.id}
        />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarOptionList
              data={sizeOptions}
              onOptionClick={onBtnSizeChange}
              activeKey={size}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="btn_group_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onGroupDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default ButtonGroupMenubar;