import React from "react";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Provider as StoreProvider } from 'react-redux'
import { PlateController } from "@udecode/plate/react";

import { store } from "../store/store";
import GlobalStylesLoader from "../HOC/GlobalStylesLoader/GlobalStylesLoader";

type Props = {
  children: React.ReactNode
}

const GlobalProviders = ({ children }: Props) => {
  return (
    <StoreProvider store={store}>
      <TooltipProvider
        disableHoverableContent
        delayDuration={200}
        skipDelayDuration={0}
      >
        <GlobalStylesLoader>
          {/* This allows us to interact with editor outside of the editor */}
          <PlateController>
            {children}
          </PlateController>
        </GlobalStylesLoader>
      </TooltipProvider>
    </StoreProvider>

  )
}

export default GlobalProviders;