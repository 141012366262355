import React from "react";
import { useEditorRef } from "@udecode/plate/react";

import { PlateSectionElement } from "../../../../plate-config/Plugins/Section/Section.plugin";
import ToolbarSeparator from "../../../../shared/ToolbarControls/ToolbarSeparator";
import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector";
import ToolbarSelector from "../../../../shared/ToolbarControls/ToolbarSelector";
import BlockMenubarInput from "../../../../shared/BlockMenubarControls/BlockMenubarInput";
import BlockMenubarHeader from "../../../../shared/BlockMenubarControls/BlockMenubarHeader";
import BlockMenubarSlider from "../../../../shared/BlockMenubarControls/BlockMenubarSlider";


type Props = {
  onMenuClose: () => void;
  sectionElement: PlateSectionElement
}

const Settings: React.FC<Props> = ({ onMenuClose, sectionElement }) => {
  const editor = useEditorRef()

  const onSectionNameChange = (name: string | null) =>
    editor.tf.setNodes({ name } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })

  const onTintColorChange = (tint_color: string | null) =>
    editor.tf.setNodes(
      { tint_color, tint_opacity: typeof sectionElement.tint_opacity == 'number' ? sectionElement.tint_opacity : 50 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onTintKindChange = (tint_kind: string) =>
    editor.tf.setNodes({ tint_kind } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })

  const onTintOpacityChange = (value: number[]) =>
    editor.tf.setNodes({ tint_opacity: value[0] } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })

  const onBackgroundImageSizeChange = (image_size: string) =>
    editor.tf.setNodes({ image_size } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })

  const onCardColorChange = (card_color: string | null) =>
    editor.tf.setNodes(
      { card_color, card_opacity: typeof sectionElement.card_opacity === 'number' ? sectionElement.card_opacity : 50 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )

  const onCardOpacityChange = (value: number[]) =>
    editor.tf.setNodes({ card_opacity: value[0] } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })

  const onStyleChange = (style: string) =>
    editor.tf.setNodes({ style } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })

  return (
    <section className="flex flex-col gap-4">
      <BlockMenubarHeader label="Section Settings" onClose={onMenuClose} />
      <BlockMenubarInput
        label="Name"
        placeholder="Section name"
        onChange={onSectionNameChange}
        value={sectionElement.name}
        variant="sidebar"
      />
      <ToolbarSeparator variant="horizontal" />
      <ToolbarColorSelector
        label="Background Color"
        color={sectionElement.tint_color}
        onColorChange={onTintColorChange}
        onColorReset={onTintColorChange.bind(onTintColorChange, null)}
        wrapperClassName="w-full"
      />
      {sectionElement.background_image_url &&
        <ToolbarSelector
          activeValue={sectionElement.tint_kind || 'regular'}
          label="Type"
          onValueChange={onTintKindChange}
          data={[{ key: 'regular', value: 'Regular' }, { key: 'blend', value: 'Blend' }]}
        />
      }
      <BlockMenubarSlider
        label="Opacity"
        value={[typeof sectionElement.tint_opacity === 'number' ? sectionElement.tint_opacity : 50]}
        onValueChange={onTintOpacityChange}
      />
      {sectionElement.background_image_url &&
        <ToolbarSelector
          activeValue={sectionElement.image_size || "adjustable"}
          label="Background image size"
          onValueChange={onBackgroundImageSizeChange}
          data={[{ key: "full_screen", value: "Full" }, { key: "adjustable", value: "Responsive" }]}
        />
      }
      <ToolbarSeparator variant="horizontal" />
      <ToolbarColorSelector
        label="Content Background Color"
        color={sectionElement.card_color}
        onColorChange={onCardColorChange}
        onColorReset={onCardColorChange.bind(onCardColorChange, null)}
        wrapperClassName="w-full"
      />
      <BlockMenubarSlider
        label="Opacity"
        value={[typeof sectionElement.card_opacity === 'number' ? sectionElement.card_opacity : 50]}
        onValueChange={onCardOpacityChange}
      />
      <ToolbarSeparator variant="horizontal" />
      <ToolbarSelector
        activeValue={sectionElement.style || 'adjustable'}
        label="Layout Width"
        onValueChange={onStyleChange}
        data={[{ key: "full_width", value: "Full" }, { key: "adjustable", value: "Responsive" }]}
      />
    </section>
  );
}

export default Settings;