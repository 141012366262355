import React from "react"
import { useEditorRef } from "@udecode/plate/react"

import { PlatePricingElement, createDefaultTable } from "../../../../plate-config/Plugins/Pricing/Pricing.plugin"
import PhIcon from "../../../../shared/Icon/PhIcon"

type Props = {
  element: PlatePricingElement,
  tableIndex: number
}

const Footer = ({ element, tableIndex }: Props) => {
  const editor = useEditorRef()

  const onTableAdd = (index: number) => {
    const tables = [...element.pricing_tables_attributes]
    tables.splice(index + 1, 0, createDefaultTable())
    editor.tf.setNodes(
      { pricing_tables_attributes: tables } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <div className="border-b border-gray-300 my-12">
      <div
        className="line-button relative h-4 top-2 w-full hover:bg-blue-400/25 cursor-pointer transition-all duration-300"
        onClick={onTableAdd.bind(onTableAdd, tableIndex)}
      >
        <div
          className="relative z-2 btn btn-white rounded-full w-8 h-8 text-center p-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-1 transition-all duration-300"
        >
          <PhIcon name={"plus"} size={16} className="w-full" />
        </div>
      </div>
    </div>
  )
}

export default Footer
