import React from 'react';
import {
  SpinnerGap, Plus, DotsSixVertical, FacebookLogo, InstagramLogo, XLogo, LinkedinLogo, CaretDown, Check,
  Gear, Share, TextAa, Image, VideoCamera, Columns, RadioButton, Minus, Smiley, LinkBreak, TrashSimple,
  AlignBottom, AlignLeft, AlignRight, AlignTop, TextAlignCenter, TextAlignLeft, TextAlignRight, AlignCenterVertical,
  AlignCenterHorizontal, Square, Circle, Triangle, Star, Link, Warning, SealWarning, ListBullets, ListNumbers, X,
  MagnifyingGlass, Divide, Paragraph, TextHOne, TextHTwo, Quotes, PlusSquare, MagicWand, PencilRuler, ArrowUDownLeft,
  ArrowsClockwise, Laptop, DeviceTabletCamera, DeviceMobileCamera, SlidersHorizontal, CaretUp, Copy, Pencil, PaintBrush,
  ArrowLeft, Lock, LockOpen, Info, DotsThreeOutline, Eye, EyeSlash, CaretLeft, CaretRight, User, Globe,
  ArrowUp, ArrowDown, ArrowRight, FlowArrow, Faders, TextBolder, TextUnderline, TextItalic, TextStrikethrough,
  FilePlus, ChatCircleText, File, BookOpenText, Folder, UploadSimple, Code, List, ArrowSquareOut, NumberSquareTwo,
  NumberSquareThree, NumberSquareFour, NumberSquareOne, FileImage, StackPlus, Cards, FileMagnifyingGlass, Stack,
  PinterestLogo, RedditLogo, SnapchatLogo, TiktokLogo, YoutubeLogo, Newspaper, CheckCircle
} from "@phosphor-icons/react";

import {
  ellipse, parallelogram, sizeSmall, sizeLarge, sizeMedium, youtube, threeColumns, fourColumns,
  loom, vimeo, vidyard, facebook, wistia, twitch, streamable, dailymotion, kaltura, twoColumns,
  linkedin, calendly, pitch, gooleDocs, gooleSLides, googleForms, googleSheets, solidLine,
  hubspot, miro, tiktok, twitter, instagram, dashedLine, sectionTemplate1, sectionTemplate2,
  sectionTemplate3, sectionTemplate4, stars, confetti
} from './CustomIcons';

export type PhIconName =
  // custom
  'youtube' | "loom" | 'vimeo' | 'vidyard' | 'facebook' | 'wistia' | 'twitch' | 'streamable' | 'dailymotion' |
  'kaltura' | 'settingsError' | 'header3ParagraphsColWith3ImagesTemplate' | 'imageColWithHeaderAnd5ParagraphsColTemplate' |
  'linkedin' | 'calendly' | 'pitch' | 'gooleDocs' | 'gooleSLides' | 'googleForms' | 'googleSheets' | 'hubspot' |
  'miro' | 'tiktok' | 'twitter' | 'instagram' | 'parallelogram' | 'ellipse' | 'sizeSmall' | 'sizeMedium' | 'sizeLarge' |
  'headerWith5ParagrapsTemplate' | 'dashedLine' | 'solidLine' | 'twoColumns' | 'threeColumns' | 'fourColumns' |
  'sectionTemplate1' | 'sectionTemplate2' | 'sectionTemplate3' | 'sectionTemplate4' | 'stars' | 'confetti' |
  // phosphor
  'spinnerGap' | 'plus' | 'dotsSixVertical' | 'facebookLogo' | 'instagramLogo' | 'xLogo' | 'linkedinLogo' | 'caretDown' |
  'trash' | 'check' | 'gear' | 'share' | 'textAa' | 'image' | 'videoCamera' | 'microsoftOutlookLogo' | 'columns' | 'radioButton' |
  'minus' | 'smiley' | 'linkBreak' | 'alignBottom' | 'alignLeft' | 'alignRight' | 'alignTop' | 'textAlignCenter' | 'textAlignLeft' |
  'textAlignRight' | 'alignCenterVertical' | 'alignCenterHorizontal' | 'square' | 'circle' | 'triangle' | 'star' | 'link' | 'warning' |
  'sealWarning' | 'listBullets' | 'listNumbers' | 'x' | 'magnifyingGlass' | 'divide' | 'paragraph' | 'textHOne' | 'textHTwo' | 'quotes' |
  'plusSquare' | 'magicWand' | 'pencilRuler' | 'arrowUDownLeft' | 'arrowsClockwise' | 'laptop' | 'tablet' | 'smartPhone' | 'settings' |
  'caretUp' | 'copy' | 'pencil' | 'paintBrush' | 'arrowLeft' | 'lock' | 'lockOpen' | 'info' | 'dotsThreeOutline' | 'listPlus' |
  'eye' | 'eyeSlash' | 'caretLeft' | 'caretRight' | 'user' | 'globe' | 'arrowUp' | 'arrowDown' | 'arrowRight' | 'flowArrow' |
  'faders' | 'textBolder' | 'textUnderline' | 'textItalic' | 'textStrikethrough' | 'filePlus' | 'chatCircleText' | 'file' | 'bookOpenText' |
  'folder' | 'uploadSimple' | 'code' | 'list' | 'arrowSquareOut' | 'numberSquareTwo' | 'numberSquareThree' | 'numberSquareFour' |
  'numberSquareOne' | 'fileImage' | 'stackPlus' | 'cards' | 'fileMagnifyingGlass' | 'stack' | 'testimonials' | 'newspaper' |
  'checkCircle'

const ICONS = {
  // custom
  parallelogram: parallelogram, ellipse: ellipse, sizeSmall: sizeSmall, sizeMedium: sizeMedium, twoColumns: twoColumns,
  sizeLarge: sizeLarge, youtube: youtube, loom: loom, vimeo: vimeo, linkedin: linkedin, calendly: calendly,
  vidyard: vidyard, facebook: facebook, wistia: wistia, twitch: twitch, streamable: streamable, threeColumns: threeColumns,
  dailymotion: dailymotion, kaltura: kaltura, pitch: pitch, gooleDocs: gooleDocs, gooleSLides: gooleSLides,
  googleForms: googleForms, googleSheets: googleSheets, hubspot: hubspot, miro: miro, tiktok: tiktok,
  twitter: twitter, instagram: instagram, dashedLine: dashedLine, solidLine: solidLine, fourColumns: fourColumns,
  sectionTemplate1: sectionTemplate1, sectionTemplate2: sectionTemplate2, sectionTemplate3: sectionTemplate3,
  sectionTemplate4: sectionTemplate4, stars: stars, confetti: confetti,
  // phosphor
  spinnerGap: SpinnerGap, plus: Plus, dotsSixVertical: DotsSixVertical, facebookLogo: FacebookLogo, instagramLogo: InstagramLogo,
  xLogo: XLogo, linkedinLogo: LinkedinLogo, caretDown: CaretDown, trash: TrashSimple, check: Check, gear: Gear, share: Share, textAa: TextAa,
  image: Image, videoCamera: VideoCamera, columns: Columns, radioButton: RadioButton,
  minus: Minus, smiley: Smiley, linkBreak: LinkBreak, alignBottom: AlignBottom, alignLeft: AlignLeft, alignRight: AlignRight,
  alignTop: AlignTop, textAlignCenter: TextAlignCenter, textAlignLeft: TextAlignLeft, alignCenterVertical: AlignCenterVertical,
  alignCenterHorizontal: AlignCenterHorizontal, textAlignRight: TextAlignRight, square: Square, circle: Circle, triangle: Triangle,
  star: Star, link: Link, warning: Warning, sealWarning: SealWarning, listBullets: ListBullets, listNumbers: ListNumbers, x: X,
  magnifyingGlass: MagnifyingGlass, divide: Divide, paragraph: Paragraph, textHOne: TextHOne, textHTwo: TextHTwo, quotes: Quotes,
  plusSquare: PlusSquare, magicWand: MagicWand, pencilRuler: PencilRuler, arrowUDownLeft: ArrowUDownLeft, arrowsClockwise: ArrowsClockwise,
  laptop: Laptop, tablet: DeviceTabletCamera, smartPhone: DeviceMobileCamera, settings: SlidersHorizontal, caretUp: CaretUp, copy: Copy,
  pencil: Pencil, paintBrush: PaintBrush, arrowLeft: ArrowLeft, lock: Lock, lockOpen: LockOpen, info: Info, dotsThreeOutline: DotsThreeOutline,
  eye: Eye, eyeSlash: EyeSlash, caretLeft: CaretLeft, caretRight: CaretRight, user: User, globe: Globe,
  arrowUp: ArrowUp, arrowDown: ArrowDown, arrowRight: ArrowRight, flowArrow: FlowArrow, faders: Faders, textBolder: TextBolder,
  textUnderline: TextUnderline, textItalic: TextItalic, textStrikethrough: TextStrikethrough, filePlus: FilePlus, testimonials: ChatCircleText,
  file: File, bookOpenText: BookOpenText, folder: Folder, uploadSimple: UploadSimple, code: Code, list: List, arrowSquareOut: ArrowSquareOut,
  numberSquareTwo: NumberSquareTwo, numberSquareThree: NumberSquareThree, numberSquareFour: NumberSquareFour, numberSquareOne: NumberSquareOne,
  fileImage: FileImage, stackPlus: StackPlus, cards: Cards, fileMagnifyingGlass: FileMagnifyingGlass, stack: Stack, pinterestLogo: PinterestLogo,
  redditLogo: RedditLogo, snapchatLogo: SnapchatLogo, tiktokLogo: TiktokLogo, youtubeLogo: YoutubeLogo, newspaper: Newspaper, checkCircle: CheckCircle
};

interface IconProps {
  name: PhIconName;
  size?: number | string;
  width?: string;
  height?: string;
  color?: string;
  className?: string;
  strokeWidth?: string | number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  weight?: string;
}

const PhIcon: React.FC<IconProps> = ({
  name, color, size, className, strokeWidth, onMouseEnter, onMouseLeave, width, height, weight
}) => {
  const IconComponent = ICONS[name];
  if (!IconComponent) return null;
  return <IconComponent
    aria-hidden
    className={`pointer-events-none ${className}`}
    size={size}
    color={color}
    strokeWidth={strokeWidth}
    weight={weight || "regular"}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    {...(width ? { width } : {})}
    {...(height ? { height } : {})}
  />;
};

export default PhIcon;
