import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Plate } from '@udecode/plate/react';
import { TElement } from '@udecode/plate';

import { Editor, EditorContainer } from '../plate-components/EditorContainer/editor';
import { useAppSelector, useAppDispatch } from '../store/hooks/redux-hooks';
import { updateShadowCalcVariables } from '../store/reducers/page-shadow-store/page_shadow_store';
import useAppEditor from './PlateConfig';

const PlateEditorReadOnly = () => {
  const dispatch = useAppDispatch()
  const page_sections = useAppSelector(state => state.page_sections)
  const isTemplate = useAppSelector(state => state.page_navigation.template)
  const calc_vars = useAppSelector(state => state.page_calc_variables)
  const [shadowEditorState, setShadowEditorState] = useState(page_sections.sections as TElement[])
  const isLandingPage = useAppSelector((state) => state.page_addendums.isLandingPage);

  useEffect(() => {
    dispatch(updateShadowCalcVariables(calc_vars))
  }, [])

  useEffect(() => {
    setShadowEditorState(page_sections.sections as TElement[])
  }, [page_sections])

  const editor = useMemo(() => useAppEditor(isTemplate, shadowEditorState, 'editor_read_only'), [])

  // CRITICAL: This is workaround to scroll to section by id
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
          !isLandingPage && window.history.pushState("", document.title, window.location.pathname + window.location.search);
        }
      }, 500);
    }
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Plate editor={editor} readOnly={true} >
        <EditorContainer>
          <Editor />
        </EditorContainer>
      </Plate>
    </DndProvider>
  );
}

export default PlateEditorReadOnly;