import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import { PlateTestimonialGroupElement } from "../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import BlockMenubarColors from "../../../shared/BlockMenubarControls/BlockMenubarColors";
import BlockMenubarSlider from "../../../shared/BlockMenubarControls/BlockMenubarSlider";
import BlockMenubarDragList from "../../../shared/BlockMenubarControls/BlockMenubarDragList";
import { createDefaultTestimonialElement } from "../../../plate-config/Plugins/Testimonial/Testimonial.plugin";


type Props = {
  showTrigger: boolean;
  block: PlateTestimonialGroupElement;
  onLibModalOpen: () => void;
}

const Menubar: React.FC<Props> = ({ showTrigger, block }) => {
  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const dragList = block.children.map((item, index) => ({
    id: item.id,
    title: item.user_name ? item.user_name.trim().length ? item.user_name : `Item ${index + 1}` : `Item ${index + 1}`
  }))

  const onGroupDelete = () => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onTemplateChange = (template: string) => editor.tf.setNodes({ template } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onBgColorChange = (card_bg_color: string | null) => editor.tf.setNodes({ card_bg_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onTextColorChange = (text_color: string | null) => editor.tf.setNodes({ text_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onBgOpacityChange = (value: number[]) =>
    editor.tf.setNodes({ card_bg_opacity: value[0] } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onItemAdd = () => {
    const path = editor.api.findPath(block)!
    editor.tf.insertNodes(createDefaultTestimonialElement(), { at: [...path, block.children.length] })
  }
  const onItemDelete = (id: string) => editor.tf.removeNodes({ at: [], match: (n: any) => n.id === id })
  const onOrderUpdate = (oldIndex: number, newIndex: number) => {
    const node = editor.api.node({ at: [], match: (n: any) => n.id === block.children[oldIndex].id })!
    const path = [...node[1]]
    editor.tf.moveNodes({ at: node[1], to: path.slice(0, -1).concat(newIndex) })
  }

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      <MenuBar.Menu value="testimonials_template">
        <BlockMenubarTrigger isDropdown label="Template" isOpened={value === 'testimonials_template'} />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarOptionList
              data={[{ key: "slider", value: "Slider" }, { key: "cards", value: "Cards" }]}
              onOptionClick={onTemplateChange}
              activeKey={block.template}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Menu value="testimonials_order">
        <BlockMenubarTrigger isDropdown label="Manage Items" isOpened={value === 'testimonials_order'} />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarDragList
              onItemAdd={onItemAdd}
              onItemDelete={onItemDelete}
              items={dragList}
              onOrderUpdate={onOrderUpdate}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      {/* Temporary disabled due to API functionality */}
      {/* <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="testimonials_library">
        <BlockMenubarTrigger labelIcon="stackPlus" onClick={onLibModalOpen} />
      </MenuBar.Menu> */}
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="testimonials_colors">
        <BlockMenubarTrigger
          isDropdown isColor color={block.card_bg_color} isOpened={value === 'testimonials_colors'}
          tooltip="Colors" containerId={block.id}
        />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarColors
              color={block.card_bg_color}
              onColorChange={onBgColorChange}
              onColorReset={onBgColorChange.bind(onBgColorChange, null)}
              label="Background Color"
            />
            <BlockMenubarSlider
              label="Opacity"
              value={[block.card_bg_opacity]}
              onValueChange={onBgOpacityChange}
              min={0}
              max={100}
            />
            <BlockMenubarColors
              color={block.text_color}
              onColorChange={onTextColorChange}
              onColorReset={onTextColorChange.bind(onTextColorChange, null)}
              label="Text Color"
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="testimonials_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onGroupDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;