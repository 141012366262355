import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"

export const ImagePlugin = createPlatePlugin({
  key: 'image',
  node: {
    type: 'image',
    isVoid: true,
    isElement: true,
  },
})

export interface PlateImageElement extends TElement {
  id: string
  type: typeof ImagePlugin.key
  image_external_id: string | null
  image_source: string | null
  image_url: string | null
  internal_page_id: string | null
  link_type: string | null
  shape: string
  width: string
  align: string
  url: string | null
  internal_resource_id: string | null
  internal_template_resource_slot_id: string | null
}

export enum ProgramaticTypes {
  BRAND_LOGO = "{{BUYER_LOGO}}",
  PROFILE_IMAGE = "{{PROFILE_IMAGE}}"
}


export const createDefaultImageElement = (): TElement => ({
  type: ImagePlugin.key,
  children: [{ text: '' }],
  image_external_id: null,
  image_source: null,
  image_url: null,
  internal_page_id: null,
  link_type: null,
  shape: 'square',
  width: '100%',
  align: 'center',
  url: null,
  internal_resource_id: null,
  internal_template_resource_slot_id: null,
})