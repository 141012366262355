import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"


export const EmbedPlugin = createPlatePlugin({
  key: 'embed',
  node: {
    type: 'embed',
    isElement: true,
    isVoid: true,
  }
})

export interface PlateElementEmbed extends TElement {
  id: string
  type: typeof EmbedPlugin.key
  url: string | null
  width: string
  align: string
  width_mode: string | null
}

export const createDefaultEmbedElement = (): TElement => ({
  type: EmbedPlugin.key,
  url: null,
  children: [{ text: '' }],
  width: '100%',
  align: 'center',
  width_mode: null
})