import { TElement } from '@udecode/plate';
import { createPlatePlugin } from '@udecode/plate/react';

import { textNodeTypes } from '../../../utils/plate.util';
import { SectionPlugin } from '../Section/Section.plugin';


export const TextColorLeafPlugin = createPlatePlugin({
  key: 'font_color',
  node: {
    isLeaf: true,
  },
}).overrideEditor(({ editor, tf: { normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // If section node has font_color property we need to add font_color marks to all text nodes inside the section
        if (textNodeTypes.includes(node.type as string)) {
          const textNode = node as TElement;
          const section = editor.api.node({ at: [path[0]], match: { type: SectionPlugin.key } });
          if (section) {
            if (section[0].font_color) {
              textNode.children.forEach((child, index) => {
                // we need to check if child is one of inline plugins, like link
                //  if it has it's own font color we should not override it
                if (child?.type) {
                  const inlineElement = child as TElement
                  if (inlineElement?.children[0]?.font_color) {
                    return
                  }
                } else {
                  if (child.font_color) {
                    return;
                  }
                }
                editor.tf.setNodes({ font_color: section[0].font_color }, { at: [...path, index], marks: true });
              });
            }
          }
        }
        normalizeNode([node, path])
      },
    }
  }
})