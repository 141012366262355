import { TElement } from "@udecode/plate"
import { createPlatePlugin } from "@udecode/plate/react"

import { createDefaultTestimonialElement, PlateTestimonialElement, TestimonialPlugin } from "./Testimonial.plugin";

export const TestimonialGroupPlugin = createPlatePlugin({
  key: 'testimonial_group',
  node: {
    type: 'testimonial_group',
    isElement: true,
  },
}).overrideEditor(({ editor, tf: { normalizeNode } }) => {
  return {
    transforms: {
      normalizeNode([node, path]) {
        // Rule: group should be deleted if it has no children
        if (node.type === TestimonialGroupPlugin.node.type) {
          const block = node as PlateTestimonialGroupElement;
          if (block.children.length === 0) {
            editor.tf.removeNodes({ at: path });
            return;
          }
        }
        // Children control - only testimonials allowed
        if (node.type === TestimonialGroupPlugin.node.type) {
          const block = node as PlateTestimonialGroupElement;
          block.children.map((child) => {
            if (child.type !== TestimonialPlugin.node.type) {
              editor.tf.removeNodes({ at: [], match: (n: any) => n.id === child.id });
            }
          });
        }
        normalizeNode([node, path])
      },
    }
  }
})

export interface PlateTestimonialGroupElement extends TElement {
  id: string
  type: typeof TestimonialGroupPlugin.key
  children: PlateTestimonialElement[]
  template: string
  card_bg_color: string | null
  card_bg_opacity: number
  text_color: string | null
}

export const createDefaultTestimonialGroupElement = (): TElement => ({
  type: TestimonialGroupPlugin.key,
  children: [createDefaultTestimonialElement()],
  template: 'slider',
  card_bg_color: 'color-white',
  card_bg_opacity: 100,
  text_color: 'black',
})
