import React from "react";
import { useEditorRef } from "@udecode/plate/react";

import { PlateSectionElement } from "../../../../plate-config/Plugins/Section/Section.plugin";
import ToolbarSeparator from "../../../../shared/ToolbarControls/ToolbarSeparator";
import ToolbarSelector from "../../../../shared/ToolbarControls/ToolbarSelector";
import BlockMenubarHeader from "../../../../shared/BlockMenubarControls/BlockMenubarHeader";


type Props = {
  onMenuClose: () => void;
  sectionElement: PlateSectionElement
}

const Animation: React.FC<Props> = ({ onMenuClose, sectionElement }) => {
  const editor = useEditorRef()

  const onAnimateTypeChange = (animate_type: string) => {
    if (animate_type === 'no-animation') {
      editor.tf.setNodes(
        { animate_type, animate_direction: null, animate_speed: null, animate_style: null } as Partial<Node>,
        { at: [], match: (n: any) => n.id === sectionElement.id }
      )
    } else {
      editor.tf.setNodes(
        {
          animate_type,
          animate_direction: sectionElement.animate_direction || 'right',
          animate_speed: sectionElement.animate_speed || 'medium',
          animate_style: sectionElement.animate_style || 'fade',
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === sectionElement.id }
      )
    }
    reanimate()
  }

  const onAnimateStyleChange = (animate_style: string) => {
    editor.tf.setNodes({ animate_style } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })
    reanimate()
  }

  const onAnimateDirectionChange = async (value: string) => {
    editor.tf.setNodes({ animate_direction: value } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })
    reanimate()
  }

  const onAnimateSpeedChange = (animate_speed: string) => {
    editor.tf.setNodes({ animate_speed } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionElement.id })
    reanimate()
  }

  const onPreviewHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    reanimate()
  }

  const reanimate = () => {
    editor.tf.setNodes(
      { animationCount: typeof sectionElement.animationCount === 'number' ? sectionElement.animationCount + 1 : 0 } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
  }

  return (
    <section className="flex flex-col gap-4">
      <BlockMenubarHeader label="Section Animation" onClose={onMenuClose} />
      <ToolbarSelector
        activeValue={sectionElement.animate_type as string || "no-animation"}
        label="Visibility"
        onValueChange={onAnimateTypeChange}
        data={[{ key: "no-animation", value: "Disable" }, { key: "animate", value: "Enable" }]}
      />
      {sectionElement.animate_type === "animate" &&
        <>
          <ToolbarSeparator variant="horizontal" />
          <ToolbarSelector
            activeValue={sectionElement.animate_style as string || 'slide'}
            label="Style"
            onValueChange={onAnimateStyleChange}
            data={[{ key: "fade", value: "Fade In" }, { key: "slide", value: "Slide In" }]}
          />
          <ToolbarSeparator variant="horizontal" />
          <ToolbarSelector
            activeValue={sectionElement.animate_direction as string || 'right'}
            label="Direction"
            onValueChange={onAnimateDirectionChange}
            data={[
              { key: "left", value: 'left', icon: 'arrowLeft' },
              { key: "right", value: 'right', icon: 'arrowRight' },
              { key: "up", value: 'up', icon: 'arrowUp' },
              { key: "down", value: 'down', icon: 'arrowDown' }
            ]}
          />
          <ToolbarSeparator variant="horizontal" />
          <ToolbarSelector
            activeValue={sectionElement.animate_speed as string || 'medium'}
            label="Speed"
            onValueChange={onAnimateSpeedChange}
            data={[{ key: "slow", value: "Slow" }, { key: "medium", value: "Med" }, { key: "fast", value: "Fast" }]}
          />
          <ToolbarSeparator variant="horizontal" />
          <button className="brand_btn-secondary w-full" onClick={onPreviewHandle} > Preview </button>
        </>
      }
    </section>
  );
}

export default Animation;