import React, { useState } from "react";
import { PlateElement, useReadOnly, withRef, useSelected } from "@udecode/plate/react";

import { PlateElementAiTextBlock } from "../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import AiTextBlockSummary from "./AiTextBlockSummary/AiTextBlockSummary";
import AiTextBlockPreview from "./AiTextBlockPreview/AiTextBlockPreview";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { textColorFromColorClass } from "../../utils/color.util";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";
import SettingsView from "./SettingsView/SettingsView";
import ModalEditor from "./ModalEditor/ModalEditor";
import Menubar from "./Menubar/Menubar";


const AiTextBlockVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isReadOnly = useReadOnly();
    const selected = useSelected()
    const colors = useAppSelector(state => state.page_colors.activePalette.colors)
    const [modalOpen, setModalOpen] = useState(false)

    const block = element as PlateElementAiTextBlock
    const nodePath = editor.api.node({ at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const colBgColor = isElColumnChild ? parentNode[0].bg_color ? parentNode[0].bg_color : null : null
    const activeSectionCardColor = activeSection.card_color as string
    const activeSectionBgColor = activeSection.tint_color as string
    const activeSectionTextColor = activeSection.font_color as string
    const bgColour = activeSectionCardColor ? activeSectionCardColor : activeSectionBgColor
    const dynamicTextColor = textColorFromColorClass(bgColour, colors)
    const textColor = block.primary_color
      ? block.primary_color
      : colBgColor
        ? `text-${textColorFromColorClass(colBgColor as string, colors)}`
        : activeSectionTextColor
          ? activeSectionTextColor
          : `text-${dynamicTextColor}`
    const generalBlockCheck = !!block.details.prompt && !!block.details.length_min && !!block.details.length_max
      && block.details.length_min <= block.details.length_max && block.details.length_max >= block.details.length_min
    const aiTextReady = block.details.kind === "list"
      ? generalBlockCheck && !!block.details.list_items_min && !!block.details.list_items_max &&
      block.details.list_items_min <= block.details.list_items_max && block.details.list_items_max >= block.details.list_items_min
      : generalBlockCheck

    const [activeView, setActiveView] = useState(aiTextReady ? "preview" : "settings")

    const onActiveViewChange = (view: string) => setActiveView(view)
    const onEditModalOpen = () => {
      setModalOpen(true)
      setActiveView('modal')
    }
    const onEditSummaryModalOpen = () => {
      setModalOpen(true)
      setActiveView('summary')
    }
    const onEditModalClose = () => {
      setModalOpen(false)
      setActiveView(aiTextReady ? "summary" : "settings")
    }

    if (!aiTextReady && isReadOnly) {
      return null
    }
    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={element}
        className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
           ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          } 
        ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block ai-text-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        `}
        data-plate-selectable
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        {children}
        {activeView === 'preview'
          && <AiTextBlockPreview element={block} textColor={textColor} markerColor={dynamicTextColor} />
        }
        {activeView === 'settings' && <SettingsView onModalOpen={onEditModalOpen} />}
        {activeView === 'summary' && <AiTextBlockSummary element={block} onActiveViewChange={onActiveViewChange} onEditModalOpen={onEditSummaryModalOpen} />}
        {activeView === 'modal' && <div className="min-h-[400px] bg-gray-100 rounded flex w-full" />}
        <ModalEditor modalOpen={modalOpen} onModalClose={onEditModalClose} element={block} />
        {!isReadOnly && <Menubar showTrigger={isReadOnly ? false : selected} block={block} activeView={activeView} onEditModalOpen={onEditModalOpen} />}
      </PlateElement>
    )
  })

export default AiTextBlockVoidElement