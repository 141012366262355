import React from "react";
import { useEditorRef } from "@udecode/plate/react";

import { PlateElementAiTextBlock, UnionSourceTypes } from "../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import Prompt from "./Prompt/Prompt";
import ContentDetails from "./ContentDetails/ContentDetails";
import TextColor from "./TextColor/TextColor";
import AiSelector from "./AiSelector/AiSelector";
import TextLength from "./TextLength/TextLength";
import ListLength from "./ListLength/ListLength";
import ListOptions from "./ListOptions/ListOptions";
import AdditionalListParams from "./AdditionalListParams/AdditionalListParams";
import SourcesCheckbox from "./SourcesCheckbox/SourcesCheckbox";
import ResourcesCheckbox from "./ResourcesCheckbox/ResourcesCheckbox";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";


type Props = {
  element: PlateElementAiTextBlock
  onModalClose: () => void
}


const ModalContent: React.FC<Props> = ({ element, onModalClose }) => {
  const editor = useEditorRef()
  const template = useAppSelector(state => state.page_navigation.template)

  const onElementKindChange = (kind: string) =>
    editor.tf.setNodes({ details: { ...element.details, kind } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onPromptChange = (prompt: string) =>
    editor.tf.setNodes({ details: { ...element.details, prompt } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onTextTypeChange = (length_type: string) =>
    editor.tf.setNodes({ details: { ...element.details, length_type } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onMinTextLengthChange = (length_min: number) =>
    editor.tf.setNodes({ details: { ...element.details, length_min } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onMaxTextLengthChange = (length_max: number) =>
    editor.tf.setNodes({ details: { ...element.details, length_max } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onAlignmentChange = (alignment: string) =>
    editor.tf.setNodes({ details: { ...element.details, alignment } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onPrimaryColorChange = (primary_color: string) =>
    editor.tf.setNodes({ primary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onListStyleChange = (list_style: string) =>
    editor.tf.setNodes({ details: { ...element.details, list_style } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onMinListItemsChange = (list_items_min: number) =>
    editor.tf.setNodes({ details: { ...element.details, list_items_min } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onMaxListItemsChange = (list_items_max: number) =>
    editor.tf.setNodes({ details: { ...element.details, list_items_max } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onListExtraSpacingChange = (list_extra_spacing: boolean) =>
    editor.tf.setNodes({ details: { ...element.details, list_extra_spacing } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onSourceChange = (source: UnionSourceTypes) =>
    editor.tf.setNodes({ details: { ...element.details, [source]: !element.details[source] } } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const onListKindChange = (list_kind: string) => {
    const listOutputsParagraphs = list_kind === "feature_benefit" || list_kind === "objection" || list_kind === "bolded_summary_paragraph"
    editor.tf.setNodes(
      { details: { ...element.details, list_kind, list_extra_spacing: !listOutputsParagraphs ? false : element.details.list_extra_spacing } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onToggleSlotResouce = (resourceId: string) => {
    const idIncluded = element.details.template_resource_slot_ids.includes(resourceId)
    const template_resource_slot_ids = idIncluded
      ? [...element.details.template_resource_slot_ids.filter(id => id !== resourceId)]
      : [...element.details.template_resource_slot_ids, resourceId]
    editor.tf.setNodes(
      { details: { ...element.details, template_resource_slot_ids } } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const alignmentOptions = [
    { key: "left", value: 'left', icon: "textAlignLeft" },
    { key: "center", value: 'center', icon: 'textAlignCenter' },
    { key: "right", value: "right", icon: "textAlignRight" }
  ]
  const elementOptions = [{ key: "header", value: "Header" }, { key: "paragraph", value: "Paragraph" }, { key: "list", value: "List" },]
  const textOptions = [
    { key: "words", value: "Words" }, { key: "sentences", value: "Sentences" }, ...(element.details.kind !== 'header' ? [{ key: "paragraphs", value: "Paragraphs" }] : [])
  ]
  const listOptions = [{ key: "unordered", value: "Bulleted" }, { key: "ordered", value: "Numbered" }]
  const showAlignmentOptions = element.details.kind !== "list" ||
    (element.details.kind === "list" && (element.details.list_kind === "feature_benefit" ||
      element.details.list_kind === "objection" ||
      element.details.list_kind === "bolded_summary_paragraph"));
  const listOutputsParagraphs = element.details.list_kind === "feature_benefit" ||
    element.details.list_kind === "objection" ||
    element.details.list_kind === "bolded_summary_paragraph";
  const generalBlockCheck = !!element.details.prompt && !!element.details.length_min && !!element.details.length_max
    && element.details.length_min <= element.details.length_max && element.details.length_max >= element.details.length_min
  const aiTextReady = element.details.kind === "list"
    ? generalBlockCheck && !!element.details.list_items_min && !!element.details.list_items_max &&
    element.details.list_items_min <= element.details.list_items_max && element.details.list_items_max >= element.details.list_items_min
    : generalBlockCheck

  return (
    <div className="flex pb-6 px-6 flex-col gap-6">
      <div className="flex w-full">
        <div className="flex flex-col w-[50%] pr-6 border-r border-gray-300 gap-4">
          <AiSelector activeValue={element.details.kind} onChange={onElementKindChange} data={elementOptions} label="Element to Generate" />
          <Prompt onPromptChange={onPromptChange} prompt={element.details.prompt || ''} />
          {element.details.kind === 'list'
            ?
            <>
              <ContentDetails title='List Length' subTitle='Control the number of items that will be generated in the list' />
              <AiSelector onChange={onListStyleChange} activeValue={element.details.list_style} data={listOptions} />
              <ListLength
                element={element}
                list_items_min={element.details.list_items_min || 0}
                list_items_max={element.details.list_items_max || 0}
                onMinListItemsChange={onMinListItemsChange}
                onMaxListItemsChange={onMaxListItemsChange}
              />
              <ListOptions element={element} onListKindChange={onListKindChange} />
              {listOutputsParagraphs && <AdditionalListParams value={element.details.list_extra_spacing} onChange={onListExtraSpacingChange} />}
              <ContentDetails title='List Item Details' subTitle='Control the individual items that are generated in the list' />
              <AiSelector onChange={onTextTypeChange} activeValue={element.details.length_type} data={textOptions} />
              <TextLength
                element={element}
                length_min={element.details.length_min || 0}
                length_max={element.details.length_max || 0}
                onMinLengthChange={onMinTextLengthChange}
                onMaxLengthChange={onMaxTextLengthChange}
              />
            </>
            :
            <>
              <ContentDetails title='Content Details' subTitle='Control the length of the content generated' />
              <AiSelector onChange={onTextTypeChange} activeValue={element.details.length_type} data={textOptions} />
              <TextLength
                element={element}
                length_min={element.details.length_min || 0}
                length_max={element.details.length_max || 0}
                onMinLengthChange={onMinTextLengthChange}
                onMaxLengthChange={onMaxTextLengthChange}
              />
            </>
          }
        </div>
        <div className="flex flex-col w-[50%] pl-6 gap-4">
          <ContentDetails title='Settings' subTitle='Select sources, choose resources, and customize the text.' />
          {showAlignmentOptions &&
            <AiSelector onChange={onAlignmentChange} activeValue={element.details.alignment} label="Alignment" data={alignmentOptions} />
          }
          <TextColor color={element.primary_color} onColorChange={onPrimaryColorChange} />
          <SourcesCheckbox element={element} onSourceChange={onSourceChange} />
          {template && <ResourcesCheckbox element={element} onToggleSlotResouce={onToggleSlotResouce} />}
        </div>
      </div>
      <button onClick={onModalClose} className="brand_btn-primary w-full" disabled={!aiTextReady} >
        Save
      </button>
    </div>
  );
};

export default ModalContent;