import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addItem", "template"];

  addAssociation(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addItemTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeAssociation(event) {
    event.preventDefault()
    let item = event.target.closest("[data-section~=nested-fields]")
    item.querySelector("input[name*='_destroy']").value = 1
    item.querySelectorAll("input[required]").forEach(i => i.required = false)
    item.style.display = 'none'
  }
}
