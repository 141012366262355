import React from 'react';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import { PlateElement, useReadOnly, withRef } from "@udecode/plate/react";

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmojiInput,
  InlineEmojiComboboxItem,
} from '../InlineCombobox/InlineCombobox';


export const EmojiInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;
    const isReadOnly = useReadOnly()

    if (isReadOnly) return <>{children}</>

    const slashNode = editor.api.node({ at: [], match: (n: any) => n.id === props.element.id })!

    const onEmojiClick = (emojiObject: EmojiClickData) => {
      editor.tf.insertNodes({ text: emojiObject.emoji }, { at: slashNode[1], select: true })
    }

    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        className={className}
        ref={ref}
        data-id={element.id}
        {...props}
      >
        <InlineCombobox element={element} trigger=":">
          <InlineComboboxEmojiInput />
          <InlineComboboxContent>
            <InlineEmojiComboboxItem value={'dummy value'} className='p-0'  >
              <EmojiPicker
                autoFocusSearch={true}
                emojiStyle={EmojiStyle.NATIVE}
                lazyLoadEmojis={true}
                skinTonesDisabled={true}
                onEmojiClick={onEmojiClick}
                height={'420px'}
                width={'350px'}
              />
            </InlineEmojiComboboxItem>
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);
