import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { ParagraphPlugin, PlateElement, useReadOnly, useSelected } from '@udecode/plate/react';

import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';


export const ParagraphElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const isReadOnly = useReadOnly()
    const selected = useSelected();

    const nodePath = editor.api.node({ at: [], match: { id: element.id } })![1];
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const nextColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.findIndex(child => child.id === element.id) + 1] : null
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const isNextColElementParagraph = nextColElement ? nextColElement.type === ParagraphPlugin.key : false
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    return (
      <PlateElement
        ref={ref}
        data-id={element.id as string}
        id={element.id as string}
        editor={editor}
        element={element}
        asChild
        className={cn(`
        p-2 text-base paragraph-block rounded-lg transition-all duration-300 ease-in-out selection:bg-sky-50 border border-transparent
        ${!isReadOnly && !selected ? 'hover:bg-[#B9D3F426]' : ''} ${!isReadOnly && selected ? 'border-sky-50' : ''}
         ${isElColumnChild
            ? `${isLastColElement ? "mb-0" : isNextColElementParagraph ? "mb-2" : "mb-4"}`
            : `page-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        `,
          className
        )}
        {...props}
      >
        <p>{children}</p>
      </PlateElement>
    );
  }
);
