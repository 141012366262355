import React from "react";

import { PlateElementAiTextBlock, UnionSourceTypes } from "../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import Checkbox from "../../../../../shared/Inputs/Checkbox";

type Props = {
  element: PlateElementAiTextBlock
  onSourceChange: (source: UnionSourceTypes) => void
}

const SourcesCheckbox: React.FC<Props> = ({ element, onSourceChange }) => {
  const options = [
    { name: "source_buyer_summary", label: "Company Summary" },
    { name: "source_buyer_contact", label: "Contact Summary" },
    { name: "source_persona", label: "Buyer Persona" },
    { name: "source_seller_summary", label: "Seller Summary" },
    { name: "source_seller_benefits", label: "Seller Benefits" },
    { name: "source_seller_testimonials", label: "Seller Testimonials" },
  ]

  return (
    <div className="flex flex-col gap-4">
      <div className="brand-text text-sm font-medium">Sources to Use</div>
      <div className="flex flex-col gap-2">
        {options.map((source, i) => (
          <Checkbox
            key={i}
            htmlFor={source.name}
            label={source.label}
            value={!!element.details[source.name as UnionSourceTypes]}
            setValue={onSourceChange.bind(onSourceChange, source.name as UnionSourceTypes)}
          />
        ))}
      </div>
    </div>
  )
}

export default SourcesCheckbox;
