import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { ImagePlaceholders, PageData } from '../api/api.types'

export interface PageImages {
  profile_image: string | null
  image_placeholders: ImagePlaceholders
}

const initialState: PageImages = {
  profile_image: null,
  image_placeholders: {
    // Used old images url as fallback
    profile_url: "https://res.cloudinary.com/dvrkbepve/image/upload/v1702354634/profile-placeholder-image_aqsot2.png",
    brand_url: "https://res.cloudinary.com/dvrkbepve/image/upload/v1701483165/brand-placeholder-image_mablwd.png"
  }
}

export const pageImagesSlice = createSlice({
  name: 'page_images',
  initialState,
  reducers: {
    setPageImages: (state, action: PayloadAction<PageData>) => {
      return {
        ...state,
        profile_image: action.payload.profile_image_url,
        image_placeholders: action.payload.image_placeholders
      }
    },
  },
})

export const { setPageImages } = pageImagesSlice.actions

export default pageImagesSlice.reducer