import React from "react";
import { useEditorRef } from "@udecode/plate/react";

import { PhIconName } from "../../../shared/Icon/PhIcon";
import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";

type Props = {
  mark: string;
  iconName: PhIconName;
  containerId?: string;
  tooltip?: string;
}

const BasicMarks: React.FC<Props> = ({ mark, iconName, containerId, tooltip }) => {
  const editor = useEditorRef()
  const marks = editor.api.marks()

  const onMarkChange = () => {
    editor.tf.toggleMark(mark);
    editor.tf.select(editor.selection!);
    editor.tf.focus();
  }

  return <BlockMenubarTrigger
    labelIcon={iconName}
    onClick={onMarkChange}
    isOpened={!!marks?.[mark]}
    containerId={containerId}
    tooltip={tooltip}
  />
}

export default BasicMarks;