import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { useEditorRef, useReadOnly, useSelected } from '@udecode/plate/react';

import { PlateElementEmbed } from "../../../../plate-config/Plugins/Embed/Embed.plugin";
import EmbedViewController from "../EmbedViewController/EmbedViewController";
import { EmbedConfig } from "../../config/config";


type Props = {
  element: PlateElementEmbed,
  platform: EmbedConfig,
  isActive: boolean
}

const ResizableEmbed: React.FC<Props> = ({ element, platform, isActive }) => {
  const editor = useEditorRef();
  const selected = useSelected()
  const isReadOnly = useReadOnly()

  const [size, setSize] = useState({ width: element.width ? element.width : '100%' });

  const onSizeChange = (width: string) =>
    editor.tf.setNodes(
      { width, width_mode: null } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  return (
    <Resizable
      defaultSize={{ width: size.width, height: '100%' }}
      onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
      onResizeStop={(_e, _direction, ref) => onSizeChange(ref.style.width)}
      minWidth={'25%'}
      maxWidth={'100%'}
      className={`resizer-wrapper grid ${isActive && selected ? 'active' : ''}`}
      handleClasses={{
        top: 'pointer-events-none', right: 'pointer-events-none',
        bottom: 'pointer-events-none', left: 'pointer-events-none',
      }}
      handleComponent={{
        top: <div className="resizer_border top" />,
        bottom: <div className="resizer_border bottom" />,
        right: <div className="resizer_border right" />,
        left: <div className="resizer_border left" />,
        bottomRight: <div className="resizer_dot bottom-right" />,
        bottomLeft: <div className="resizer_dot bottom-left" />,
        topRight: <div className="resizer_dot top-right" />,
        topLeft: <div className="resizer_dot top-left" />,
      }}
      enable={isReadOnly ? false : selected ? undefined : false}
    >
      <EmbedViewController platform={platform} element={element} />
    </Resizable>
  )
}

export default ResizableEmbed;