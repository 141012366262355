import React, { useEffect, useRef, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { PagePalette } from "../../../../../store/reducers/page_colors/page_colors";
import PhIcon from "../../../../../shared/Icon/PhIcon";

type Props = {
  activePalette: PagePalette
  pallets: PagePalette[]
  onColorSourceChange: (palette: string) => void
}

const ColorSourceSelector: React.FC<Props> = ({
  activePalette, pallets, onColorSourceChange
}) => {

  const [open, setOpen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>();

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const updateWidth = () => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
  };

  const onColorSourceChangeHandler = (palette: string) => {
    onColorSourceChange(palette);
    setOpen(false);
  }

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <div
          className={`
            p-2 rounded-xl border bg-white hover:border-gray-400 cursor-pointer
            flex justify-between gap-2 items-center outline-none mt-4 w-full text-xs
            ${open ? "active_input text-gray-500" : "border-gray-300 text-brand"}
            `}
          ref={elementRef}
        >
          <span className="brand-text text-base font-normal capitalize">{activePalette.palette}</span>
          <PhIcon name="caretDown" size={16} color="text-black" />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="dropdown_content max-h-[180px] z-[4]" style={{ width }}>
          {
            pallets.map((data, index) => {
              return (
                <DropdownMenu.Item
                  key={index}
                  className={`dropdown_item ${activePalette.palette === data.palette ? 'active' : ''}`}
                  onClick={onColorSourceChangeHandler.bind(onColorSourceChangeHandler, data.palette)}
                >
                  <span className="brand-text text-base">{data.label}</span>
                  {activePalette.palette === data.palette && <PhIcon name="check" size={16} color="text-black" />}
                </DropdownMenu.Item>
              );
            })
          }
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default ColorSourceSelector;