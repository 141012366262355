import React from "react";

import { useAppSelector } from "../../../store/hooks/redux-hooks";

const PageLogo: React.FC = () => {
  const { template } = useAppSelector((state) => state.page_navigation);
  const { room_slug } = useAppSelector((state) => state.page_addendums);
  return (
    <div className="flex items-center">
      <a className="normal-case text-3xl font-extrabold mr-[22px]" href={template ? "/templates" : `/rooms/${room_slug}/coach`}>
        <img alt="RevvedUp.ai" src="/logo.svg" />
      </a>
    </div>
  );
}

export default PageLogo;