import React, { useState } from "react";
import { useEditorRef } from "@udecode/plate/react";

import ScrollableDialog from "../../../shared/ScrollableDialog/ScrollableDialog";
import LibraryHeader from "./LibraryHeader";
import LibraryContent from "./LibraryContent";
import LibraryFooter from "./LibraryFooter";
import { PlateTestimonialGroupElement } from "../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import { TestimonialPlugin } from "../../../plate-config/Plugins/Testimonial/Testimonial.plugin";

type Props = {
  onModalClose: () => void;
  modalOpen: boolean;
  block: PlateTestimonialGroupElement
}

const LibraryDialog: React.FC<Props> = ({ onModalClose, modalOpen, block }) => {

  const editor = useEditorRef()
  const [idToAdd, setIdToAdd] = useState<string[]>([])

  const testimonials = [
    {
      id: '1', content: 'content', user_name: 'Name Surname', user_position: 'CEO NIKE', image_external_id: 'RnCPiXixooY', image_source: 'unsplash',
      image_url: 'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjUyOTF8MHwxfHNlYXJjaHwxfHxyYW5kb218ZW58MHx8fHwxNzM0MzQ3MzYzfDA&ixlib=rb-4.0.3&q=80&w=1080'
    },
    {
      id: '2', content: 'content', user_name: 'Name Surname', user_position: 'CEO NIKE', image_external_id: 'RnCPiXixooY', image_source: 'unsplash',
      image_url: 'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjUyOTF8MHwxfHNlYXJjaHwxfHxyYW5kb218ZW58MHx8fHwxNzM0MzQ3MzYzfDA&ixlib=rb-4.0.3&q=80&w=1080'
    },
    {
      id: '3', content: 'content', user_name: 'Name Surname', user_position: 'CEO NIKE', image_external_id: 'RnCPiXixooY', image_source: 'unsplash',
      image_url: 'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjUyOTF8MHwxfHNlYXJjaHwxfHxyYW5kb218ZW58MHx8fHwxNzM0MzQ3MzYzfDA&ixlib=rb-4.0.3&q=80&w=1080'
    },
    {
      id: '4', content: 'content', user_name: 'Name Surname', user_position: 'CEO NIKE', image_external_id: 'RnCPiXixooY', image_source: 'unsplash',
      image_url: 'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjUyOTF8MHwxfHNlYXJjaHwxfHxyYW5kb218ZW58MHx8fHwxNzM0MzQ3MzYzfDA&ixlib=rb-4.0.3&q=80&w=1080'
    },
    {
      id: '5', content: 'content', user_name: 'Name Surname', user_position: 'CEO NIKE', image_external_id: 'RnCPiXixooY', image_source: 'unsplash',
      image_url: 'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjUyOTF8MHwxfHNlYXJjaHwxfHxyYW5kb218ZW58MHx8fHwxNzM0MzQ3MzYzfDA&ixlib=rb-4.0.3&q=80&w=1080'
    }
  ]

  const toggleIdToAdd = (id: string) => {
    setIdToAdd(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  const onTestimonialsAdd = () => {
    const newTestimonials = testimonials.filter(testimonial => idToAdd.includes(testimonial.id)).map(testimonial => {
      return {
        ...testimonial,
        type: TestimonialPlugin.key,
        children: [{ text: '' }],
      }
    }
    )
    const path = editor.api.findPath(block)!
    editor.tf.insertNodes(newTestimonials, { at: [...path, block.children.length] })
    onModalClose()
  }

  return (
    <ScrollableDialog
      dialogHeader={<LibraryHeader onModalClose={onModalClose} />}
      scrollableContent={<LibraryContent testimonials={testimonials} toggleIdToAdd={toggleIdToAdd} />}
      dialogFooter={<LibraryFooter onModalClose={onModalClose} idToAdd={idToAdd.length} onTestimonialsAdd={onTestimonialsAdd} />}
      dialogOpen={modalOpen}
      onDialogClose={onModalClose}
      scrollableContentId="scrollable-dialog-content-id"
      dialogStyles={{ margin: '20px', marginTop: '70px' }}
      sxClassess={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": { width: "960px", maxWidth: "100%", height: '620px', maxHeight: '100%', margin: '0' },
          "& .MuiDialog-paper": {
            borderRadius: "32px", boxShadow: "0px 8px 24px 0px #00000026", outline: "6px solid #FFFFFF80", background: '#F8F5F5'
          },
          "& .MuiDialogContent-root": { padding: "0", borderTop: "unset", borderBottom: "unset" },
        },
      }}
    />
  )
}


export default LibraryDialog;