import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Resizable } from "re-resizable";
import { useEditorRef, useReadOnly } from '@udecode/plate/react';

import { PlateVideoElement } from '../../../plate-config/Plugins/Video/Video.plugin';


type Props = {
  element: PlateVideoElement,
  onSizeChange: (width: string) => void,
  controlsVisible: boolean
}

const VideoPlayer: React.FC<Props> = ({ element, onSizeChange, controlsVisible }) => {

  const editor = useEditorRef();
  const isReadOnly = useReadOnly()
  const [size, setSize] = useState({ width: element.width ? element.width : '100%' });

  const url = element.url as string
  const isLoomVideo = /loom\.com/i.test(url)
  const isVidyardVideo = /vidyard\.com/i.test(url)

  return (
    <div className='w-full block'>
      <div
        className={`flex 
  ${element.align === 'left' ? 'justify-start' : ''}
  ${element.align === 'center' ? 'justify-center' : ''}   
  ${element.align === 'right' ? 'justify-end' : ''}
  `}>
        <Resizable
          defaultSize={{ width: size.width, height: '100%' }}
          onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
          onResizeStop={(_e, _direction, ref) => onSizeChange(ref.style.width)}
          minWidth={'30%'}
          maxWidth={'100%'}
          className={`video-block-wrapper resizer-wrapper ${controlsVisible ? 'active' : ''}`}
          handleClasses={{
            top: 'pointer-events-none', right: 'pointer-events-none',
            bottom: 'pointer-events-none', left: 'pointer-events-none',
          }}
          handleComponent={{
            top: <div className="resizer_border top" />,
            bottom: <div className="resizer_border bottom" />,
            right: <div className="resizer_border right" />,
            left: <div className="resizer_border left" />,
            bottomRight: <div className="resizer_dot bottom-right" />,
            bottomLeft: <div className="resizer_dot bottom-left" />,
            topRight: <div className="resizer_dot top-right" />,
            topLeft: <div className="resizer_dot top-left" />,
          }}
          enable={isReadOnly ? false : controlsVisible ? undefined : false}
        >
          <div className="video-player-wrapper aspect-video">
            {isLoomVideo || isVidyardVideo
              ? <iframe
                src={isLoomVideo ? url.replace(/share/i, "embed") : url}
                allowFullScreen
                style={{
                  position: "absolute", top: "0", left: "0",
                  width: "100%", height: "100%",
                }}
              />
              : <ReactPlayer url={url} className="react-player" width="100%" height="100%" />
            }
          </div>
        </Resizable>
      </div>
    </div>
  )
}

export default VideoPlayer