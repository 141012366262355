import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';
import { TElement } from '@udecode/plate';

import { createDefaultSectionElement } from '../../../../components/plate-config/Plugins/Section/Section.plugin'
import { createDefaultParagraphElement } from '../../../../components/plate-config/Plugins/DefaultMockups/DefaultMockups';
import { PageData } from '../api/api.types';

type initState = {
  sections: TElement[],
  lastUpdated: string
  lastSavedSections: TElement[]
}

const blankSectionData = [{ ...createDefaultSectionElement(), id: uuidv4(), children: [{ ...createDefaultParagraphElement(), id: uuidv4() }] }]

const initialState: initState = {
  sections: [],
  lastUpdated: new Date().toISOString(),
  lastSavedSections: []
}

export const pageSectionsSlice = createSlice({
  name: 'page_sections',
  initialState,
  reducers: {
    // NOTE: Action to set initial data from the API
    // TODO: edit this reducer, because we'll temporary use endpoint which saves everything that receives and by default its empty object
    setSectionsFromAPI: (_state, action: PayloadAction<PageData>) => {
      const editor_data = action.payload.editor_data
      const sections = Array.isArray(editor_data) ? editor_data.length === 0 ? blankSectionData : editor_data : blankSectionData
      return {
        lastUpdated: action.payload.updated_at,
        sections: sections as TElement[],
        lastSavedSections: sections as TElement[]
      }
    },
    // NOTE: This action used to track editor changes
    setSectionsFromEditor: (state, action: PayloadAction<any>) => {
      return { ...state, sections: action.payload }
    },
    // NOTE: After successful sync with the API, we'll set the last sync date
    setLastSyncDate: (state, action: PayloadAction<any>) => {
      return { ...state, lastUpdated: new Date().toISOString(), lastSavedSections: action.payload }
    },
  }
})

export const { setSectionsFromAPI, setSectionsFromEditor, setLastSyncDate } = pageSectionsSlice.actions

export default pageSectionsSlice.reducer