import { type TriggerComboboxPluginOptions, withTriggerCombobox } from '@udecode/plate-combobox';
import { type PluginConfig, type TElement, createSlatePlugin, createTSlatePlugin } from '@udecode/plate';

export type EmojiConfig = PluginConfig<'emoji_command', TriggerComboboxPluginOptions>;

export const EmojiInputPlugin = createSlatePlugin({
  key: 'emoji_input',
  node: { isElement: true, isInline: true, isVoid: true },
});

export const EmojiPlugin = createTSlatePlugin<EmojiConfig>({
  key: 'emoji_command',
  options: {
    createComboboxInput: () => ({
      children: [{ text: '' }],
      type: EmojiInputPlugin.key,
    }),
    trigger: ':',
    triggerPreviousCharPattern: /^\s?$/,
  },
  plugins: [EmojiInputPlugin],
}).overrideEditor(withTriggerCombobox);

export const createDefaultEmojiElement = (): TElement => ({
  type: EmojiInputPlugin.key,
  children: [{ text: '' }],
})
