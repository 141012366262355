import React, { useState } from "react";
import { PlateElement, useReadOnly, withRef, useSelected } from "@udecode/plate/react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import ImagePlaceholder from "./ImagePlaceholder/ImagePlaceholder";
import { PlateImageElement } from "../../plate-config/Plugins/Image/Image.plugin";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";
import ImagePicker from "../../shared/ImagePicker/ImagePicker";
import ImageResizer from "./ImageResizer/ImageResizer";
import Menubar from "./Menubar/Menubar";

export const ImageVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isTemplate = useAppSelector(state => state.page_navigation.template)
    const isReadOnly = useReadOnly();
    const selected = useSelected()
    const pages = useAppSelector(state => state.page_navigation.all_pages)
    const resources = useAppSelector(state => state.page_resources.resources)
    const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)
    const [showImagePicker, setShowImagePicker] = useState(false)

    const block = element as PlateImageElement
    const nodePath = editor.api.node({ at: [], match: { id: block.id } })![1];
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const openState = isReadOnly ? false : selected
    const matchingPage = pages?.find(page => page.id === block.internal_page_id)
    const matchingResource = resources?.find(resource => resource.id === block.internal_resource_id)
    const externalLinkPath = block.url && block.url.length > 0 ? block.url : null
    const internalLinkPath = matchingPage ? isLandingPage ? matchingPage.landing_link : matchingPage.builder_link : null
    const resourceLinkPath = matchingResource ? matchingResource.goto_url : null
    const goToLink = block.link_type === "external" ? externalLinkPath :
      block.link_type === "internal" ? internalLinkPath :
        block.link_type === "resource" ? resourceLinkPath : null

    const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
      editor.tf.setNodes(
        { image_external_id, image_source, image_url, internal_page_id: null, link_type: "external" } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )
    const onImageUnset = () =>
      editor.tf.setNodes(
        { image_external_id: null, image_source: null, image_url: null, internal_page_id: null, link_type: null, } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )
    const onProgrammaticImageSet = (image_url: string, image_source: string | null) =>
      editor.tf.setNodes(
        { image_external_id: null, image_source, internal_page_id: null, image_url, link_type: 'external', shape: 'square' } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )
    const onImageSizeChange = (width: string) => editor.tf.setNodes({ width } as Partial<Node>, { at: [], match: (n: any) => { return n.id === block.id } })

    const onModalClose = () => {
      setShowImagePicker(false)
      editor.tf.select(nodePath)
      editor.tf.focus()
    }

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={block}
        className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
          ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block video-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
          ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          } 
        `}
        data-plate-selectable
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        {children}
        {
          isReadOnly
            ? block.image_url ? <ImageResizer element={block} controlsVisible={false} onImageSizeChange={onImageSizeChange} goToLink={goToLink} /> : null
            : block.image_url
              ? <ImageResizer element={block} controlsVisible={openState} onImageSizeChange={onImageSizeChange} goToLink={goToLink} />
              : <ImagePlaceholder element={block} openPicker={setShowImagePicker.bind(setShowImagePicker, true)} />
        }
        <Menubar
          showTrigger={openState}
          block={block}
          onPickerOpen={setShowImagePicker.bind(setShowImagePicker, true)}
          goToLink={goToLink}
        />
        <ImagePicker
          currentImageUrl={block.image_url as string}
          backgroundImageSource={block.image_source as string}
          modalOpen={showImagePicker}
          onImageChange={setImageFromPicker}
          onImageUnset={onImageUnset}
          onModalClose={onModalClose}
          onProgrammaticImageSet={onProgrammaticImageSet}
          brandImages={!isTemplate}
        />
      </PlateElement>
    )
  }
);

export default ImageVoidElement
