import React from 'react';
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from '@udecode/plate/react';

import BlockMenubarTrigger from '../../../shared/BlockMenubarControls/BlockMenubarTrigger';
import BlockMenubarContent from '../../../shared/BlockMenubarControls/BlockMenubarContent';
import BlockMenubarOptionList from '../../../shared/BlockMenubarControls/BlockMenubarOptionList';

type Props = {
  value?: string;
}

const FontSize: React.FC<Props> = ({ value }) => {
  const editor = useEditorRef()
  const marks = editor.api.marks()

  const onSizeChange = (font_size: string) => {
    editor.tf.addMarks({ ['font_size']: font_size });
    editor.tf.select(editor.selection!);
    editor.tf.focus();
  }

  const size = (marks?.font_size || 'medium') as string
  const sizeOptions: any = [
    { key: "small", value: "Small", iconName: "sizeSmall" },
    { key: "medium", value: "Medium", iconName: "sizeMedium" },
    { key: "large", value: "Large", iconName: "sizeLarge" }
  ]
  return (
    <MenuBar.Menu value="float_size">
      <BlockMenubarTrigger
        isDropdown
        label={sizeOptions.find(item => item.key === size)?.value}
        iconBeforeLabel={sizeOptions.find(item => item.key === size)?.iconName}
        isOpened={value === 'float_size'}
        tooltip="Font Size"
        containerId="floating-toolbar"
      />
      <MenuBar.Portal>
        <BlockMenubarContent>
          <BlockMenubarOptionList
            data={sizeOptions}
            onOptionClick={onSizeChange}
            activeKey={size}
          />
        </BlockMenubarContent>
      </MenuBar.Portal>
    </MenuBar.Menu>
  )
}

export default FontSize;
