import React, { useState, useEffect, useRef } from 'react';
import { withRef } from '@udecode/cn';
import { PlateElement, useReadOnly, useSelected } from '@udecode/plate/react';

import { PlateSectionElement } from '../../plate-config/Plugins/Section/Section.plugin';
import { colorClassToRGB } from '../../utils/color.util';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import SectionChildWrapper from './SectionChildWrapper/SectionChildWrapper';
import SectionToolbar from './SectionToolbar/SectionToolbar';
import BottomToolbar from './BottomToolbar/BottomToolbar';
import { nonSlashCommandsBlocks } from '../../utils/plate.util';
import { createDefaultParagraphElement } from '../../plate-config/Plugins/DefaultMockups/DefaultMockups';

export const SECTION_PADDING_CONFIG: { [key: string]: { [key: string]: string } } = {
  laptop: {
    adjustable: 'px-[138px]  py-[80px]',
    full_width: 'px-[20px]  py-[80px]',
  },
  tablet: {
    adjustable: 'px-[40px]  py-[52px]',
    full_width: 'px-[20px]  py-[80px]',
  },
  mobile: {
    adjustable: 'px-[20px]  py-[52px]',
    full_width: 'px-[20px]  py-[80px]',
  },
}

export interface SectionStyleTypes extends React.CSSProperties {
  '--section_plugin-background-color': string;
  '--section_plugin-background-image'?: string;
  '--section_plugin-background-filter'?: string;
  '--section_plugin-background-transform'?: string;
  '--section_plugin-background-blend-mode'?: string;
}

const SectionElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const section = element as PlateSectionElement

    const isReadOnly = useReadOnly();
    const { activePalette } = useAppSelector(state => state.page_colors)
    const editorContainerWidth = useAppSelector((state) => state.page_addendums.editorContainerWidth);
    const [bgImageHeight, setBgImageHeight] = useState(0);
    const selected = useSelected();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (section.background_image_url && section.image_size === "full_screen") {
        const img = new Image();
        img.onload = () => setBgImageHeight(img.naturalHeight)
        img.src = section.background_image_url as string;
      } else (
        setBgImageHeight(0)
      )
    }, [section]);

    const node = editor.api.node({ at: [], match: { id: section.id } })!
    const sectionWidth = section.style || 'adjustable';
    const sectionStyles: SectionStyleTypes = {
      '--section_plugin-background-color': colorClassToRGB(section.tint_color as string, section.tint_opacity as number, activePalette.colors)!,
      ...(section.background_image_url
        ? {
          '--section_plugin-background-image': `url(${section.background_image_url})`,
          '--section_plugin-background-blend-mode': section.tint_kind === "regular" ? 'normal' : 'overlay',
        }
        : {}),
      ...(section.background_image_url && section.image_size === "full_screen" ? { minHeight: `${bgImageHeight}px` } : {}),
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isReadOnly) return
      if (!containerRef.current) return;
      if (!!section.card_color) return

      const target = e.target as HTMLElement;
      const dataId = target.getAttribute('data-id');
      if (dataId === 'section-bottom-toolbar') {
        e.preventDefault();
        return
      }

      const style = window.getComputedStyle(containerRef.current);
      const paddingBottom = parseInt(style.paddingBottom, 10);
      const rect = containerRef.current.getBoundingClientRect();
      const bottomEdge = rect.bottom;
      const clickYPosition = e.clientY;
      if (clickYPosition > (bottomEdge - paddingBottom)) {
        const lastSectionChild = section.children[section.children.length - 1];
        const lastSectionChildType = lastSectionChild.type as string
        if (nonSlashCommandsBlocks.includes(lastSectionChildType)) {
          editor.tf.insertNodes(createDefaultParagraphElement(), { at: [...node[1], section.children.length], select: true });
        }
      }
      return
    };

    return (
      <PlateElement
        ref={ref}
        id={section.id}
        data-section-id={section.id}
        editor={editor}
        element={section}
        {...props}
      >
        {/* This wrapper is needed to handle trailing block system. We can use parent, it has it's own ref provided by PlateJS.*/}
        <div
          className={`${className} section_plugin_wrapper group/section ${SECTION_PADDING_CONFIG[editorContainerWidth][sectionWidth]}`}
          style={sectionStyles}
          onClick={handleClick}
          ref={containerRef}
        >
          {!isReadOnly &&
            <>
              <SectionToolbar showTrigger={selected} sectionElement={section} />
              <BottomToolbar sectionElement={section} selected={selected} />
            </>
          }
          <SectionChildWrapper section={section} children={children} />
        </div>
      </PlateElement>
    )
  })

export default SectionElement
