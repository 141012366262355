import React, {
  type HTMLAttributes, type ReactNode, type RefObject,
  createContext, forwardRef, startTransition,
  useCallback, useContext, useEffect,
  useMemo, useState,
} from 'react';
import type { PointRef } from 'slate';
import {
  Combobox, ComboboxItem, type ComboboxItemProps,
  ComboboxPopover, ComboboxProvider, Portal,
  useComboboxContext, useComboboxStore,
} from '@ariakit/react';
import { cn, useComposedRef } from '@udecode/cn';
import { type UseComboboxInputResult, useComboboxInput, useHTMLInputCursorState } from '@udecode/plate-combobox/react';
import { cva } from 'class-variance-authority';
import { filterWords } from '@udecode/plate-combobox';
import { TElement } from '@udecode/plate';
import { useEditorRef } from '@udecode/plate/react';

type FilterFn = (
  item: { keywords?: string[]; value: string },
  search: string
) => boolean;

interface InlineComboboxContextValue {
  filter: FilterFn | false;
  inputProps: UseComboboxInputResult['props'];
  inputRef: RefObject<HTMLInputElement>;
  removeInput: UseComboboxInputResult['removeInput'];
  setHasEmpty: (hasEmpty: boolean) => void;
  showTrigger: boolean;
  trigger: string;
}

const InlineComboboxContext = createContext<InlineComboboxContextValue>(
  null as any
);

export const defaultFilter: FilterFn = ({ keywords = [], value }, search) =>
  [value, ...keywords].some((keyword) => filterWords(keyword, search));

interface InlineComboboxProps {
  children: ReactNode;
  element: TElement;
  trigger: string;
  filter?: FilterFn | false;
  hideWhenNoValue?: boolean;
  setValue?: (value: string) => void;
  showTrigger?: boolean;
  value?: string;
}

const InlineCombobox = ({
  children, element, filter = defaultFilter, hideWhenNoValue = false,
  setValue: setValueProp, showTrigger = true, trigger, value: valueProp,
}: InlineComboboxProps) => {
  const editor = useEditorRef();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const cursorState = useHTMLInputCursorState(inputRef);

  const [valueState, setValueState] = useState('');
  const hasValueProp = valueProp !== undefined;
  const value = hasValueProp ? valueProp : valueState;

  const setValue = useCallback(
    (newValue: string) => {
      setValueProp?.(newValue);
      if (!hasValueProp) {
        setValueState(newValue);
      }
    },
    [setValueProp, hasValueProp]
  );
  const [insertPoint, setInsertPoint] = useState<PointRef | null>(null);
  useEffect(() => {
    const path = editor.api.findPath(element);
    if (!path) return;
    const point = editor.api.before(path);
    if (!point) return;
    const pointRef = editor.api.pointRef(point);
    setInsertPoint(pointRef);
    return () => {
      pointRef.unref();
    };
  }, [editor, element]);

  const { props: inputProps, removeInput } = useComboboxInput({
    cancelInputOnBlur: false,
    cursorState,
    cancelInputOnEscape: true,
    onCancelInput: (cause) => {
      if (cause !== 'backspace') {
        editor.tf.insertText(trigger + value, {
          at: insertPoint?.current ?? undefined,
        });
      }
      if (cause === 'arrowLeft' || cause === 'arrowRight') {
        editor.tf.move({
          distance: 1,
          reverse: cause === 'arrowLeft',
        });
      }
    },
    ref: inputRef,
  });
  const [hasEmpty, setHasEmpty] = useState(false);
  const contextValue: InlineComboboxContextValue = useMemo(
    () => ({ filter, inputProps, inputRef, removeInput, setHasEmpty, showTrigger, trigger, }),
    [trigger, showTrigger, filter, inputRef, inputProps, removeInput, setHasEmpty]
  );
  const store = useComboboxStore({ setValue: (newValue) => startTransition(() => setValue(newValue)), });
  const items = store.useState('items');
  useEffect;
  useEffect(() => {
    if (!store.getState().activeId) {
      store.setActiveId(store.first());
    }
  }, [items, store]);

  return (
    <span contentEditable={false}>
      <ComboboxProvider open={(items.length > 0 || hasEmpty) && (!hideWhenNoValue || value.length > 0)} store={store} >
        <InlineComboboxContext.Provider value={contextValue}>
          {children}
        </InlineComboboxContext.Provider>
      </ComboboxProvider>
    </span>
  );
};

interface Additional extends HTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

const InlineComboboxInput = forwardRef<HTMLInputElement, Additional>(({ className, ...props }, propRef) => {
  const { inputProps, inputRef: contextRef, showTrigger, trigger } = useContext(InlineComboboxContext);
  const store = useComboboxContext()!;
  const value = store.useState('value');
  const ref = useComposedRef(propRef, contextRef);

  return (
    <>
      {showTrigger && trigger}
      <span className="relative min-h-[1lh]">
        <span
          aria-hidden="true"
          className="invisible overflow-hidden text-nowrap"
          style={{ fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit' }}
        >
          {value || props.placeholder || '\u200B'}
        </span>
        <Combobox
          autoSelect
          className={cn('absolute left-0 top-0 size-full bg-transparent p-0 m-0 placeholder:brand-subtext', className)}
          placeholder={props.placeholder}
          style={{
            boxShadow: 'none', border: 'none', outline: 'none',
            fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit'
          }}
          ref={ref}
          value={value}
          {...inputProps}
          {...props}
        />
      </span>
    </>
  );
});

const InlineComboboxEmojiInput = forwardRef<HTMLInputElement, HTMLAttributes<HTMLInputElement>>(({ className, ...props }, propRef) => {
  const { inputProps, inputRef: contextRef, showTrigger, trigger } = useContext(InlineComboboxContext);
  const store = useComboboxContext()!;
  const value = store.useState('value');
  const ref = useComposedRef(propRef, contextRef);

  return (
    <>
      {showTrigger && trigger}
      <span className="relative min-h-[1lh]">
        <span aria-hidden="true" className="invisible overflow-hidden text-nowrap"  >
          {value || '\u200B'}
        </span>
        <Combobox
          autoSelect
          disabled={true}
          className={cn('absolute left-0 top-0 size-full bg-transparent p-0 m-0 text-sm', className)}
          style={{ boxShadow: 'none', border: 'none', outline: 'none' }}
          ref={ref}
          value={value}
          {...inputProps}
          {...props}
        />
      </span>
    </>
  );
});

InlineComboboxInput.displayName = 'InlineComboboxInput';

const InlineComboboxContent: typeof ComboboxPopover = ({ className, ...props }) =>
  <Portal>
    <ComboboxPopover className={cn('z-[500] overflow-y-auto rounded-lg bg-white shadow-md', className)}  {...props} />
  </Portal>


const comboboxItemVariants = cva(
  'relative flex select-none items-center py-2 px-4 text-sm outline-none brand-text gap-[6px] transition-colors',
  {
    defaultVariants: { interactive: true },
    variants: {
      interactive: {
        false: '',
        true: 'cursor-pointer transition-colors hover:bg-gray-100 data-[active-item=true]:bg-gray-100',
      },
    },
  }
);

export type InlineComboboxItemProps = {
  keywords?: string[];
} & ComboboxItemProps &
  Required<Pick<ComboboxItemProps, 'value'>>;

const InlineComboboxItem = ({ className, keywords, onClick, ...props }: InlineComboboxItemProps) => {
  const { value } = props;
  const { filter, removeInput } = useContext(InlineComboboxContext);
  const store = useComboboxContext()!;
  const search = filter && store.useState('value');
  const visible = useMemo(
    () => !filter || filter({ keywords, value }, search as string),
    [filter, value, keywords, search]
  );
  if (!visible) return null;

  return (
    <ComboboxItem
      className={cn(comboboxItemVariants(), className)}
      onClick={(event) => { removeInput(true); onClick?.(event); }}
      {...props}
    />
  );
};

export type InlineComboboxEmojiItemProps = {
  keywords?: string[];
} & ComboboxItemProps &
  Required<Pick<ComboboxItemProps, 'value'>>;

const InlineEmojiComboboxItem = ({ className, keywords, onClick, ...props }: InlineComboboxEmojiItemProps) => {
  const { value } = props;
  const { filter } = useContext(InlineComboboxContext);
  const store = useComboboxContext()!;
  const search = filter && store.useState('value');
  const visible = useMemo(
    () => !filter || filter({ keywords, value }, search as string),
    [filter, value, keywords, search]
  );
  if (!visible) return null;

  return (
    <ComboboxItem
      className={cn(comboboxItemVariants(), className)}
      onClick={(event) => { event.preventDefault(); }}
      {...props}
    />
  );
};


const InlineComboboxEmpty = ({ children, className }: HTMLAttributes<HTMLDivElement>) => {
  const { setHasEmpty } = useContext(InlineComboboxContext);
  const store = useComboboxContext()!;
  const items = store.useState('items');
  useEffect(() => {
    setHasEmpty(true);
    return () => {
      setHasEmpty(false);
    };
  }, [setHasEmpty]);
  if (items.length > 0) return null;

  return (
    <div className={cn(comboboxItemVariants({ interactive: false }), className)} >
      {children}
    </div>
  );
};

export {
  InlineCombobox, InlineComboboxContent, InlineComboboxEmpty, InlineComboboxInput,
  InlineComboboxItem, InlineEmojiComboboxItem, InlineComboboxEmojiInput,
};
