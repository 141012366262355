import React, { useCallback } from 'react';
import ahoy from "ahoy.js"
import { useEditorRef } from '@udecode/plate/react';

import { PlateButtonElement } from '../../../plate-config/Plugins/Button/Button.plugin';
import { useAppSelector } from '../../../store/hooks/redux-hooks';


type Props = {
  block: PlateButtonElement
  bgColor: string | null
  textColor: string,
  goToLink: string | null
  activeSize: string
}

const ReadOnlyButton: React.FC<Props> = ({ block, bgColor, textColor, goToLink, activeSize }) => {
  const editor = useEditorRef()
  const account_id = useAppSelector(state => state.page_addendums.account_id)
  const current_page_id = useAppSelector(state => state.page_navigation.current_page_id)
  const analyticsEnabled = useAppSelector(state => state.page_side_effects.analyticsEnabled)

  const elementNode = editor.api.node({ at: [], match: (n: any) => n.id === block.id })!;
  const section = editor.children[elementNode[1][0]]

  const trackBtnClick = useCallback((href: string) => {
    if (!analyticsEnabled) return
    return ahoy.track("page action", {
      page_id: current_page_id, section_id: section.id, block_id: block.id,
      type: "button_click", button_text: block.content, account_id, href,
    });
  }, [current_page_id, section, block]);

  return (
    <a
      href={goToLink ? goToLink : undefined}
      style={{
        ...(bgColor &&
        {
          backgroundColor: bgColor,
          borderColor: (bgColor === 'rgba(255, 255, 255, 1)' || bgColor === '#ffffff') ? "#333333" : bgColor,
          borderStyle: 'solid'
        })
      }}
      className={`
        outline-none border-none focus:border-none focus:outline-none focus:shadow-none 
        focus:drop-shadow-none focus:ring-0 rounded-2xl font-medium text-base text-center
        min-w-[125px] justify-center border hover:${textColor} flex 
        ${textColor} ${!bgColor ? 'bg-[#333333] border-[#333333]' : ''}
        ${activeSize === 'small' ? 'px-4 py-[10px] h-10 text-sm' : ''}
        ${activeSize === 'medium' ? 'px-5 py-3 text-base h-12' : ''}
        ${activeSize === 'large' ? 'px-6 py-[18px] text-base h-[60px]' : ''}
    `}
      onClick={trackBtnClick.bind(trackBtnClick, goToLink)}
      {...(block.link_type === 'external' || block.link_type === 'resource')
        ? { target: "_blank", rel: "noopener noreferrer" }
        : { target: "_self" }
      }
    >
      {block.content}
    </a>
  );
}

export default ReadOnlyButton;