import React from 'react';
import { useDrag } from 'react-dnd'

import PhIcon, { PhIconName } from '../../../shared/Icon/PhIcon';

type Props = {
  id: string;
  label: string;
  iconName: PhIconName;
  enabled?: boolean
}

const FixedToolbarButton: React.FC<Props> = ({ id, label, iconName, enabled = true }) => {

  const [{ isDragging }, dragRef] = useDrag({
    type: 'fixed-toolbar-nodes',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  if (enabled === false) return null

  return (
    <div
      className={`${isDragging ? 'opacity-50' : ''} group/fixedDnD
      cursor-grab rounded-lg hover:bg-gray-900 p-2 flex items-center justify-between w-full h-8 gap-[6px]`}
      ref={dragRef}
    >
      <div className='flex gap-[6px]'>
        <div><PhIcon name={iconName} size={18} className="text-gray-300 group-hover/fixedDnD:text-white" /></div>
        <span className='brand-font-family text-gray-300 text-sm group-hover/fixedDnD:text-white text-nowrap' >{label}</span>
      </div>
      <PhIcon name='dotsSixVertical' weight="regular" size={18} className="text-white opacity-0 group-hover/fixedDnD:opacity-100" />
    </div>
  );
}

export default FixedToolbarButton;