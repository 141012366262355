import React, { memo } from "react"

import ScrollableDialog from "../../../shared/ScrollableDialog/ScrollableDialog";
import PhIcon from "../../../shared/Icon/PhIcon";

type Props = {
  onModalClose: () => void;
  modalOpen: boolean;
}

const Dialog = memo(({ onModalClose, modalOpen }: Props) => {

  return (
    <ScrollableDialog
      dialogOpen={modalOpen}
      onDialogClose={onModalClose}
      scrollableContent={
        <section className="flex flex-col p-8">
          <div className="flex w-full justify-between">
            <div><PhIcon name="confetti" /></div>
            <div className="flex justify-center items-center">
              <PhIcon name="checkCircle" size={56} weight='fill' color='#22C55D' />
            </div>
            <div><PhIcon name="confetti" /></div>
          </div>
          <div className="flex flex-col gap-2 mt-2 justify-center items-center">
            <h2
              className="brand-text text-center cursor-default"
              style={{ fontSize: '28px', fontWeight: 700 }}
            >
              You’re Subscribed!
            </h2>
            <p className="text-center text-gray-700 text-base cursor-default">
              Thank you for joining us! Stay tuned for updates, exclusive content, and exciting news delivered straight to your inbox.
            </p>
          </div>
          <button
            type='button'
            onClick={onModalClose}
            className="brand_btn-secondary w-full mt-6">
            Close
          </button>
        </section>
      }
      scrollableContentId="scrollable-dialog-content-id"
      dialogStyles={{ margin: 'auto' }}
      sxClassess={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "560px",
            maxWidth: "100%",
            height: '350px',
            maxHeight: '100%',
            margin: '0',
          },
          "& .MuiDialog-paper": {
            borderRadius: "12px",
            boxShadow: "0px 8px 24px 0px #00000026",
            background: '#FFFFFF'
          },
          "& .MuiDialogContent-root": {
            padding: "0",
            borderTop: "unset",
            borderBottom: "unset",
          },
        },
      }}
    />
  )
})

export default Dialog