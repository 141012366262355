import React from 'react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { createNodesHOC, ParagraphPlugin, PlaceholderProps, useEditorRef, usePlaceholderState, useReadOnly } from '@udecode/plate/react';

import { useAppSelector } from '../../store/hooks/redux-hooks';
import { textColorFromColorClass } from '../../utils/color.util'
import { ParagraphPlaceholder } from './ParagraphPlaceholder/ParagraphPlaceholder';


export const Placeholder = (props: PlaceholderProps) => {
  const { children, placeholder, nodeProps } = props;

  const { enabled } = usePlaceholderState(props);
  const editor = useEditorRef()
  const readOnlyMode = useReadOnly()
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)


  return React.Children.map(children, (child) => {

    const childrenElId = children?.props?.element?.id
    const nodePath = childrenElId ? editor.api.node({ at: [], match: { id: childrenElId } })![1] : null
    const activeSectionCardColor = nodePath ? editor.children[nodePath[0]]?.card_color : null
    const activeSectionBgColor = nodePath ? editor.children[nodePath[0]]?.tint_color : null
    const bgColour = activeSectionCardColor ? activeSectionCardColor : activeSectionBgColor
    const textColor = textColorFromColorClass(bgColour as string, colors)

    const isParagraph = children?.props?.element?.type === ParagraphPlugin.key
    const isBlockquote = children?.props?.element?.type === BlockquotePlugin.key

    if (enabled && !readOnlyMode) {
      return <>
        {
          isParagraph
            ?
            <>
              {React.cloneElement(child, {
                className: child.props.className,
                nodeProps: {
                  ...nodeProps,
                  className: `relative`,
                },
              })}
              <ParagraphPlaceholder textColor={textColor} />
            </>
            :
            <>
              {React.cloneElement(child, {
                className: child.props.className,
                nodeProps: {
                  ...nodeProps,
                  className: `
              before:absolute before:cursor-text before:opacity-50
              before:content-[attr(placeholder)] before:font-medium
              ${textColor === 'black' ? 'before:text-black' : ""}
              ${textColor === 'white' ? 'before:text-white' : ""}
              ${isBlockquote ? 'before:pl-4' : ""}
               `,
                  placeholder,
                },
              })}
            </>
        }
      </>
    }

    return React.cloneElement(child, {
      ...child.props,
      nodeProps: {
        ...nodeProps,
      },
    });
  });
};

export const withPlaceholdersPrimitive = createNodesHOC(Placeholder);

export const withPlaceholders = (components: any) =>
  withPlaceholdersPrimitive(components, [
    {
      key: ParagraphPlugin.key,
      placeholder: 'Type / for commands',
      hideOnBlur: true,
    },
    {
      key: HEADING_KEYS.h1,
      placeholder: 'Heading 1',
      hideOnBlur: true,
    },
    {
      key: HEADING_KEYS.h2,
      placeholder: 'Heading 2',
      hideOnBlur: true,
    },
    {
      key: BlockquotePlugin.key,
      placeholder: 'Blockquote',
      hideOnBlur: true,
    },
  ]);