import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDrop } from 'react-dnd';
import { withRef } from '@udecode/cn';
import { isType } from '@udecode/plate';
import { type NodeWrapperComponent, type PlateRenderElementProps, MemoizedChildren, useReadOnly, useEditorRef } from '@udecode/plate/react';

import {
  createDefaultBlockQuoteElement,
  createDefaultH1Element,
  createDefaultH2Element,
  createDefaultParagraphElement,
  createDefaultOlElement,
  createDefaultUlElement
} from "../../plate-config/Plugins/DefaultMockups/DefaultMockups";
import { createDefaultImageElement } from "../../plate-config/Plugins/Image/Image.plugin";
import { createDefaultVideoElement } from "../../plate-config/Plugins/Video/Video.plugin";
import { createDefaultEmbedElement } from "../../plate-config/Plugins/Embed/Embed.plugin";
import { createDefaultDashedHrElement, createDefaultHrElement } from "../../plate-config/Plugins/HrLine/HrLine.plugin";
import {
  createDefaultButtonGroupElement,
  createDefaultButtonGroupWith2BtnElement,
  createDefaultButtonGroupWith3BtnElement
} from "../../plate-config/Plugins/Button/ButtonGroup.plugin";
import { createTwoColumnGroupElement, createThreeColumnsElement, createFourColumnsElement } from "../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin";
import { createDefaultTestimonialGroupElement } from "../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import { createDefaultCardGroupElement } from "../../plate-config/Plugins/CardGroup/CardGroup.plugin";
import { createDefaultSubscribeToNewsletterElement } from "../../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin";
import { UNDRAGGABLE_KEYS } from "./DraggableAboveNodes";

export interface FixedDraggableItem {
  id: string;
}

export const FixedMenuDraggables: NodeWrapperComponent = (props) => {
  const { editor, element, path } = props;
  const readOnly = useReadOnly();
  const enabled = useMemo(() => {
    if (readOnly) return false;
    // First level nodes are sections, so it will be always false
    if (path.length === 1) {
      return false;
    }
    // All second level nodes are draggable 
    if (path.length === 2 && !isType(editor, element, UNDRAGGABLE_KEYS)) {
      return true;
    }
    // Third level nodes are group elements
    if (path.length === 3 && !isType(editor, element, UNDRAGGABLE_KEYS)) {
      return true;
    }
    // only column childs, so they should be draggable
    if (path.length === 4 && !isType(editor, element, UNDRAGGABLE_KEYS)) {
      return true
    }
    return false;
  }, [editor, element, path, readOnly]);
  if (!enabled) return;
  return (props) => <Draggable {...props} />;
};

export const Draggable = withRef<'div', PlateRenderElementProps>(
  ({ className, ...props }, ref) => {

    const divRef = useRef<HTMLDivElement>(null);
    const [hoverPosition, setHoverPosition] = useState<'above' | 'below' | ''>('');
    const editor = useEditorRef()

    const editorDataProps = props as any
    const node = editor.api.node({ at: [], match: { id: editorDataProps.element.id as string } })!;
    const nodePathBelow = [...node[1]];
    nodePathBelow[nodePathBelow.length - 1] += 1;

    // NOTE: id should be taken from fixed toolbar.
    const onAddItem = (item: FixedDraggableItem, position: 'above' | 'below') => {
      const path = { at: position === 'above' ? node[1] : nodePathBelow, select: true }
      switch (item.id) {
        case 'paragraph':
          editor.tf.insertNodes(createDefaultParagraphElement(), path);
          break;
        case 'heading1':
          editor.tf.insertNodes(createDefaultH1Element(), path);
          break;
        case 'heading2':
          editor.tf.insertNodes(createDefaultH2Element(), path);
          break;
        case 'blockquote':
          editor.tf.insertNodes(createDefaultBlockQuoteElement(), path);
          break;
        case 'image':
          editor.tf.insertNodes(createDefaultImageElement(), path);
          break;
        case 'video':
          editor.tf.insertNodes(createDefaultVideoElement(), path);
          break;
        case 'embed':
          editor.tf.insertNodes(createDefaultEmbedElement(), path);
          break;
        case 'bulletList':
          editor.tf.insertNodes(createDefaultUlElement(), path);
          break;
        case 'numberedList':
          editor.tf.insertNodes(createDefaultOlElement(), path);
          break;
        case '2columns':
          editor.tf.insertNodes(createTwoColumnGroupElement(), path);
          break;
        case '3columns':
          editor.tf.insertNodes(createThreeColumnsElement(), path);
          break;
        case '4columns':
          editor.tf.insertNodes(createFourColumnsElement(), path);
          break;
        case 'hr':
          editor.tf.insertNodes(createDefaultHrElement(), path);
          break;
        case 'hr-dashed':
          editor.tf.insertNodes(createDefaultDashedHrElement(), path);
          break;
        case 'button':
          editor.tf.insertNodes(createDefaultButtonGroupElement(), path);
          break;
        case '2buttons':
          editor.tf.insertNodes(createDefaultButtonGroupWith2BtnElement(), path);
          break;
        case '3buttons':
          editor.tf.insertNodes(createDefaultButtonGroupWith3BtnElement(), path);
          break;
        case 'testimonials':
          editor.tf.insertNodes(createDefaultTestimonialGroupElement(), path);
          break;
        case 'cards':
          editor.tf.insertNodes(createDefaultCardGroupElement(), path);
          break;
        case 'subscribe_to_newsletter':
          editor.tf.insertNodes(createDefaultSubscribeToNewsletterElement(), path);
          break;
        default:
          break;
      }
    }

    const [{ isOver }, dropRef] = useDrop({
      accept: 'fixed-toolbar-nodes',
      drop: (item: FixedDraggableItem, monitor) => {
        const clientOffset = monitor.getClientOffset();
        const componentRect = divRef.current?.getBoundingClientRect();
        if (clientOffset && componentRect) {
          const dropYRelativePosition: number = clientOffset.y - componentRect.top;
          const isAboveCenter: boolean = dropYRelativePosition < componentRect.height / 2;
          onAddItem(item, isAboveCenter ? 'above' : 'below');
        }
      },
      hover: (_item, monitor) => {
        const clientOffset = monitor.getClientOffset();
        const componentRect = divRef.current?.getBoundingClientRect();
        if (clientOffset && componentRect) {
          const hoverYRelativePosition = clientOffset.y - componentRect.top;
          const isHoverAboveCenter = hoverYRelativePosition < componentRect.height / 2;
          setHoverPosition(isHoverAboveCenter ? 'above' : 'below');
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver()
      }),
    });

    useEffect(() => {
      dropRef(divRef);
    }, [dropRef]);



    return (
      <div ref={divRef} className="relative" >
        {isOver && !!hoverPosition &&
          <div
            className="relative bg-transparent h-[2px] overflow-hidden my-2"
            contentEditable={false}
            suppressContentEditableWarning={true}
          >
            <div
              className={`
                        absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                        ${hoverPosition === 'above' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                        ${hoverPosition === 'below' ? "h-[4px] scale-x-0 opacity-0" : " "}
                      `}
            />
          </div>}
        <MemoizedChildren>{props.children}</MemoizedChildren>
        {isOver && !!hoverPosition &&
          <div
            className="relative bg-transparent h-[2px] overflow-hidden my-2"
            contentEditable={false}
            suppressContentEditableWarning={true}
          >
            <div
              className={`
                        absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                        ${hoverPosition === 'below' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                        ${hoverPosition === 'above' ? "h-[4px] scale-x-0 opacity-0" : " "}
                        `}
            />
          </div>}
      </div>
    )

  }
);