import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { HEADING_KEYS } from "@udecode/plate-heading";
import { createPlatePlugin } from '@udecode/plate/react';
import { Descendant } from '@udecode/plate';

import { ColumnGroupPlugin, PlateColumnGroupElement } from "../ColumnGroup/ColumnGroup.plugin";
import { PlateVideoElement, VideoPlugin } from "../Video/Video.plugin";
import { EmbedPlugin, PlateElementEmbed } from "../Embed/Embed.plugin";
import { AiTextBlockPlugin, PlateElementAiTextBlock } from "../AiTextBlock/AiTextBlock.plugin";
import { HorizontalRulePlugin, PlateHrElement } from "../HrLine/HrLine.plugin";
import { PlateSectionElement, SectionPlugin } from "../Section/Section.plugin";
import { ColumnPlugin, PlateColumnElement } from "../ColumnGroup/Column.plugin";


export const NormalizePlugin = createPlatePlugin({
  key: 'normalize',
  // This is a part of editor functionality that process data before showing it to user
  // This is the right place to make hot fixes for incorrect data that comes from API
  // After v41 normalizeInitialValue returning void instead of new value, so value data 
  // should be changed only with editor transform operations
  normalizeInitialValue: ({ editor }) => {
    // Remove nodes that are not sections (first level elements should only be sections)
    editor.children.map((firstLevelNode) => {
      if (firstLevelNode.type !== SectionPlugin.key) {
        editor.tf.removeNodes({ at: [editor.children.indexOf(firstLevelNode)] })
      }
    })
    // Add missing default properties to sections (in case if editor will create new section or API will return section without some properties)
    editor.children.map((node) => {
      if (node.type === SectionPlugin.key) {
        const section = node as PlateSectionElement
        editor.tf.setNodes({
          padding: null,
          animationCount: section.animationCount || 0,
          tint_opacity: typeof section.tint_opacity === 'number' ? section.tint_opacity : 50,
          background_blur: typeof section.background_blur === 'number' ? section.background_blur : 0,
          style: section.style || (node.width === 'large' ? 'full_width' : 'adjustable'),
          width: null,
          tint_kind: section.tint_kind || 'regular',
          image_size: section.image_size || 'adjustable',
          animate_type: section.animate_type || 'no-animation',
          ...(section.animate_type !== 'no-animation' && {
            animate_direction: section.animate_direction || 'right',
            animate_speed: section.animate_speed || 'medium',
            animate_style: section.animate_style || 'fade',
          })
        }, { at: [], match: (node: any) => node.id === section.id })
      }
    })

    // Minor changes to section childs:
    editor.children.map((node) => {
      if (node.type === SectionPlugin.key) {
        const section = node as PlateSectionElement
        section.children.map((sectionChild) => {

          // new prop
          if (sectionChild.type === VideoPlugin.key) {
            const videoElement = sectionChild as PlateVideoElement;
            editor.tf.setNodes({ width: videoElement.width || '100%' }, { at: [], match: (node: any) => node.id === videoElement.id })
          }

          // new prop
          if (sectionChild.type === EmbedPlugin.key) {
            const embedElement = sectionChild as PlateElementEmbed;
            editor.tf.setNodes({ width: embedElement.width || '100%' }, { at: [], match: (node: any) => node.id === embedElement.id })
          }

          // new prop
          if (sectionChild.type === HorizontalRulePlugin.key) {
            const hrElement = sectionChild as PlateHrElement;
            editor.tf.setNodes({ style: hrElement.style || 'straight' }, { at: [], match: (node: any) => node.id === hrElement.id })
          }

          // could be in some old data
          if (sectionChild.type === 'h') {
            editor.tf.setNodes({ type: HEADING_KEYS.h1 }, { at: [], match: (node: any) => node.id === sectionChild.id })
          }

          // col group style prop migration, save old width prop from col childs
          if (sectionChild.type === ColumnGroupPlugin.key) {
            const colGroup = sectionChild as PlateColumnGroupElement
            // Should respect only structure of string with 1-3 slashes and not === to layoutConfigStyleTypes
            const stylePropCheck = !colGroup.style || !/^\d+(\/\d+){1,3}$/.test(colGroup.style)
            if (stylePropCheck) {
              // Save prev width values and use them as default
              editor.tf.setNodes(
                { style: [...colGroup.children].map(colChild => Math.round(colChild.width as number)).join('/') },
                { at: [], match: (node: any) => node.id === colGroup.id }
              )
            }
            // column cleanup, drop width prop, it's no longer used
            colGroup.children.map((node) => {
              if (node.type === ColumnPlugin.key) {
                const colEl = node as PlateColumnElement;
                editor.tf.setNodes({ width: null }, { at: [], match: (node: any) => node.id === colEl.id })
              }
            })
          }

          // fix for ai text block list items min/max values ( user had ability to save incorrect values for list items min/max )
          if (sectionChild.type === AiTextBlockPlugin.key) {
            const block = sectionChild as PlateElementAiTextBlock
            if (block.details?.kind === "list") {
              if (block.details.list_items_min !== null && block.details.list_items_max !== null) {
                if (block.details.list_items_min > block.details.list_items_max) {
                  editor.tf.setNodes(
                    { details: { ...block.details, list_items_max: block.details.list_items_max + 1, list_items_min: block.details.list_items_max } },
                    { at: [], match: (node: any) => node.id === block.id })
                }
              }
            }
          }

          // fix for ai text block list items min/max values ( user had ability to save incorrect values for list items min/max )
          // in case it's nested in column group
          if (sectionChild.type === ColumnGroupPlugin.key) {
            const colGroup = sectionChild as PlateColumnGroupElement
            colGroup.children.map((node) => {
              if (node.type === ColumnPlugin.key) {
                const colEl = node as PlateColumnElement;
                colEl.children.map((node) => {
                  if (node.type === AiTextBlockPlugin.key) {
                    const block = node as PlateElementAiTextBlock
                    if (block.details?.kind === "list") {
                      if (block.details.list_items_min !== null && block.details.list_items_max !== null) {
                        if (block.details.list_items_min > block.details.list_items_max) {
                          editor.tf.setNodes(
                            { details: { ...block.details, list_items_max: block.details.list_items_max + 1, list_items_min: block.details.list_items_max } },
                            { at: [], match: (node: any) => node.id === block.id })
                        }
                      }
                    }
                  }
                })
              }
            })
          }
        })
      }
    })
  }
}).overrideEditor(({ tf: { insertFragment } }) => {
  return {
    transforms: {
      // This is the place where we can get data before it will be inserted to editor, used to handle copy/paste operations
      insertFragment(fragment, options) {
        const fragmentToAdd = [...fragment]
        // We can't actually copy/paste sections, but we can copy/paste elements inside sections
        // So we need to remove sections from the fragment and extract their children
        const fragmentWithoutSections = fragmentToAdd.map(node => {
          if (node.type === SectionPlugin.key) {
            return node.children
          }
          return node
        }).flat() as Descendant[]

        const fragmentWithNormalizedLists = [...fragmentWithoutSections].map((node, i, a) => {
          // Add blank element before list, so editor could normalize it
          if (node?.type === BulletedListPlugin.key || node.type === NumberedListPlugin.key) {
            return [{ text: "" }, node]
          }
          // We do not support h3-h6, so convert them into h2
          if (node?.type === 'h3' || node?.type === 'h4' || node?.type === 'h5' || node?.type === 'h6') {
            return { ...node, type: 'h2' }
          }
          return node
        }).flat() as Descendant[]
        //  we don't need two blank elements in a row
        const fragmentWithBlankElementNormalized = [...fragmentWithNormalizedLists].map((node, i, a) => {
          if (i === 0) {
            return node
          }
          if (!node.type && node?.text === '' && !a[i - 1].type && a[i - 1].text === '') {
            return []
          }
          return node
        }).flat() as Descendant[]
        insertFragment(fragmentWithBlankElementNormalized, options)
      },
    }
  }
})