import { BlockquotePlugin } from "@udecode/plate-block-quote/react"
import { HEADING_KEYS } from "@udecode/plate-heading"
import { ParagraphPlugin } from "@udecode/plate/react";
import { BulletedListPlugin, NumberedListPlugin, ListItemPlugin } from "@udecode/plate-list/react";
import { LinkPlugin } from "@udecode/plate-link/react";
import { Descendant } from "@udecode/plate";

import { AiTextBlockPlugin } from "../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import { ButtonGroupPlugin } from "../plate-config/Plugins/Button/ButtonGroup.plugin";
import { AssessmentPlugin } from "../plate-config/Plugins/Assessment/Assessment.plugin";
import { EmbedPlugin } from "../plate-config/Plugins/Embed/Embed.plugin";
import { HorizontalRulePlugin } from "../plate-config/Plugins/HrLine/HrLine.plugin";
import { ImagePlugin } from "../plate-config/Plugins/Image/Image.plugin";
import { PricingPlugin } from "../plate-config/Plugins/Pricing/Pricing.plugin";
import { VideoPlugin } from "../plate-config/Plugins/Video/Video.plugin";
import { ColumnGroupPlugin } from "../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin";
import { CardGroupPlugin } from "../plate-config/Plugins/CardGroup/CardGroup.plugin";
import { TestimonialGroupPlugin } from "../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import { ColumnPlugin } from "../plate-config/Plugins/ColumnGroup/Column.plugin";
import { ButtonPlugin } from "../plate-config/Plugins/Button/Button.plugin";
import { CardPlugin } from "../plate-config/Plugins/CardGroup/Card.plugin";
import { TestimonialPlugin } from "../plate-config/Plugins/Testimonial/Testimonial.plugin";
import { SubscribeToNewsletterPlugin } from "../plate-config/Plugins/SubscribeToNewsletter/SubscribeToNewsletter.plugin";


export const textNodeTypes = [
  ParagraphPlugin.key, BlockquotePlugin.key, HEADING_KEYS.h1, HEADING_KEYS.h2, NumberedListPlugin.key,
  BulletedListPlugin, ListItemPlugin.key, LinkPlugin.key,
] as string[];

export const nonSlashCommandsBlocks = [
  AiTextBlockPlugin.key, AssessmentPlugin.key, ButtonGroupPlugin.key, EmbedPlugin.key, HorizontalRulePlugin.key,
  // can't say why but ELEMENT_COLUMN_GROUP as a constant is not recognized
  ColumnGroupPlugin.key, 'column-group',
  ImagePlugin.key, PricingPlugin.key, VideoPlugin.key, CardGroupPlugin.key, TestimonialGroupPlugin.key,
  SubscribeToNewsletterPlugin.key
] as string[];

// Blocks that cant be represented in editor without a parent or can't be nested
export const dependentBlocks = [
  'section', ColumnPlugin.key, ButtonPlugin.key, CardPlugin.key, TestimonialPlugin.key
]

// Recursive function to find all text nodes in the section
export const findSectionTextNodes = (parent: any) => {
  let result: Descendant[] = [];
  if (!parent.children.length) {
    return result;
  }
  for (const child of parent.children) {
    if (textNodeTypes.includes(child.type)) {
      result.push(child);
    }
    if (child.children) {
      result = result.concat(findSectionTextNodes(child));
    }
  }
  return result;
}