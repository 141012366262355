import React from 'react';
import { withRef } from '@udecode/cn';
import { PlateElement, useReadOnly } from '@udecode/plate/react';

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxInput,
  InlineComboboxItem,
} from '../InlineCombobox/InlineCombobox';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { TemplateVariablePlugin } from '../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin';


export const TemplateVariableInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;

    const isReadOnly = useReadOnly()
    const isTemplate = useAppSelector(state => state.page_navigation.template)
    const template_variables = useAppSelector(state => state.page_template_variables)

    if (!isTemplate || isReadOnly) return <>{children}</>

    const onVariableSelect = (variableId: string) =>
      editor.tf.insertNodes({ type: TemplateVariablePlugin.key, children: [{ text: "" }], variableId })

    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        ref={ref}
        data-id={element.id}
        {...props}
        className={`rounded p-1 bg-gray-100 ${className}`}
        style={{ fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit' }}
      >
        <InlineCombobox element={element} trigger="{" showTrigger={false} >
          <InlineComboboxInput placeholder={'Select Variable'} />
          <InlineComboboxContent className="max-h-[280px] max-w-[270px] border border-white/20 shadow-md rounded-xl p-3">
            <InlineComboboxEmpty>
              No matching variables found
            </InlineComboboxEmpty>
            {template_variables.map((variable) => (
              <InlineComboboxItem
                key={variable.name}
                keywords={[variable.about, variable.name, variable.value]}
                onClick={onVariableSelect.bind(onVariableSelect, variable.value)}
                value={variable.value}
                className='rounded-lg p-0'
              >
                <div className="p-2 flex flex-col w-full justify-between gap-1">
                  <div className="text-xs brand-text">{variable.name}</div>
                  <div className="text-xs/3 brand-subtext">{variable.about}</div>
                </div>
              </InlineComboboxItem>
            ))}
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);
