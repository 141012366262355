import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageData } from '../api/api.types'
import { PageDataAttributes } from '../api/data-attributes.util'

export interface PageAddendums {
  currency: string,
  readOnly: boolean,
  isLandingPage: boolean,
  requestAttributes: PageDataAttributes | null,
  account_id: string | null
  editorContainerWidth: 'laptop' | 'tablet' | 'mobile'
  room_slug: string | null
}

const initialState: PageAddendums = {
  currency: '',
  readOnly: false,
  isLandingPage: false,
  requestAttributes: null,
  account_id: null,
  editorContainerWidth: 'laptop',
  room_slug: null
}

export const pageAddendumsSlice = createSlice({
  name: 'page_addendums',
  initialState,
  reducers: {
    setAddendums: (state, action: PayloadAction<{ pageData: PageData, requestAttributes: PageDataAttributes | null }>) => ({
      ...state,
      currency: action.payload.pageData.currency,
      requestAttributes: action.payload.requestAttributes,
      account_id: action.payload.pageData.account_id,
      room_slug: action.payload.pageData.room_slug
    }),
    toggleEditorMode: (state) => ({ ...state, readOnly: !state.readOnly }),
    setLandingPageMode: (state, action: PayloadAction<boolean>) => ({ ...state, isLandingPage: action.payload }),
    setEditorContainerWidth: (state, action: PayloadAction<'laptop' | 'tablet' | 'mobile'>) => ({ ...state, editorContainerWidth: action.payload }),
  },
})

export const { setAddendums, toggleEditorMode, setLandingPageMode, setEditorContainerWidth } = pageAddendumsSlice.actions

export default pageAddendumsSlice.reducer
