import { Node } from "slate";
import { v4 as uuidv4 } from 'uuid';
import { createPlatePlugin, ParagraphPlugin } from "@udecode/plate/react";
import { TElement } from "@udecode/plate";

import { createDefaultParagraphElement } from "../DefaultMockups/DefaultMockups";
import { dependentBlocks, textNodeTypes } from "../../../utils/plate.util";


export const SectionPlugin = createPlatePlugin({
  key: 'section',
  node: {
    type: 'section',
    isElement: true,
  },
  // As first level node it should higher priority than all other editor elements
}).overrideEditor(({ editor, tf: { normalizeNode, apply } }) => {
  return {
    transforms: {
      // Rule: first level elements should only be sections
      normalizeNode([node, path]) {
        editor.children.map((firstLevelNode) => {
          if (firstLevelNode.type !== SectionPlugin.key) {
            editor.tf.removeNodes({ at: [editor.children.indexOf(firstLevelNode)] })
          }
        })
        // Nested elements of group nodes should not exist without parent group node
        if (node.type === SectionPlugin.key) {
          const section = node as PlateSectionElement;
          section.children.map((sectionChild) => {
            if (dependentBlocks.includes(sectionChild.type as any)) {
              if (sectionChild.type === SectionPlugin.key) {
                editor.tf.setNodes({ delete: true } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionChild.id });
              }
              editor.tf.removeNodes({ at: [], match: (n: any) => n.id === sectionChild.id });
            }
          })
        }
        // If section node has align property we need to add align marks to all text nodes inside the section
        if (textNodeTypes.includes(node.type as string) && !node.align) {
          const section = editor.api.node({ at: [path[0]], match: { type: SectionPlugin.key } });
          if (section) {
            const align = section[0].font_alignment;
            align && editor.tf.setNodes(
              { align } as Partial<Node>,
              { at: [], match: (n: any) => n.id === node.id }
            )
          }
        }
        normalizeNode([node, path])
      },
      apply(operation) {
        // Allow to delete only marked sections
        if (operation.type === "remove_node" && operation.node.type === SectionPlugin.key && operation.node.delete === true) {
          return apply(operation)
        }
        // Prevent removing unmarked sections (in case some mess between blocks)
        if (operation.type === "remove_node" && operation.node.type === SectionPlugin.key) {
          // If such event was triggered, it means that section.children array are empty, so add blank paragraph:
          const node = operation.node as TElement;
          if (node.children.length === 0) {
            editor.tf.insertNodes(createDefaultParagraphElement(), { at: [...operation.path, 0] });
          }
          return
        }
        // Prevent merging sections (we don't want to merge sections)
        if (operation.type === "merge_node" && operation.properties?.type === SectionPlugin.key) {
          return
        }
        // Prevent moving sections to other sections (we don't want to move sections inside sections)
        // unit.path?.length === 1 - we are always sure that 1 level elements - sections
        if (operation.type === "move_node" && operation.path?.length === 1 && operation.newPath.length > 1) {
          return
        }
        // prevent node spliting
        if (operation.type === "split_node" && operation.properties.type === SectionPlugin.key) {
          return
        }
        // Convert emty {text: ""} node into paragraph. In case of removing last child in section
        if (operation.type == 'insert_node' && !operation.node.type && operation.path.length === 2) {
          if (operation.path[1] === 0) {
            operation = { ...operation, node: { type: ParagraphPlugin.key, children: [{ text: '' }], id: uuidv4() } } as any
          }
        }
        apply(operation)
      },
    }
  }
})

export interface PlateSectionElement extends TElement {
  id: string
  name: string | null
  position: number
  padding: string | null
  width: string | null
  tint_color: string | null
  tint_kind: string | null
  tint_opacity: number | null
  background_blur: number
  fixed_kind: string | null
  image_id: string
  image_source: string
  image_external_id: string
  image_size: string
  background_image_url: string
  animate_direction: string | null
  animate_speed: string | null
  animate_style: string | null
  animate_type: string | null
  card_color: string | null
  card_opacity: number | null
  deleted_at?: string | null
  created_at?: string
  updated_at?: string
  hidden?: boolean
  animationCount: number
  font_size: string | null
  font_alignment: string | null
  font_color: string | null
  style: string | null
  children: TElement[]
}

export const createDefaultSectionElement = (): TElement => ({
  type: SectionPlugin.key,
  children: [createDefaultParagraphElement()],
  padding: 'medium',
  tint_kind: 'regular',
  image_size: 'adjustable',
  animate_type: 'no-animation',
  style: "adjustable",
  tint_opacity: 50,
  background_blur: 0,
  card_opacity: 50,
  animationCount: 0
})