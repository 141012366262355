import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import { revvedupApi } from './reducers/api/api'
import pageCalcVariablesSlice from './reducers/page_calc_variables/page_calc_variables'
import pageNavigationSlice from './reducers/page_navigation/page_navigation'
import pageIndicatorsSlice from './reducers/page_indicators/page_indicators'
import pageFontsSlice from './reducers/page_fonts/page_fonts'
import pageColorsSlice from './reducers/page_colors/page_colors'
import pageAddendumsSlice from './reducers/page_addendums/page_addendums'
import templateVariablesSlice from './reducers/page_template_variables/page_template_variables'
import pageSectionsSlice from './reducers/page_sections/page_sections'
import pageSideEffectsSlice from './reducers/page_side_effects/page_side_effects'
import pageContactsSlice from './reducers/page_contacts/page_contacts'
import pageResourcesSlice from './reducers/page_resources/page_resources'
import pageCommentsSlice from './reducers/page_comments/page_comments'
import pageImagesSlice from './reducers/page_images/page_images'
import pageShadowStoreSlice from './reducers/page-shadow-store/page_shadow_store'
import pagePluginsStatusSlice from './reducers/page_plugins/page_plugins'

export const RESET_STORE_ACTION_TYPE = 'RESET_STORE_ACTION_TYPE'

const combinedReducer = combineReducers({
  [revvedupApi.reducerPath]: revvedupApi.reducer,
  page_sections: pageSectionsSlice,
  page_colors: pageColorsSlice,
  page_fonts: pageFontsSlice,
  page_calc_variables: pageCalcVariablesSlice,
  page_navigation: pageNavigationSlice,
  page_indicators: pageIndicatorsSlice,
  page_addendums: pageAddendumsSlice,
  page_template_variables: templateVariablesSlice,
  page_side_effects: pageSideEffectsSlice,
  page_contacts: pageContactsSlice,
  page_resources: pageResourcesSlice,
  page_comments: pageCommentsSlice,
  page_images: pageImagesSlice,
  page_shadow_store: pageShadowStoreSlice,
  page_plugins_status: pagePluginsStatusSlice,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // We ignore immutableCheck because of simplifying sections data update, we overwrite it each time when we get new data from Editor.
    // Updating section by section will require additional complex logic, because of deeply nested data & dnd & history engine.
    getDefaultMiddleware({ immutableCheck: { ignoredPaths: ['page_sections', 'revvedupApi'] } }).concat(revvedupApi.middleware),
})

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch