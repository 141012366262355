import React, { useEffect, useRef, useState } from "react";
import { Resizable } from "re-resizable";
import { useEditorRef, useReadOnly } from "@udecode/plate/react";

import { PlateImageElement, ProgramaticTypes } from "../../../plate-config/Plugins/Image/Image.plugin";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import { useElementWidth } from "../../../hooks/useElementWidth";

type Props = {
  element: PlateImageElement
  onImageSizeChange: (width: string) => void
  goToLink?: string
  controlsVisible: boolean
}

const ImageResizer = ({ element, onImageSizeChange, controlsVisible, goToLink }: Props) => {
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

  const editor = useEditorRef()
  const isReadOnly = useReadOnly();
  const [size, setSize] = useState({ width: element.width });
  const parentRef = useRef<HTMLImageElement>(null)
  const parentWidth = useElementWidth(parentRef)
  const [dynamicWidth, setDynamicWidth] = useState(0)
  const { profile_image, image_placeholders } = useAppSelector(state => state.page_images)
  const isTemplate = useAppSelector(state => state.page_navigation.template)

  useEffect(() => {
    if (parentRef.current?.offsetWidth) {
      setDynamicWidth(parentRef.current?.offsetWidth! * Number(size.width.slice(0, -1)) / 100)
    }
  }, [parentRef.current?.offsetWidth, size, editor, parentWidth])

  const { image_url, align, shape } = element
  const isProgrammatic = image_url === ProgramaticTypes.BRAND_LOGO || image_url === ProgramaticTypes.PROFILE_IMAGE
  const profileImageUrl = (isTemplate && isReadOnly || !isTemplate)
    ? profile_image ? profile_image : image_placeholders.profile_url
    : image_placeholders.profile_url
  const image = isProgrammatic
    ? image_url === ProgramaticTypes.BRAND_LOGO ? image_placeholders.brand_url : profileImageUrl
    : image_url
  const imageClass = shape === 'square' ? 'rounded-lg object-cover object-center' : 'object-cover object-center'
  const dynamicImageStyle = shape === 'square' ? {} : { width: dynamicWidth, height: dynamicWidth }

  const getClipPath = (shape: string) => {
    switch (shape) {
      case 'square':
        return 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)'
      case 'circle':
        return 'circle(50% at 50% 50%)'
      case 'ellipse':
        return 'ellipse(25% 40% at 50% 50%)'
      case 'parallelogram':
        return 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)'
      case 'triangle':
        return ' polygon(50% 0%, 0% 100%, 100% 100%)'
      case 'star':
        return 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)'
      default:
        return 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)'
    }
  }

  return (
    <div className='w-full block'>
      <div
        className={`
        flex ${align === 'center' ? 'justify-center' : ''}
        ${align === 'right' ? 'justify-end' : ''} ${align === 'left' ? 'justify-start' : ''}
      `}
        ref={parentRef}
      >
        <Resizable
          defaultSize={{ width: size.width, height: '100%' }}
          onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
          onResizeStop={(_e, _direction, ref) => onImageSizeChange(ref.style.width)}
          minWidth={'30%'}
          maxWidth={'100%'}
          className={`resizer-wrapper ${controlsVisible ? 'active' : ''}`}
          handleClasses={{
            top: 'pointer-events-none', right: 'pointer-events-none',
            bottom: 'pointer-events-none', left: 'pointer-events-none',
          }}
          handleComponent={{
            top: <div className="resizer_border top" />,
            bottom: <div className="resizer_border bottom" />,
            right: <div className="resizer_border right" />,
            left: <div className="resizer_border left" />,
            bottomRight: <div className="resizer_dot bottom-right" />,
            bottomLeft: <div className="resizer_dot bottom-left" />,
            topRight: <div className="resizer_dot top-right" />,
            topLeft: <div className="resizer_dot top-left" />,
          }}
          enable={isReadOnly ? false : controlsVisible ? undefined : false}
        >
          <a
            href={isReadOnly ? goToLink ? goToLink : undefined : undefined}
            {...(element.link_type === 'external' || element.link_type === 'resource') ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
          >
            <div style={{ clipPath: getClipPath(shape), ...dynamicImageStyle }}>
              <img src={image as string} alt="img" draggable={false} className={imageClass} style={{ width: '100%', height: '100%' }} />
            </div>
          </a>
        </Resizable>
      </div>
    </div>
  )
}

export default ImageResizer
