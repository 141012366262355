import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Command } from 'cmdk';

import { transformCustomFonts, WEIGHT_BY_FAMILY } from "../../../../../store/reducers/page_fonts/page_fonts.config";
import PhIcon from "../../../../../shared/Icon/PhIcon";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";

type Props = {
  activeFont: string;
  onFontChange: (font: string) => void;
  width?: number;
}

const FontSelector: React.FC<Props> = ({ activeFont, onFontChange, width }) => {
  const [open, setOpen] = useState(false);
  const [isInputActive, setInputActive] = useState<boolean>(false);
  const custom_fonts = useAppSelector(state => state.page_fonts.customFonts);

  const customFontFamilies = transformCustomFonts(custom_fonts)
  const sortedFamiliesArray = Object.entries({ ...WEIGHT_BY_FAMILY, ...customFontFamilies })
    .sort((a, b) => a[0].localeCompare(b[0]))
    .reduce((acc, [family, weights]) => ({ ...acc, [family]: weights }), {});
  const fontFamilies = Object.keys(sortedFamiliesArray)

  const preventDefault = (event: React.PointerEvent<HTMLDivElement>) => event.preventDefault();

  const onFontChangeHandler = (font: string) => {
    onFontChange(font)
    setOpen(false)
  }
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild  >
        <div
          className={`
            p-2 rounded-lg border w-[70%] bg-white flex justify-between gap-2 items-center outline-none cursor-pointer
            ${open ? "active_input text-gray-500" : "border-gray-300 text-brand"} hover:border-gray-400
            `}
        >
          <span className="brand-text text-base font-normal capitalize">{activeFont}</span>
          <PhIcon name="caretDown" size={16} className="text-black" />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="dropdown_content  max-h-[265px] z-[4]"
          style={{ width }}
          align="start"
          onPointerLeave={preventDefault}
          onPointerMove={preventDefault}
        >
          <Command className="w-full">
            <div className={`decorated_input-wrapper ${isInputActive ? 'active' : ''} mb-2`}>
              <PhIcon name="magnifyingGlass" size={16} className="text-gray-400 mr-1" />
              <Command.Input
                placeholder="Search..."
                className="text-base decorated_input"
                onFocus={setInputActive.bind(setInputActive, true)}
                onBlur={setInputActive.bind(setInputActive, false)}
              />
            </div>
            <Command.List>
              <Command.Empty className="brand-text text-black text-sm" >No results found.</Command.Empty>
              {fontFamilies.map((fontFamily, i) =>
                <Command.Item
                  key={i}
                  className={`dropdown_item ${activeFont === fontFamily ? 'active' : ''}`}
                  value={fontFamily}
                  onSelect={onFontChangeHandler}
                >
                  <span className="brand-text text-base">{fontFamily}</span>
                  {activeFont === fontFamily && <PhIcon name="check" size={16} className="text-black" />}
                </Command.Item>
              )}
            </Command.List>
          </Command>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default FontSelector;