import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { PlateElement, useReadOnly, useSelected } from '@udecode/plate/react';

import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { colorClassToRGB, systemColorsArray } from '../../utils/color.util';

export const BlockquoteElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const isReadOnly = useReadOnly();
    const selected = useSelected();
    const { activePalette } = useAppSelector(state => state.page_colors)

    const nodePath = editor.api.node({ at: [], match: { id: element.id } })![1];
    const parentNode = editor.api.parent(nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false

    const line_color = element.line_color as string
    const landingColor = line_color ? line_color.startsWith('landing-') : null
    const landingPalett = systemColorsArray.find(color => color.name === line_color)

    return (
      <PlateElement
        ref={ref}
        id={element.id as string}
        editor={editor}
        element={element}
        data-id={element.id}
        asChild
        className={cn(`
        p-2 rounded-lg italic text-base flex w-full transition-all duration-300 ease-in-out selection:bg-sky-50 border border-transparent
        ${!isReadOnly && !selected ? 'hover:bg-[#B9D3F426]' : ''} ${!isReadOnly && selected ? 'border-sky-50' : ''}
        ${isElColumnChild
            ? isLastColElement ? 'mb-0' : "mb-4"
            : `page-block quote-block  ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        `, className)}
        {...props}
      >
        <div>
          <blockquote className='relative pl-4 h-full w-full'>
            <div
              contentEditable={false}
              suppressContentEditableWarning
              className='absolute h-full w-1 left-0 rounded-[20px]'
              style={{ backgroundColor: line_color ? landingColor ? landingPalett?.color : colorClassToRGB(line_color, 100, activePalette.colors)! : "#05AAF6" }}
            />
            {children}
          </blockquote>
        </div>
      </PlateElement>
    );
  }
);
